import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NavSub from "../components/NavSub";
import { UserContext } from "../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MagnifyingGlass } from "@phosphor-icons/react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import HorizontalDateCalender from "./HorizontalDateCalender";
import ToastComponent from "../components/ToastComponent";
import { clientApiUrl, reportApiUrl } from "../utils/GetUrl";
import {
  CaretCircleDoubleLeft,
  CaretCircleDoubleRight,
  FileArrowUp,
  FunnelSimple,
} from "phosphor-react";
import "../Styles/DailyVoucherStock.css";
import * as XLSX from "xlsx";
import isGuestUser from "../utils/guestUser";

const DailyVoucherStock = () => {
  // ///////////////////////////////////////////////////

  const [query, setQuery] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const debounceTimeout = useRef(null);

  const fetchSuggestionByText = async () => {
    if (!token) {
      console.error("Token is missing.");
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        console.error("Failed to fetch suggestions:", response.statusText);
        setSearchLoading(false);
        return;
      }
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setSearchLoading(true);

      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);

      setSearchLoading(false);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const handleCustomersearchClick = () => {
    // return;

    setIsSearchOpen(!isSearchOpen);
  };

  const handleClearAllSearch = () => {
    setQuery("");
    setPage(1);
    setSelectedSuggestions([]);
    setSelectedEmployees([userData?.[0]])
  };
  const handleCheckboxChange = (suggestion) => {
    console.log(suggestion)
    if (selectedSuggestions?.length === 0) {
      setPage(1);
    }
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected?.some((item) => item.Id === suggestion.Id)) {
        return prevSelected?.filter((item) => item.Id !== suggestion.Id);
      } else {
        return [suggestion];
      }
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsSearchOpen(false);
      }
    };

    const handleScroll = () => {
      setIsSearchOpen(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ////////////////////////////////////////////////////
  const {
    teamsData,
    setTeamsData,
    teamNames,
    setTeamNames,
    usersList,
    fetchTeams,
  } = useContext(UserContext);
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);

  const [teams, setTeams] = useState(
    teamNames?.filter(
      (team) =>
        team?.superTeamId === 0 && team?.type === "T" && team?.status === true
    )
  ); 
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] = useState();
  const [selectedPrepareName, setSelectedPrepareName] = useState(userData?.[0]);
  const [accSearch, setAccSearch] = useState("");
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState(
    []
  );
  const dropdownsearchRef = useRef(null);
  const { toastRef } = useRef();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([userData?.[0]]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedClient, setSelectedClient] = useState({});
  const todaysDate = new Date();
  const [selectedYear, setSelectedYear] = useState(todaysDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    todaysDate.toLocaleString("default", { month: "long" })
  );
  const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());

  const [isTableLoaded, setIsTableLoaded] = useState(false);

  const handleCheckboxAccountResponsibleChange = (acc) => {
    setSelectedOption({});
    setSelectedAccountResponsible([acc]);
    if (selectedTeams?.length) {
      setSelectedTeams([]);
      setSelectedAccountResponsible([]);
    }
    if (selectedPrepareName === acc) {
      setSelectedPrepareName();
      setSelectedAccountResponsible([]);
      return;
    }
    setPage(1);
    // setClients([]);
    setSelectedClient([])
    setSelectedEmployees([acc]);
    setSelectedPrepareName(acc);
  };
  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList]);

  const toggleTeamSelector = () => {
    // setTeamSelectorOpen((prev) => !prev);
  };

  useEffect(()=>{
    console.log([userData?.[0]])
    setSelectedEmployees([userData?.[0]])
  }, [userData])

  const handleAccountingResponsibleClick = () => {
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };

  const handleChange = (event) => {
    console.log(selectedEmployees);
    setSelectedPrepareName();
    //console.log(event.target.value);
    if (event.target.value?.[event.target.value?.length - 1] === undefined) {
      //console.log("mmmmmmmmmmmmmmmm");
      setSelectedTeams([]);
      return;
    }
    setSelectedTeams(event.target.value);
  };
  const handleClearAll = (event) => {
    event.preventDefault();
    console.log(selectedEmployees);
    setSelectedTeams([]);
  };
  useEffect(() => {
    if (!selectedTeams?.length && !isConsultant()) {
      setSelectedEmployees([userData?.[0]]);
    }
  }, [selectedTeams, token]);

  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
      setSelectedEmployees(filteredEmployees);
  }, [selectedTeams, usersList, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsAccountResponsibleOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownsearchRef]);

  const [expanded, setExpanded] = useState(true);

  //fetching data
  const [page, setPage] = useState(1);
  const [masterData, setMasterData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);
  const fetchInProgress = useRef(false); //


  const isConsultant =()=>{
    if(userData?.[0]?.DR_Name=="Consultant"){
      return true;
    }
    return false;
  }

  const formatSelectedEmployees = (selected) => {
    if(!selected?.[0]?.EM_ID)return 
    console.log('sel', selectedEmployees)
    const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
    console.log(emIds?.join(","))
    return emIds?.join(",");
  };
  useEffect(() => {
    console.log(selectedEmployees);
  }, [selectedEmployees, selectedTeams, token]);

  const fetchCustomer = async (dateStart, dateEnd, first) => {
    const clientId = selectedSuggestions?.[0]?.Id;
    console.log(selectedSuggestions)
    if(!selectedEmployees?.length) return
    console.log(selectedEmployees);
    if (loading || fetchInProgress.current) return;
    console.log("calll");
    fetchInProgress.current = true; // Set the flag to true
    setLoading(true);
    console.log("Fetching data for page:", page);
    const date = formatDate(selectedDay, selectedMonth, selectedYear);
    try {
      setIsTableLoaded(false);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${
          first ? 1 : page
        }&ItemsPerPage=${
          clientId ? 1 : 20
        }&CreatDate=${date}${
          clientId
            ? `&id=${clientId}` 
            : selectedEmployees.length > 0 || selectedPrepareName
            ? `&SubOwnerEMIDs=${formatSelectedEmployees(selectedEmployees)}`
            : ""
        }`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          setEnd(true);
        } else {
          const formattedIds = data.map((d) => `'${d?.Id}'`).join(", ");
          await fetchedReportsPerClient(data, formattedIds, dateStart, dateEnd);
        }
      } else {
        setLoading(false);
        setEnd(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);

      fetchInProgress.current = false; // Reset the flag
    }
  };

  const fetchedReportsPerClient = async (
    clientData,
    ids,
    dateStart,
    dateEnd
  ) => {
    if (!ids || !dateStart || !dateEnd) return;

    try {
      const response = await fetch(
        `${reportApiUrl}/GetReports?ClientId=${ids}&DteRange1=${dateStart}&DteRange2=${dateEnd}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();

        const currentMergedData = clientData.map((client) => {
          const matchingReport = data.find(
            (report) => report.ClientId === client.Id
          );

          const defaultReportFields = {
            Id: null,
            ReceivedVolume: "",
            PendingVolume: "",
            Volume2: "",
            CommentOfVolume: "",
            PaymentCheck: "",
            PaymentStatus: "",
            CommentOfPaymentFaild: "",
            ReportType: "",
            ReceivedDate: "",
            DueDate: "",
            DateOfSubmission: "",
            ClientId: client.Id,
            CreatedBy: "",
            SynergyPreparer: "",
            CreateDate: "",
          };

          return {
            ...client,
            ...(matchingReport || defaultReportFields),
          };
        });

        setMergedData((prev) => [...prev, ...currentMergedData]);
        setIsTableLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleScroll = debounce(() => {
    // if (!isTableLoaded || loading || end) return;

    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setPage((p) => p + 1);
    }
  }, 1000);

  const isInitialRender = useRef(true);

  useEffect(() => {
    console.log(selectedEmployees)
    const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
    const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
    if(!selectedEmployees?.length){
      setSelectedEmployees([userData?.[0]])
    }
    fetchCustomer(dateStart, dateEnd, false);
  }, [page]);

  useEffect(() => {

    console.log('This triggered')
    console.log(selectedEmployees)
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setEnd(false);
    setPage(1);
    setMergedData([]);
    setMasterData([]);
    // new Promise(resolve => setTimeout(resolve, 2000));
    if(!selectedEmployees?.length){
      setSelectedEmployees([userData?.[0]])
    }
    const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
    const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
    fetchCustomer(dateStart, dateEnd, true);
  }, [selectedDay, selectedMonth, selectedYear, selectedEmployees, selectedSuggestions]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const formatDate = (day, month, year) => {
    const monthMapping = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const formattedMonth = monthMapping[month];
    const formattedDay = String(day).padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // //////////////////////////////////////////////////////////////////

  const getUserFirstName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user ? user.EM_FirstName + " " + user.EM_LastName : "";
  };
  const [receivedVolume, setReceivedVolume] = useState("");
  const [volume2, setVolume2] = useState("");
  const [processedVolume, setProcessedVolume] = useState(0);
  const [pendingVolume, setPendingVolume] = useState(0);
  const [comment, setComment] = useState("");
  const [paymentCheck, setPaymentCheck] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [commentOfPaymentFaild, setCommentOfPaymentFaild] = useState("");

  const debounceRef = useRef(null); // A ref to hold the debounce timeout

  const handleReportChange = async (data, val, e) => {
    const isToday = () =>
      new Date(
        formatDate(selectedDay, selectedMonth, selectedYear)
      ).toDateString() === new Date().toDateString();
    if (!isToday()) {
      toastRef.current.showToast(`Please Select Today's Date`, "error");
      alert(`Please Select Today's Date`);
      return;
    }

    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      alert(`Guest User Does not have Add or Edit Access`);

      return;
    }
    const EM_ID = userData?.[0]?.EM_ID; // Assuming userData is coming from context or props

    if (!EM_ID) {
      console.error("Failed to retrieve user ID or Client ID.");
      return;
    }

    // Update the mergedData state with the new value
    const updatedMergedData = mergedData.map((item) => {
      if (item.ClientId === data.ClientId) {
        return { ...item, [val]: e.target.value }; // Dynamically update the field based on val
      }
      return item;
    });

    setMergedData(updatedMergedData);
    console.log("Updated merged data:", updatedMergedData);

    const updatedItem = updatedMergedData.find(
      (item) => item.ClientId === data.ClientId
    );

    let payload;
    if (data.Id === null) {
      payload = {
        ClientId: data.ClientId,
        ReceivedVolume: Number(updatedItem.ReceivedVolume) || 0,
        Volume2: Number(updatedItem.Volume2) || 0,
        PendingVolume:
          Number(updatedItem.ReceivedVolume - updatedItem.StatusCount) || 0,
        CommentOfVolume: updatedItem.CommentOfVolume || "",
        PaymentCheck: updatedItem.PaymentCheck || "",
        PaymentStatus: updatedItem.PaymentStatus || "",
        CommentOfPaymentFaild: updatedItem.CommentOfPaymentFaild || "",
        ReportType: "Daily Voucher",
        ReceivedDate: "",
        DueDate: "",
        DateOfSubmission: todaysDate,
        CreatedBy: EM_ID,
        SynergyPreparer: "",
      };
    } else {
      payload = {
        TaskReportId: data.Id,
        ClientId: data.ClientId,
        ReceivedVolume: Number(updatedItem.ReceivedVolume) || 0,
        Volume2: Number(updatedItem.Volume2) || 0,
        PendingVolume:
          Number(updatedItem.ReceivedVolume - updatedItem.StatusCount) || 0,
        CommentOfVolume: updatedItem.CommentOfVolume || "",
        PaymentCheck: updatedItem.PaymentCheck || "",
        PaymentStatus: updatedItem.PaymentStatus || "",
        CommentOfPaymentFaild: updatedItem.CommentOfPaymentFaild || "",
        ReportType: "Daily Voucher",
        ReceivedDate: "",
        DueDate: "",
        DateOfSubmission: todaysDate,
        CreatedBy: EM_ID,
        SynergyPreparer: "",
      };
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(async () => {
      try {
        const url =
          data.Id === null
            ? `${reportApiUrl}/AddTaskReport`
            : `${reportApiUrl}/UpdateTaskReport`;

        const method = data.Id === null ? "POST" : "PUT";

        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }

        if (response.ok) {
          const result = await response.json();

          console.log(
            `${method === "POST" ? "Added" : "Updated"} successfully:`,
            result
          );

          if (method === "POST") {
            // Fetch the newly created report's ID using the GET endpoint
            const reportResponse = await fetch(
              `${reportApiUrl}/GetReports?ClientId='${
                data.ClientId
              }'&DteRange1=${formatDate(
                selectedDay,
                selectedMonth,
                selectedYear
              )}&DteRange2=${formatDate(
                selectedDay,
                selectedMonth,
                selectedYear
              )}`,
         
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`, // Assuming `token` is defined elsewhere
                },
              }
            );

            if (response.status === 401) {
              toastRef.current.showToast(
                "Unauthorised access. Please login again.",
                "error"
              );
              await logout();
              return;
            }

            if (reportResponse.ok) {
              const reportData = await reportResponse.json();
              console.log("Fetched report data:", reportData);

              // Update mergedData with reportId
              const updatedMergedDataWithReportId = mergedData.map((item) => {
                if (item.ClientId === data.ClientId) {
                  return {
                    ...item,
                    Id: reportData?.[0]?.Id, // Update the Id field with the reportData Id

                    ReceivedVolume:
                      updatedItem.ReceivedVolume || item.ReceivedVolume,
                    Volume2: updatedItem.Volume2 || item.Volume2,
                    PendingVolume:
                      updatedItem.PendingVolume || item.PendingVolume,
                    CommentOfVolume:
                      updatedItem.CommentOfVolume || item.CommentOfVolume,
                    PaymentCheck: updatedItem.PaymentCheck || item.PaymentCheck,
                    PaymentStatus:
                      updatedItem.PaymentStatus || item.PaymentStatus,
                    CommentOfPaymentFaild:
                      updatedItem.CommentOfPaymentFaild ||
                      item.CommentOfPaymentFaild,
                    ClientId: updatedItem.ClientId || item.ClientId,
                    CreatedBy: updatedItem.CreatedBy || item.CreatedBy,
                  };
                }
                return item;
              });

              setMergedData(updatedMergedDataWithReportId);
            }
          }
        } else {
          console.error(
            `Error ${method === "POST" ? "adding" : "updating"} data:`,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error making API call:", error);
      } finally {
        debounceRef.current = null;
      }
    }, 1000);
  };

  // const handleDownload = async () => {
  //   const monthIndex =
  //     new Date(`${selectedMonth} 1, ${selectedYear}`).getMonth() + 1; // Convert month string to index
  //   const startOfMonth = `${selectedYear}_${String(monthIndex).padStart(
  //     2,
  //     "0"
  //   )}_01`;

  //   const endOfMonthDate = new Date(selectedYear, monthIndex, 0); // Get last day of the month
  //   const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
  //     endOfMonthDate.getMonth() + 1
  //   ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;

  //   if (isGuestUser(userData?.[0]?.DRM_ID)) {
  //     toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

  //     return;
  //   }
  //   try {
  //     const response = await fetch(`${reportApiUrl}/GetReportFile`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         DateRange1: startOfMonth,
  //         DateRange2: endOfMonth,
  //         ReportType: "Daily Voucher",
  //       }),
  //     });

  //     if (response.status === 401) {
  //       toastRef.current.showToast(
  //         "Unauthorised access. Please login again.",
  //         "error"
  //       );
  //       await logout();
  //       return;
  //     }
  //     const result = await response.json();
  //     if (result.Status) {
  //       const fileUrl = result.Result.URI;
  //       window.location.href = fileUrl;
  //     } else {
  //       console.error("Error in fetching report:", result);
  //     }
  //   } catch (error) {
  //     console.error("Error in fetch:", error);
  //   }
  // };

  const handleDownload = async () => {
    // Validate that all required values are available
    if (!selectedDay || !selectedMonth || !selectedYear) {
      toastRef.current.showToast("Please select a valid date.", "error");
      return;
    }
  
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);
      return;
    }
  
    try {
      // Use the formatDate function to format the selected date
      const formattedDate = formatDate(selectedDay, selectedMonth, selectedYear);
  
      const response = await fetch(`${reportApiUrl}/GetReportFile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DateRange1: formattedDate, // Set DateRange1 as the selected date
          DateRange2: formattedDate, // Set DateRange2 as the same date
          ReportType: "Daily Voucher",
        }),
      });
  
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
  
      const result = await response.json();
      if (result.Status) {
        const fileUrl = result.Result.URI;
        window.location.href = fileUrl;
      } else {
        console.error("Error in fetching report:", result);
      }
    } catch (error) {
      console.error("Error in fetch:", error);
    }
  };

  const getUserTeamName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user
      ? teamNames?.find((team) => team.id === parseFloat(user.STM_Team))
          ?.name || "Unknown Team"
      : "Unknown Team"; // Return team name or default to "Unknown Team"
  };

  useEffect(() => {
    const tableContainer = document.querySelector(".scroll-hardware");

    if (tableContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        tableContainer.classList.add("active");
        startX = e.pageX - tableContainer.offsetLeft;
        scrollLeft = tableContainer.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseUp = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - tableContainer.offsetLeft;
        const walk = (x - startX) * 2; // Adjust multiplier for speed
        tableContainer.scrollLeft = scrollLeft - walk;
      };

      tableContainer.addEventListener("mousedown", handleMouseDown);
      tableContainer.addEventListener("mouseleave", handleMouseLeave);
      tableContainer.addEventListener("mouseup", handleMouseUp);
      tableContainer.addEventListener("mousemove", handleMouseMove);

      return () => {
        tableContainer.removeEventListener("mousedown", handleMouseDown);
        tableContainer.removeEventListener("mouseleave", handleMouseLeave);
        tableContainer.removeEventListener("mouseup", handleMouseUp);
        tableContainer.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);
  return (
    <div>
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />

      <div
        style={{
          display: "flex",
          gap: "28px",
          padding: "20px 24px",
          width: "98%",
          margin: "1rem auto",
          background: "white",
          borderRadius: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "28px" }}>
        {!isConsultant()&&<FormControl className="select-dropdown-user">
            <InputLabel
              id="team-selector-label"
              className="select-dropdown-label"
              shrink={false}
              onClick={toggleTeamSelector}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }} // Ensures clickable area
            >
              SELECT TEAMS
            </InputLabel>

            <Select
              labelId="team-selector-label"
              id="team-selector"
              multiple
              value={selectedTeams}
              onChange={handleChange}
              renderValue={(selected) =>
                selected
                  .map((teamId) => {
                    const team = teams.find((team) => team.id === teamId);
                    return team?.Name;
                  })
                  .join(" ")
              }
              style={{ width: "150px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {selectedTeams?.length > 0 && (
                <MenuItem onClick={(e) => handleClearAll(e)}>
                  <ListItemText
                    style={{ color: "red" }}
                    primary={"Clear All"}
                  />
                </MenuItem>
              )}
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>}

          <div
            className="Title-STR-main2"
            onClick={handleAccountingResponsibleClick}
          >
            {selectedPrepareName?.EM_FirstName +
              selectedPrepareName?.EM_LastName || "Preparer Name"}
            <FontAwesomeIcon
              className="str-caret-icon"
              icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}
            />
            {isAccountResponsibleOpen && !isConsultant() && (
              <div ref={dropdownsearchRef} className="search-drop-DailyVoucher">
                <div className="search-drop-page">
                  <div className="main-suggestion">
                    <div className="input-suggestion">
                      <div className="icon-search-phospher">
                        <MagnifyingGlass
                          size={20}
                          color="#ada4a4"
                          weight="fill"
                        />
                      </div>
                      <input
                        type="text"
                        value={accSearch}
                        className="input-suggestion-search"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const value = e.target.value.toLowerCase();
                          setAccSearch(value);
                          setAccountResponsible2(
                            accountResponsible?.filter((acc) =>
                              (acc.EM_FirstName + " " + acc?.EM_LastName)
                                .toLowerCase()
                                .includes(value)
                            )
                          );
                        }}
                        placeholder="Start typing..."
                      />
                    </div>
                    <div tabIndex={1}>
                      <div
                        style={{ color: "red", padding: "2px 22px" }}
                        onClick={() => {
                          setSelectedPrepareName(userData?.[0]);
                          setSelectedAccountResponsible([userData?.[0]]);
                          setSelectedEmployees([userData?.[0]]);
                        }}
                      >
                        Clear All
                      </div>
                      {accountResponsible2?.map((acc) => (
                        <div key={acc.EM_ID} className="suggestion-list-li">
                          <input
                            type="radio"
                            className="radio-searchbox"
                            style={{
                              accentColor: "rgb(80, 20, 90)",
                              width: "18px",
                              height: "18px",
                            }}
                            checked={selectedAccountResponsible?.some(
                              (item) => item.EM_ID === acc?.EM_ID // Compare using EM_ID for uniqueness
                            )}
                            onChange={() =>
                              handleCheckboxAccountResponsibleChange(acc)
                            }
                          />
                          <label className="li-content">
                            {acc.EM_FirstName + " " + acc.EM_LastName}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="Nav-btn-user">
          <FileArrowUp size={25} color="#3d025f" weight="fill" />
          <button className="Nav-Sub-btn" onClick={handleDownload}>
            DOWNLOAD FILES
          </button>
        </div>
      </div>
      <div style={{ width: "98%", margin: "1rem auto" }}>
        <HorizontalDateCalender
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
      </div>

      <div
        style={{
          width: "98%",
          margin: "1rem auto",
          padding: "20px 0px",
          overflow: "scroll",
        }}
        className="scroll-hardware"
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
            fontFamily: "Arial, sans-serif",
            border: "1px solid #ddd",
          }}
        >
          <thead>
            <tr>
              <th className="header-customername">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Customer Name
                  <FunnelSimple
                    className="icon-search-dropdown"
                    onClick={handleCustomersearchClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                </div>

                {isSearchOpen && (
                  <div
                    ref={dropdownsearchRef}
                    className="search-drop-design-report"
                    style={{
                      marginLeft: "140px !important",
                      marginTop: "23px !important",
                    }}
                  >
                    <div className="search-drop-page">
                      <div className="main-suggestion">
                        <div className="input-suggestion">
                          <div className="icon-search-phospher">
                            <MagnifyingGlass
                              size={20}
                              color="#ada4a4"
                              weight="fill"
                            />{" "}
                          </div>

                          <input
                            type="text"
                            value={query}
                            className="input-suggestion-search"
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Start typing..."
                          />

                          <button
                            className="clear-title"
                            onClick={handleClearAllSearch}
                          >
                            Clear All
                          </button>
                        </div>
                        {searchLoading ? (
                          <p className="suggestion-title">
                            <CircularProgress
                              width={30}
                              style={{ color: "purple" }}
                            />
                          </p>
                        ) : (
                          <div style={{ maxHeight: "500px" }} tabIndex={1}>
                            {suggestions.map((suggestion) => (
                              <div
                                key={suggestion.Id}
                                className="suggestion-list-li"
                              >
                                <input
                                  type="checkbox"
                                  className="check-searchbox"
                                  checked={selectedSuggestions.some(
                                    (item) => item.Id === suggestion.Id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(suggestion)
                                  }
                                />
                                <label className="li-content">
                                  {suggestion.Name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </th>
              <th
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  position: "relative",
                  minWidth: "300px",
                  textAlign: "start",
                }}
              >
                {" "}
                Project Number
                <span
                  style={{
                    position: "absolute",
                    minWidth: "150px",
                    right: "-135px",
                    top: "20px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  {" "}
                  {expanded ? (
                    <CaretCircleDoubleLeft size={32} color="#080808" />
                  ) : (
                    <CaretCircleDoubleRight size={32} color="#080808" />
                  )}
                </span>
              </th>
              {expanded && (
                <th
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    minWidth: "150px",
                  }}
                >
                  ERP System
                </th>
              )}
              {expanded && <th className="Expand-styles">Cost Center</th>}
              {expanded && <th className="Expand-styles">Department </th>}
              {expanded && <th className="Expand-styles">Department Head </th>}
              {expanded && (
                <th className="Expand-styles">View Account Owner</th>
              )}
              {expanded && <th className="Expand-styles">View Team Lead </th>}
              {expanded && (
                <th className="Expand-styles">
                  Synergy Accounting responsible
                </th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Quality Consultant </th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Process Lead</th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Department Lead</th>
              )}
              {expanded && <th className="Expand-styles">Synergy Team</th>}
              {expanded && (
                <th className="Expand-styles">Frequency Voucher Processing</th>
              )}
              {expanded && (
                <th className="Expand-styles">Bank Remittance Type</th>
              )}
              <th className="last-portion-style">Received Volumes</th>
              <th className="last-portion-style">Processed Volumes</th>
              <th className="last-portion-style">Pending Volumes</th>
              <th className="last-portion-style">Volumes &gt; 2 days</th>
              <th className="last-portion-style">Comments for Volumes </th>
              <th className="last-portion-style">Payment Check</th>
              <th className="last-portion-style">Payment Status</th>
              <th className="last-portion-style">
                Comments for Payment Failures
              </th>
            </tr>
          </thead>
          <tbody>
            {mergedData?.map((data, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#F1F1F1" : "#FFFFFF",
                  }}
                >
                  <td
                    style={{
                      padding: "15px",
                      border: "1px solid #fff",
                      fontFamily: "Poppins",
                    }}
                  >
                    {data?.Name}
                  </td>
                  <td
                    style={{
                      padding: "15px",
                      border: "1px solid #fff",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    {data?.ProjectNumber}
                  </td>
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.ERPSystem}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {/* {data?.CostCenter} */}
                      {selectedSuggestions?.length > 0 ? data?.CostCenterName : data?.CostCenter}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {/* {data?.Department} */}
                      {selectedSuggestions?.length > 0 ? data?.DepartmentName : data?.Department}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.HODName}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.OwnerEMId}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.LeadId}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getUserFirstName(data?.SubOwner)}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getUserFirstName(data?.SubQC)}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getUserFirstName(data?.SubProcLead)}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getUserFirstName(data?.SubLead)}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getUserTeamName(data?.SubOwner)}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.ReportingFrequencyName}
                    </td>
                  )}
                  {expanded && (
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.RemittanceTypeName}
                    </td>
                  )}

                  <td>
                    <input
                      type="number"
                      placeholder="Received Volume"
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                      }}
                      value={data?.ReceivedVolume}
                      onChange={(e) => {
                        handleReportChange(data, "ReceivedVolume", e);
                      }}
                    />
                  </td>

                  <td
                    style={{
                      padding: "10px",
                      width: "100%",
                      background: "none",
                    }}
                  >
                    {data?.StatusCount}
                  </td>

                  <td>
                    <input
                      type="number"
                      disabled
                      placeholder="Pending Volume"
                      value={data?.ReceivedVolume - data.StatusCount}
                      onChange={(e) => {
                        handleReportChange(data, "PendingVolume", e);
                      }}
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Volume 2"
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                      }}
                      value={data?.Volume2}
                      onChange={(e) => {
                        handleReportChange(data, "Volume2", e);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Comment"
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                      }}
                      value={data?.CommentOfVolume}
                      onChange={(e) => {
                        handleReportChange(data, "CommentOfVolume", e);
                      }}
                    />
                  </td>
                  <td>
                    <select
                      value={data?.PaymentCheck || ""}
                      onChange={(e) => {
                        handleReportChange(data, "PaymentCheck", e);
                      }}
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                        fontFamily: "Poppins",
                      }}
                    >
                      <option value="">Select Payment Check</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Applicable">Applicable</option>
                      <td>
                        <select
                          value={data?.PaymentCheck || ""}
                          onChange={(e) => {
                            handleReportChange(data, "", e);
                          }}
                          style={{
                            padding: "10px",
                            width: "100%",
                            background: "none",
                            fontFamily: "Poppins",
                          }}
                        >
                          <option value="">Select Payment Check</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Applicable">Applicable</option>
                         
                          {/* Add this option */}
                        </select>
                      </td>
                     
                      {/* Add this option */}
                    </select>
                  </td>
                  <td>
                    <select
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                        fontFamily: "Poppins",
                      }}
                      value={data?.PaymentStatus}
                      onChange={(e) => {
                        handleReportChange(data, "PaymentStatus", e);
                      }}
                    >
                      <option value="">Select Payment Status</option>
                      <option value="Success">Success</option>
                      <option value="Failed">Failed</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Comment on Payment Failed"
                      style={{
                        padding: "10px",
                        width: "100%",
                        background: "none",
                      }}
                      value={data?.CommentOfPaymentFaild}
                      onChange={(e) => {
                        handleReportChange(data, "CommentOfPaymentFaild", e);
                      }}
                    />
                  </td>
                </tr>

                // ))}
              );
            })}
          </tbody>
        </table>

         {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <CircularProgress style={{ color: "purple" }} width={40} />
                  </div>
                )}
        {end && <div>No more Data...</div>}
      </div>
    </div>
  );
};
// Add filters for selecting employee, and team. If team is selected, loop for all employees in that team.
// Based on the applied filter call 10-10 customers in a batch.
// get customer data and map it on to the UI table.
// Along with each customer get their Daily Voucher stock data.
// FlatMap these two data of each customer and show it on the UI.
// As soon as anything is changed for a customer, check whether a voucherStock entry exists for that customer in that day, if yes, update, else create.

export default DailyVoucherStock;

// const updated=await getSingleReport(data.ClientId,dateStart,dateEnd)
