import React, { useEffect, useRef, useState } from 'react';
import NavSub from '../components/NavSub';
import '../Styles/CustomisedReports.css';
import PrivateReports from '../components/PrivateReports';
import PublicReports from '../components/PublicReports';
import { PlusCircle } from 'phosphor-react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddReports from '../components/AddReports';
import ToastComponent from '../components/ToastComponent';

function CustomisedReports() {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const toastRef = useRef();
  const location = useLocation();
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleCreateNewReport = () => {
    navigate('/AddReports');
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    if (id) {
      setActiveTab(1); 
    } else {
      setActiveTab(0);
    }
  }, [location.search]);
  return (
    <div>
      <NavSub />
      <div className='layout_CustomisedReports'>
      <ToastComponent ref={toastRef} timeout={4000} />
        <div className='Header_tabs_container'>
            <div className='tab_btn_cust'>
          <div
            className={`Header_tabs_CustomisedReports ${
              activeTab === 0 ? 'active' : ''
            }`}
            onClick={() => handleTabClick(0)}
          >
            Private Reports
          </div>
          <div
            className={`Header_tabs_CustomisedReports ${
              activeTab === 1 ? 'active' : ''
            }`}
            onClick={() => handleTabClick(1)}
          >
            Public Reports
          </div>

            </div>
          <div  className={`add_cust_btn ${
              activeTab === 1 ? 'active' : ''
            }`}
            onClick={() => handleTabClick(2)}><PlusCircle size={20} />Create New report </div>
        </div>
        <div className='tabs_container_custReports'>
          {activeTab === 0 && <div><PrivateReports /></div>}
          {activeTab === 1 && <div><PublicReports /></div>}
          {activeTab === 2 && <div><AddReports /></div>}

        </div>
      </div>
    </div>
  );
}

export default CustomisedReports;
