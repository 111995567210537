import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NavSub from "../components/NavSub";
import { UserContext } from "../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MagnifyingGlass } from "@phosphor-icons/react";
import isGuestUser from "../utils/guestUser";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import HorizontalDateCalender from "./HorizontalDateCalender";
import ToastComponent from "../components/ToastComponent";
import { clientApiUrl, taskApiUrl, reportApiUrl } from "../utils/GetUrl";
import {
  CaretCircleDoubleLeft,
  CaretCircleDoubleRight,
  FileArrowUp,
  FunnelSimple,
} from "phosphor-react";
import "../Styles/DailyVoucherStock.css";
import * as XLSX from "xlsx";

const ReportingActivitySheet = () => {
  const {
    teamsData,
    setTeamsData,
    teamNames,
    setTeamNames,
    usersList,
    fetchTeams,
  } = useContext(UserContext);
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);
  // const [selectedDate, setSelectedDate] = useState('');
  // const [chosenDate, setChosenDate] = useState("");
  const [teams, setTeams] = useState(
    teamNames?.filter(
      (team) =>
        team?.superTeamId === 0 && team?.type === "T" && team?.status === true
    )
  ); // const [selectedEmployees, setSelectedEmployees] = useState(usersList);
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] = useState();
  const [selectedPrepareName, setSelectedPrepareName] = useState(null);
  const [accSearch, setAccSearch] = useState("");
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState(
    []
  );
  const dropdownsearchRef = useRef(null);
  const { toastRef } = useRef();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [allActivities, setAllActivities] = useState([]);
  const todaysDate = new Date();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so +1
  const calendarWrapperRef = useRef(null);
  const [expandedActivities, setExpandedActivities] = useState(true);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());

  const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const debounceTimeout = useRef(null);
  const futureYears = Array.from({ length: 20 }, (_, i) => currentYear + i); // Next 20 years

  const handlePreviousYear = () => {
    setSelectedYear((prevYear) => prevYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear((prevYear) => prevYear + 1);
  };
  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    setIsCalendarOpen(false); // Close calendar after month selection
  };
  const fetchSuggestionByText = async () => {
    if (!token) {
      console.error("Token is missing.");
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        console.error("Failed to fetch suggestions:", response.statusText);
        setLoading(false);
        return;
      }
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);

      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);

      setLoading(false);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);
  const handleCustomersearchClick = () => {
    // return;
    setIsSearchOpen(!isSearchOpen);
  };
  useEffect(() => {
    if (isSearchOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSearchOpen]);
  const handleClearAllSearch = () => {
    setQuery("");
    setPage(1);
    setSelectedSuggestions([]);
  };
  const handleCheckboxChange = (suggestion) => {
    if (selectedSuggestions?.length === 0) {
      setPage(1);
    }
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected?.some((item) => item.Id === suggestion.Id)) {
        return prevSelected?.filter((item) => item.Id !== suggestion.Id);
      } else {
        return [suggestion];
      }
    });
  };
  const handleCheckboxAccountResponsibleChange = (acc) => {
    console.log(acc);
    setSelectedAccountResponsible([acc]);
    if (selectedTeams?.length) {
      setSelectedTeams([]);
    }
    setSelectedEmployees([acc]);
    setSelectedPrepareName(acc);
  };

  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList]);

  const toggleTeamSelector = () => {
    // setTeamSelectorOpen((prev) => !prev);
  };

  const handleAccountingResponsibleClick = () => {
    // setSelectedOption({});
    // setShowOptions(true);
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };

  const handleChange = (event) => {
    console.log(selectedEmployees);
    setSelectedPrepareName();
    //console.log(event.target.value);
    if (event.target.value?.[event.target.value?.length - 1] === undefined) {
      //console.log("mmmmmmmmmmmmmmmm");
      setSelectedTeams([]);
      return;
    }
    setSelectedTeams(event.target.value);
  };
  const handleClearAll = (event) => {
    event.preventDefault();
    console.log(selectedEmployees);
    setSelectedTeams([]);
  };
  useEffect(() => {
    //console.log(selectedTeams.length);
    if (!selectedTeams?.length) {
      //console.log("this");
      setSelectedEmployees(usersList);
    }
  }, [selectedTeams, token]);

  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
    setSelectedEmployees(filteredEmployees);

    console.log(filteredEmployees);
    console.log(selectedEmployees);
  }, [selectedTeams, usersList, token]);

  const fetchAllActivities = async () => {
    try {
      // setLoadingActivities(true); //
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        console.log(
          data
            ?.filter(
              (act) =>
                (act?.AL_Type === "Reporting Activites" ||
                  act?.AL_Type === "Adhoc") &&
                act?.AL_Status === "1"
            )
            .map((a) => a.AL_SalseforceCode)
        );

        setAllActivities(
          data?.filter(
            (act) =>
              (act?.AL_Type === "Reporting Activites" ||
                act?.AL_Type === "Adhoc") &&
              act?.AL_Status === "1"
          )
        );
        return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoadingActivities(false); // End loading
    }
  };

  useEffect(() => {
    if (token) {
      fetchAllActivities();
    }
  }, [token]);

   useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          dropdownsearchRef.current &&
          !dropdownsearchRef.current.contains(event.target)
        ) {
          setIsSearchOpen(false);
        }
      };
  
      const handleScroll = () => {
        setIsSearchOpen(false);
      };
  
      document.addEventListener("mousedown", handleOutsideClick);
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsAccountResponsibleOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownsearchRef]);

  const [expanded, setExpanded] = useState(true);

  //fetching data
  const [page, setPage] = useState(1);
  const [masterData, setMasterData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);
  // const [isTableLoaded, setIsTableLoaded] = useState(false);
  const fetchInProgress = useRef(false); //

  const formatSelectedEmployees = (selected) => {
    const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
    return emIds?.join(",");
  };
  useEffect(() => {
    console.log(selectedEmployees);
    // formatSelectedEmployees(selectedEmployees);
  }, [selectedEmployees, selectedTeams, token]);

  const fetchCustomer = async (dateStart, dateEnd, first) => {
    const clientId = selectedSuggestions?.[0]?.Id;
    if (!allActivities?.length) return;
    if (loading || fetchInProgress.current) return;
    fetchInProgress.current = true; // Set the flag to true
    setLoading(true);

    const date = formatDate(selectedDay, selectedMonth, selectedYear);

    try {
      setIsTableLoaded(false);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${
          first ? 1 : page
        }&ItemsPerPage=${
          clientId ? 1 : 20
        }&CreatDate=${date}${
          clientId
            ? `&id=${clientId}` 
            : selectedEmployees.length > 0 || selectedPrepareName
            ? `&SubOwnerEMIDs=${formatSelectedEmployees(selectedEmployees)}`
            : ""
        }`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          setEnd(true);
        } else {
          const formattedIds = data.map((d) => `'${d?.Id}'`).join(", ");
          await fetchedReportsAndTasks(data, formattedIds, dateStart, dateEnd);
        }
      } else {
        setLoading(false);
        setEnd(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      fetchInProgress.current = false; // Reset the flag
    }
  };

  const fetchedReportsAndTasks = async (
    clientData,
    ids,
    dateStart,
    dateEnd
  ) => {
    if (!ids || !dateStart || !dateEnd) return;
    const startOfMonth = `${selectedYear}-${String(selectedMonth).padStart(
      2,
      "0"
    )}-01`;
    const endOfMonthDate = new Date(selectedYear, selectedMonth, 0);
    const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
      endOfMonthDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;
    console.log("Date Range:", { startOfMonth, endOfMonth });

    try {
      const reportsResponse = await fetch(
        `${reportApiUrl}/GetReports?ClientId=${ids}&DteRange1=${startOfMonth}&DteRange2=${endOfMonth}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (reportsResponse.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      let reportsData = [];
      if (reportsResponse.ok) {
        reportsData = await reportsResponse.json();
      }

      const tasksResponse = await fetch(
        `${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Reporting Activities`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (tasksResponse.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      let tasksData = [];
      if (tasksResponse.ok) {
        tasksData = await tasksResponse.json();
      }

      const currentMergedData = clientData.map((client) => {

        const taskDataForClient = allActivities.map((activity) => {
          const matchingTask = tasksData.find(
            (task) =>
              task.ActivityId === activity.AL_ID && task.ClientId === client.Id
          );

          return (
            matchingTask || {
              TotalTime: "0.00",
              ActivityId: activity.AL_ID,
              CreateDate: "",
              ClientId: client.Id,
              TaskStatus: "P",
            }
          );
        });
       
        const matchingReport = reportsData.find(
          (report) => report.ClientId === client.Id
        );

        const defaultReportFields = {
          Id: null,
          ReceivedVolume: "",
          PendingVolume: "",
          Volume2: "",
          CommentOfVolume: "",
          PaymentCheck: "",
          PaymentStatus: "",
          CommentOfPaymentFaild: "",
          ReportType: "",
          ReceivedDate: "",
          DueDate: "",
          DateOfSubmission: "",
          ClientId: client.Id,
          CreatedBy: "",
          SynergyPreparer: "",
          CreateDate: "",
        };

        return {
          ...client,
          ...(matchingReport || defaultReportFields),
          tasks: taskDataForClient,
        };
      });

      console.log((prev) => [...prev, ...currentMergedData]);
      setMergedData((prev) => [...prev, ...currentMergedData]);
      setIsTableLoaded(true);
    } catch (error) {
      console.error("Error fetching reports or tasks:", error);
    }
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleScroll = debounce(() => {
    // if (!isTableLoaded || loading || end) return;

    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setPage((p) => p + 1);
    }
  }, 1000);

  const isInitialRender = useRef(true);

  useEffect(() => {
    const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
    const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
    fetchCustomer(dateStart, dateEnd, false);
  }, [page, allActivities]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setEnd(false);
    setPage(1);
    setMergedData([]);
    setMasterData([]);
    // new Promise(resolve => setTimeout(resolve, 2000));

    const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
    const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
    fetchCustomer(dateStart, dateEnd, true);
  }, [selectedDay, selectedMonth, selectedYear, selectedEmployees, selectedSuggestions]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const formatDate = (day, month, year) => {
    const formattedMonth = String(month).padStart(2, "0"); // Ensure two-digit month
    const formattedDay = String(day).padStart(2, "0"); // Ensure two-digit day if needed
    return `${year}-${formattedMonth}-${formattedDay || "01"}`;
  };

  // //////////////////////////////////////////////////////////////////

  const getUserFirstName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user ? user.EM_FirstName + " " + user.EM_LastName : "";
  };
  const [receivedVolume, setReceivedVolume] = useState("");
  const [volume2, setVolume2] = useState("");
  const [processedVolume, setProcessedVolume] = useState(0);
  const [pendingVolume, setPendingVolume] = useState(0);
  const [comment, setComment] = useState("");
  const [paymentCheck, setPaymentCheck] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [commentOfPaymentFaild, setCommentOfPaymentFaild] = useState("");

  const debounceRef = useRef(null); // A ref to hold the debounce timeout

  const handleReportChange = async (data, val, e) => {
    const EM_ID = userData?.[0]?.EM_ID; // Assuming userData is coming from context or props

    if (!EM_ID) {
      console.error("Failed to retrieve user ID or Client ID.");
      return;
    }

    // Update the mergedData state with the new value
    const updatedMergedData = mergedData.map((item) => {
      if (item.ClientId === data.ClientId) {
        return { ...item, [val]: e.target.value }; // Dynamically update the field based on val
      }
      return item;
    });

    // Update the state immediately to reflect the change in UI
    setMergedData(updatedMergedData);
    console.log("Updated merged data:", updatedMergedData);

    // Prepare the payload based on the updated data
    const updatedItem = updatedMergedData.find(
      (item) => item.ClientId === data.ClientId
    );

    let payload;
    const isAdding = data.Id === null; // Determine if this is a POST or PUT
    if (isAdding) {
      payload = {
        ClientId: data.ClientId,
        ReceivedVolume: Number(updatedItem.ReceivedVolume) || 0,
        Volume2: Number(updatedItem.Volume2) || 0,
        PendingVolume: Number(updatedItem.PendingVolume) || 0,
        CommentOfVolume: updatedItem.CommentOfVolume || "No comment",
        PaymentCheck: updatedItem.PaymentCheck || "Not Applicable",
        PaymentStatus: updatedItem.PaymentStatus || "Pending",
        CommentOfPaymentFaild:
          updatedItem.CommentOfPaymentFaild || "No comment provided",
        ReportType: "Quarterly",
        ReceivedDate: updatedItem?.ReceivedDate || "",
        DueDate: updatedItem?.DueDate || "",
        DateOfSubmission: "2024-12-02",
        CreatedBy: EM_ID,
        SynergyPreparer: updatedItem?.SynergyPreparer,
      };
    } else {
      payload = {
        TaskReportId: data.Id, // Include TaskReportId for PUT
        ClientId: data.ClientId,
        ReceivedVolume: Number(updatedItem.ReceivedVolume) || 0,
        Volume2: Number(updatedItem.Volume2) || 0,
        PendingVolume: Number(updatedItem.PendingVolume) || 0,
        CommentOfVolume: updatedItem.CommentOfVolume || "No comment",
        PaymentCheck: updatedItem.PaymentCheck || "Not Applicable",
        PaymentStatus: updatedItem.PaymentStatus || "Pending",
        CommentOfPaymentFaild:
          updatedItem.CommentOfPaymentFaild || "No comment provided",
        ReportType: "Quarterly",
        ReceivedDate: updatedItem?.ReceivedDate || "",
        DueDate: updatedItem?.DueDate || "",
        DateOfSubmission: "2024-12-02",
        CreatedBy: EM_ID,
        SynergyPreparer: updatedItem?.SynergyPreparer,
      };
    }

    // Throttle API calls to once every 1 second
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(async () => {
      try {
        const url = isAdding
          ? `${reportApiUrl}/AddTaskReport` // POST URL
          : `${reportApiUrl}/UpdateTaskReport`; // PUT URL

        const method = isAdding ? "POST" : "PUT";

        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Assuming `token` is defined elsewhere
          },
          body: JSON.stringify(payload),
        });

        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }

        if (response.ok) {
          const result = await response.json();
          console.log(
            `${method === "POST" ? "Added" : "Updated"} successfully:`,
            result
          );

          if (isAdding) {
            // Fetch the report of the client only after adding
            const reportResponse = await fetch(
              `${reportApiUrl}/GetReports?ClientId='${data.ClientId}'&DteRange1=${selectedYear}-01-01&DteRange2=${selectedYear}-12-31`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 401) {
              toastRef.current.showToast(
                "Unauthorised access. Please login again.",
                "error"
              );
              await logout();
              return;
            }

            if (reportResponse.ok) {
              const reportData = await reportResponse.json();
              console.log("Fetched report data:", reportData);

              // Update mergedData with reportId
              const updatedMergedDataWithReportId = mergedData.map((item) => {
                if (item.ClientId === data.ClientId) {
                  return {
                    ...item,
                    Id: reportData?.[0]?.Id, // Update the Id field with the reportData Id
                    SynergyPreparer:
                      updatedItem?.SynergyPreparer || item.SynergyPreparer,
                    ReceivedDate:
                      updatedItem?.ReceivedDate || item.ReceivedDate,
                    DueDate: updatedItem?.DueDate || item.DueDate,
                  };
                }
                return item;
              });

              setMergedData(updatedMergedDataWithReportId);
              console.log(
                "Updated merged data with reportId:",
                updatedMergedDataWithReportId
              );
            } else {
              console.error(
                "Failed to fetch report data:",
                reportResponse.statusText
              );
            }
          }
        } else {
          console.error(
            `Error ${method === "POST" ? "adding" : "updating"} data:`,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error making API call:", error);
      } finally {
        debounceRef.current = null;
      }
    }, 1000); // Debounce delay of 1 second
  };

  ////////////////////////////////////////////////////////////////

  const handleDownload = async () => {
    const startOfMonth = `${selectedYear}-${String(selectedMonth).padStart(
      2,
      "0"
    )}-01`;
    const endOfMonthDate = new Date(selectedYear, selectedMonth, 0);
    const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
      endOfMonthDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;

    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const response = await fetch(`${reportApiUrl}/GetReportFile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DateRange1: startOfMonth,
          DateRange2: endOfMonth,
          ReportType: "Reporting Activities",
        }),
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const result = await response.json();
      if (result.Status) {
        const fileUrl = result.Result.URI;
        window.location.href = fileUrl;
      } else {
        console.error("Error in fetching report:", result);
      }
    } catch (error) {
      console.error("Error in fetch:", error);
    }
  };

  // const handleDownload = () => {
  //   // Format data for Excel
  //   const tableData = mergedData.map((data) => ({
  //     'Customer Name': data?.Name,
  //     'Project Number': data?.ProjectNumber,
  //     ...(expanded && { 'ERP System': data?.ERPSystem }),
  //     ...(expanded && { 'Cost Center': data?.CostCenter }),
  //     ...(expanded && { Department: data?.Department }),
  //     ...(expanded && { 'Department Head': data?.HODName }),
  //     ...(expanded && { 'View Account Owner': data?.OwnerEMId }),
  //     ...(expanded && { 'View Team Lead': data?.LeadId }),
  //     ...(expanded && { 'Synergy Accounting Responsible': getUserFirstName(data?.SubOwner) }),
  //     ...(expanded && { 'Synergy Quality Consultant': getUserFirstName(data?.SubQC) }),
  //     ...(expanded && { 'Synergy Process Lead': getUserFirstName(data?.SubProcLead) }),
  //     ...(expanded && { 'Synergy Department Lead': getUserFirstName(data?.SubLead) }),
  //     'Received Volumes': data?.ReceivedVolume,
  //     'Processed Volumes': data?.StatusCount,
  //     'Pending Volumes': data?.PendingVolume,
  //     'Volumes > 2 days': data?.Volume2,
  //     'Comments for Volumes': data?.CommentOfVolume,
  //     'Payment Check': data?.PaymentCheck,
  //     'Payment Status': data?.PaymentStatus,
  //     'Comments for Payment Failures': data?.CommentOfPaymentFaild,
  //   }));

  //   // Create a new workbook and add the table data
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(tableData);
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

  //   // Trigger download with the new file name
  //   XLSX.writeFile(workbook, 'STRStatusReport.xlsx');
  // };

  const calculateDateOfSubmission = (data) => {
    const allActivitiesPresent =
      data?.tasks?.length === 26 &&
      data?.tasks?.every((task) => task.TaskStatus !== "P");
    if (allActivitiesPresent) {
      // Return formatted CreateDate if all tasks are completed
      return formatDate(data?.CreateDate);
    }
    return "";
  };

  const calculateStandardTime = (data) => {
    const approvedTasks = data?.tasks?.filter(
      (task) => task.TaskStatus === "A"
    );
    console.log("Approved tasks:", approvedTasks);
    return approvedTasks?.length * 10; // 10 could be the standard time per task
  };

  // Calculate Actual Time by subtracting standard time from total task time
  const calculateActualTime = (data) => {
    const totalTime = data?.tasks?.reduce(
      (sum, task) => sum + parseFloat(task.TotalTime || 0),
      0
    );
    const standardTime = calculateStandardTime(data); // Get standard time from approved tasks
    const formattedTotalTime = totalTime.toFixed(2);
    const formattedStandardTime = standardTime.toFixed(2);

    console.log("Total Time:", formattedTotalTime);
    console.log("Standard Time:", formattedStandardTime);

    return parseFloat(formattedTotalTime); // Return the formatted value as a number
  };

  const calculateProgress = (data) => {
    const totalTasks = data?.tasks?.length || 0;
    const approvedTasks =
      data?.tasks?.filter((task) => task.TaskStatus === "A")?.length || 0;
    const rejectedTasks =
      data?.tasks?.filter((task) => task.TaskStatus === "R")?.length || 0;
    const tasksWithApprovedOrRejected = approvedTasks + rejectedTasks;
    const progress =
      totalTasks > 0
        ? ((tasksWithApprovedOrRejected / totalTasks) * 100).toFixed(2) + "%"
        : "0%";

    return progress;
  };
  const calculateProgression = (data) => {
    // Count of "Completed" (C) tasks
    const completedTasks = data?.tasks?.filter(
      (task) => task?.TaskStatus === "C"
    )?.length;
    const notApplicableTasks = data?.tasks?.filter(
      (task) => task?.TaskStatus === "A"
    )?.length;
    const totalActivities = data?.tasks?.length;
    const progression =
      totalActivities > 0
        ? ((completedTasks + notApplicableTasks) / totalActivities) * 100
        : 0;

    return `${progression.toFixed(2)}%`; // Return the percentage formatted with 2 decimal places
  };

  const calculateFinalStatus = (data) => {
    const progression = parseFloat(calculateProgression(data).replace("%", ""));
    return progression === 100 ? "Completed" : "Not Completed";
  };

  const getUserTeamName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user
      ? teamNames?.find((team) => team.id === parseFloat(user.STM_Team))
          ?.name || "Unknown Team"
      : "Unknown Team"; // Return team name or default to "Unknown Team"
  };

  useEffect(() => {
    console.log(allActivities);
  }, [allActivities]);
  const formatDateCreated = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const day = formattedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const tableContainer = document.querySelector(".table-container");

    if (tableContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        tableContainer.classList.add("active");
        startX = e.pageX - tableContainer.offsetLeft;
        scrollLeft = tableContainer.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseUp = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - tableContainer.offsetLeft;
        const walk = (x - startX) * 2; // Adjust multiplier for speed
        tableContainer.scrollLeft = scrollLeft - walk;
      };

      tableContainer.addEventListener("mousedown", handleMouseDown);
      tableContainer.addEventListener("mouseleave", handleMouseLeave);
      tableContainer.addEventListener("mouseup", handleMouseUp);
      tableContainer.addEventListener("mousemove", handleMouseMove);

      return () => {
        tableContainer.removeEventListener("mousedown", handleMouseDown);
        tableContainer.removeEventListener("mouseleave", handleMouseLeave);
        tableContainer.removeEventListener("mouseup", handleMouseUp);
        tableContainer.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);
  return (
    <div>
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />

      <div
        style={{
          display: "flex",
          gap: "28px",
          padding: "20px 24px",
          width: "98%",
          margin: "1rem auto",
          background: "white",
          borderRadius: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "28px" }}>
          <FormControl className="select-dropdown-user">
            <InputLabel
              id="team-selector-label"
              className="select-dropdown-label"
              shrink={false}
              onClick={toggleTeamSelector}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }} // Ensures clickable area
            >
              SELECT TEAMS
            </InputLabel>

            <Select
              labelId="team-selector-label"
              id="team-selector"
              multiple
              value={selectedTeams}
              onChange={handleChange}
              renderValue={(selected) =>
                selected
                  .map((teamId) => {
                    const team = teams.find((team) => team.id === teamId);
                    return team?.Name;
                  })
                  .join(" ")
              }
              style={{ width: "150px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {selectedTeams?.length > 0 && (
                <MenuItem onClick={(e) => handleClearAll(e)}>
                  <ListItemText
                    style={{ color: "red" }}
                    primary={"Clear All"}
                  />
                </MenuItem>
              )}
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div
            className="Title-STR-main2"
            onClick={handleAccountingResponsibleClick}
          >
            {selectedPrepareName?.EM_FirstName +
              selectedPrepareName?.EM_LastName || "Preparer Name"}
            <FontAwesomeIcon
              className="str-caret-icon"
              icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}
            />
            {isAccountResponsibleOpen && (
              <div ref={dropdownsearchRef} className="search-drop-DailyVoucher">
                <div className="search-drop-page">
                  <div className="main-suggestion">
                    <div className="input-suggestion">
                      <div className="icon-search-phospher">
                        <MagnifyingGlass
                          size={20}
                          color="#ada4a4"
                          weight="fill"
                        />
                      </div>
                      <input
                        type="text"
                        value={accSearch}
                        className="input-suggestion-search"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const value = e.target.value.toLowerCase();
                          setAccSearch(value);
                          setAccountResponsible2(
                            accountResponsible?.filter((acc) =>
                              (acc.EM_FirstName + " " + acc?.EM_LastName)
                                .toLowerCase()
                                .includes(value)
                            )
                          );
                        }}
                        placeholder="Start typing..."
                      />
                    </div>
                    <div tabIndex={1}>
                      <div
                        style={{ color: "#B00000", padding: "2px 22px" }}
                        onClick={() => {
                          setSelectedPrepareName();
                          setSelectedAccountResponsible([]);
                          setSelectedEmployees([]);
                        }}
                      >
                        Clear All
                      </div>
                      {accountResponsible2?.map((acc) => (
                        <div key={acc.EM_ID} className="suggestion-list-li">
                          <input
                            type="radio"
                            className="radio-searchbox"
                            style={{
                              accentColor: "rgb(80, 20, 90)",
                              width: "18px",
                              height: "18px",
                            }}
                            checked={selectedAccountResponsible?.some(
                              (item) => item.EM_ID === acc?.EM_ID // Compare using EM_ID for uniqueness
                            )}
                            onChange={() =>
                              handleCheckboxAccountResponsibleChange(acc)
                            }
                          />
                          <label className="li-content">
                            {acc.EM_FirstName + " " + acc.EM_LastName}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "24px" }}
        >
          <div className="calendar-picker" ref={calendarWrapperRef}>
            <button onClick={toggleCalendar} className="calendar-button">
              {`${months[selectedMonth - 1]} ${selectedYear}`}
            </button>
            {isCalendarOpen && (
              <div className="calendar-dropdown">
                <div className="year-selector">
                  <button onClick={handlePreviousYear} className="year-nav">
                    &lt;
                  </button>
                  <span className="year-display">{selectedYear}</span>
                  <button onClick={handleNextYear} className="year-nav">
                    &gt;
                  </button>
                </div>

                <div className="month-grid">
                  {months.map((month, index) => (
                    <div
                      key={index}
                      className={`month-item ${
                        index + 1 === selectedMonth ? "selected" : ""
                      }`}
                      onClick={() => handleMonthChange(index + 1)}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="Nav-btn-user">
            <FileArrowUp size={25} color="#3d025f" weight="fill" />
            <button className="Nav-Sub-btn" onClick={handleDownload}>
              DOWNLOAD FILES
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: "98%", margin: "1rem auto" }}>
        {/* <HorizontalDateCalender
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        /> */}
      </div>

      <div
        style={{
          width: "98%",
          margin: "1rem auto",
          padding: "20px 0px",
          overflow: "scroll",
        }}
        className="table-container"
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
            fontFamily: "Arial, sans-serif",
            border: "1px solid #ddd",
          }}
        >
          <thead>
            <tr>
              <th
                className="header-customername"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Customer Name
                  <FunnelSimple
                    className="icon-search-dropdown"
                    onClick={handleCustomersearchClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                </div>
                                {isSearchOpen && (
                                  <div
                                    ref={dropdownsearchRef}
                                    className="search-drop-design-report"
                                    style={{
                                      marginLeft: "140px !important",
                                      marginTop: "23px !important",
                                    }}
                                  >
                                    <div className="search-drop-page">
                                      <div className="main-suggestion">
                                        <div className="input-suggestion">
                                          <div className="icon-search-phospher">
                                            <MagnifyingGlass
                                              size={20}
                                              color="#ada4a4"
                                              weight="fill"
                                            />{" "}
                                          </div>
                
                                          <input
                                            type="text"
                                            value={query}
                                            className="input-suggestion-search"
                                            onChange={(e) => setQuery(e.target.value)}
                                            placeholder="Start typing..."
                                          />
                
                                          <button
                                            className="clear-title"
                                            onClick={handleClearAllSearch}
                                          >
                                            Clear All
                                          </button>
                                        </div>
                                        {searchLoading ? (
                                          <p className="suggestion-title">
                                            <CircularProgress
                                              width={30}
                                              style={{ color: "purple" }}
                                            />
                                          </p>
                                        ) : (
                                          <div style={{ maxHeight: "500px" }} tabIndex={1}>
                                            {suggestions.map((suggestion) => (
                                              <div
                                                key={suggestion.Id}
                                                className="suggestion-list-li"
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="check-searchbox"
                                                  checked={selectedSuggestions.some(
                                                    (item) => item.Id === suggestion.Id
                                                  )}
                                                  onChange={() =>
                                                    handleCheckboxChange(suggestion)
                                                  }
                                                />
                                                <label className="li-content">
                                                  {suggestion.Name}
                                                </label>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
              </th>
              <th
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  position: "relative",
                  minWidth: "200px",
                  textAlign: "start",
                }}
              >
                {" "}
                Project Number
                <span
                  style={{
                    position: "absolute",
                    minWidth: "150px",
                    right: "-115px",
                    top: "20px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  {" "}
                  {expanded ? (
                    <CaretCircleDoubleLeft size={32} color="#080808" />
                  ) : (
                    <CaretCircleDoubleRight size={32} color="#080808" />
                  )}
                </span>
              </th>
              {expanded && (
                <th
                  style={{
                    backgroundColor: "#fff",
                    padding: "15px",
                    minWidth: "150px",
                  }}
                >
                  ERP System
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Cost Center
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Department{" "}
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Department Head{" "}
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  View Account Owner
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  View Team Lead{" "}
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Synergy Accounting responsible
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Synergy Quality Consultant{" "}
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Synergy Process Lead
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Synergy Department Lead
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Synergy Team
                </th>
              )}
              {expanded && (
                <th
                  className="Expand-styles"
                  style={{ padding: "15px", fontFamily: "Poppins" }}
                >
                  Frequency Report Customer
                  <span
                    style={{
                      position: "absolute",
                      minWidth: "150px",
                      right: "-120px",
                      top: "20px",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => setExpandedActivities(!expandedActivities)}
                  >
                    {expandedActivities ? (
                      <CaretCircleDoubleRight size={32} color="#080808" />
                    ) : (
                      <CaretCircleDoubleLeft size={32} color="#080808" />
                    )}
                  </span>
                </th>
              )}

              {expandedActivities &&
                allActivities?.map((act) => {
                  return (
                    <th
                      className="Expand-styles"
                      style={{ minWidth: "250px", padding: "15px" }}
                      key={act.AL_ID}
                    >
                      {act.AL_Name}
                    </th>
                  );
                })}

              <th className="last-portion-style">Prepare Progress</th>
              <th className="last-portion-style">Final Status</th>
            </tr>
          </thead>
          <tbody>
            {/* {(selectedSuggestions.length > 0 ? selectedSuggestions : mergedData)
              ?.length > 0 ? (
              (selectedSuggestions.length > 0
                ? selectedSuggestions
                : mergedData
              )?.map((data, index) => { */}
                {mergedData
              ?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#F1F1F1" : "#FFFFFF",
                    }}
                  >
                    <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                      {data?.Name}
                    </td>
                    <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                      {data?.ProjectNumber}
                    </td>
                    {expanded && (
                      <>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {data?.ERPSystem}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                        {selectedSuggestions?.length > 0 ? data?.CostCenterName : data?.CostCenter}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                        {selectedSuggestions?.length > 0 ? data?.DepartmentName : data?.Department}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {data?.HODName}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {data?.OwnerEMId}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {data?.LeadId}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {getUserFirstName(data?.SubOwner)}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {getUserFirstName(data?.SubQC)}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {" "}
                          {getUserFirstName(data?.SubProcLead)}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {getUserFirstName(data?.SubProcLead)}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {" "}
                          {getUserTeamName(data?.SubOwner)}
                        </td>
                        <td style={{ padding: "15px", fontFamily: "Poppins" }}>
                          {" "}
                          {data?.ReportingFrequencyName}
                        </td>
                      </>
                    )}

                    {expandedActivities &&
                      (data?.tasks || allActivities).map((act) => {
                        const statusDisplay =
                          act?.TaskStatus === "C"
                            ? "Completed"
                            : act?.TaskStatus === "A"
                            ? "Applicable"
                            : act?.TaskStatus === "R"
                            ? "Not Completed"
                            : act?.TaskStatus === "P"
                            ? "Pending"
                            : "Pending";

                        const statusColor =
                          act?.TaskStatus === "C"
                            ? "#007F40"
                            : act?.TaskStatus === "P"
                            ? "gray"
                            : act?.TaskStatus === "A"
                            ? "darkgray"
                            : act?.TaskStatus === "R"
                            ? "#B00000"
                            : "black";

                        return (
                          <td
                            key={act?.ActivityId}
                            style={{
                              minWidth: "250px",
                              textAlign: "center",
                              padding: "15px",
                              color: statusColor,
                            }}
                          >
                            {statusDisplay}
                          </td>
                        );
                      })}

                    <th
                      className="last-portion-style"
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F1F1F1" : "#FFFFFF",
                        padding: "15px",
                      }}
                    >
                      {calculateProgression(data)}
                    </th>
                    <th
                      className="last-portion-style"
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F1F1F1" : "#FFFFFF",
                        padding: "15px",
                      }}
                    >
                      {calculateFinalStatus(data)}
                    </th>
                  </tr>
                );
              })
           
            }
          </tbody>
        </table>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <CircularProgress style={{ color: "purple" }} width={40} />
          </div>
        )}
        {end && <div>No more Data...</div>}
      </div>
    </div>
  );
};
// Add filters for selecting employee, and team. If team is selected, loop for all employees in that team.
// Based on the applied filter call 10-10 customers in a batch.
// get customer data and map it on to the UI table.
// Along with each customer get their Daily Voucher stock data.
// FlatMap these two data of each customer and show it on the UI.
// As soon as anything is changed for a customer, check whether a voucherStock entry exists for that customer in that day, if yes, update, else create.

export default ReportingActivitySheet;

// const updated=await getSingleReport(data.ClientId,dateStart,dateEnd)
