import React, { useContext, useEffect, useRef, useState } from "react";

import "../Styles/ContentNew1.css";

import { useNavigate } from "react-router-dom";
import  isGuestUser  from '../utils/guestUser';

import logo123 from "../Images/NewLogo.png";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import {
  Bell,
  MagnifyingGlass,
  DotsThreeOutlineVertical,
  User,
  DotOutline,
  PlusCircle,
  CaretDown,
  Download,
  PencilSimpleLine,
  Export,
  FunnelSimple,
  Backpack,
  CaretLeft,
} from "@phosphor-icons/react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { AuthContext } from "../context/AuthContext";

import * as XLSX from "xlsx";

import NavSub from "../components/NavSub";

import NavMain from "../components/NavMain";

import SuggestionComponent from "../components/Suggestion";

import { UserContext } from "../context/UserContext";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToastComponent from "../components/ToastComponent";

// import CustomerData2 from '../pages/CustomerData2';

// import {  Trash } from '@phosphor-icons/react';

function CustomerDetail() {
  const navigate = useNavigate();

  const { usersList, setUsersList } = useContext(UserContext);

  const [searchQuery, setSearchQuery] = useState("");

  // const [masterData, setMasterData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [approveLoading, setApproveLoading] = useState(false);

  const {
    token,
    userData,
    connectionIdMasterData,
    setConnectionId,
    notificationsForMasterData,
  } = useContext(AuthContext);

  const [menuData, setMenuData] = useState([]);

  const [masterData, setMasterdata] = useState([]);

  const [selectedMasterData, setSelectedMasterData] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [activeItem, setActiveItem] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState(false);

  const [isSecondaryDropdownOpen, setIsSecondaryDropdownOpen] = useState(false);

  const [isthirdDropdownOpen, setIsthirdDropdownOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [invalidMessage, setInvalidMessage] = useState(false);

  const [isbellDropdownOpen, setIsbellDropdownOpen] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [isDepartmentOpen, setIsDepartmentOpen] = useState(false);

  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] =
    useState(false);

  const [query, setQuery] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState(
    []
  );

  const [updateAccResponsible, setUpdateAccResponsible] = useState("");
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState([]);

  const [department2, setDepartment2] = useState([]);

  const [deptAdded, setDeptAdded] = useState(false);

  const [accountResponsible, setAccountResponsible] = useState([]);

  const [accountResponsible2, setAccountResponsible2] = useState([]);

  const [accRespAdded, setAccRespAdded] = useState(false);

  const [removedDeptId, setRemovedDeptId] = useState();

  const [removedAccId, setRemovedAccId] = useState();

  const [deptSearch, setDeptSearch] = useState("");

  const [accSearch, setAccSearch] = useState("");

  const [selectedDeptNames, setSelectedDeptNames] = useState([]);
  const [selectedAccNames, setSelectedAccNames] = useState([]);

  const debounceTimeout = useRef(null);

  const changeTypeDep = useRef("");

  const changeTypeAcc = useRef("");

  const handleCustomersearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleAccountingResponsibleClick = () => {
    setIsAccountResponsibleOpen(!isAccountResponsibleOpen);
  };

  const dropdownsearchRef = useRef(null);

  const dropdownDepartmentRef = useRef(null);

  const dropdownAccountResponsibleRef = useRef(null);

  const style = {
    position: "absolute",

    top: "50%",

    left: "50%",

    transform: "translate(-50%, -50%)",

    width: 900,

    bgcolor: "rgba(242, 235, 255, 1)",

    // border: '2px solid #000',

    boxShadow: 24,

    p: 4,
  };

  const clientRejectData = async () => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/ClientDataReject`,

        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify({
            RequestBy: userData?.[0]?.EM_ID,
          }),
        }
      );

      const data = await response.json();
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (data) {
        setResponseFromUpload([]);
        // toastRef.current.showToast(
        //   "Data Rejected Successfully.",
        //   "success"
        // );
        return data;
      }
    } catch (e) { }
  };

  const clientApproveData = async () => {
    setApproveLoading(true);
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      // Check if token is present
      // if (!token) {
      //   toastRef.current.showToast('Please Login to access', 'error');
      //   await logout();
      //   return;
      // }

      const response = await fetch(`${clientApiUrl}/ClientDataApprove`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          RequestBy: userData?.[0]?.EM_ID,
        }),
      });

      const data = await response.json();
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (data) {
        setMasterdata([]);
        setSelectedMasterData([]);
        toastRef.current.showToast(
          "Data Accepted Successfully.",
          "success"
        );
        // Fetch customer data with updated parameter
        fetchCustomer(true);
        setApproveLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error approving client data:", error);
      // Show error toast message or handle the error as needed
      toastRef.current.showToast(
        "Please login again.",
        "error"
      );
      setApproveLoading(false);

    }
    finally{
      setApproveLoading(false);

    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = async () => {
    // if (responseFromUpload?.length > 0) {

    //   if (

    //     window.confirm(

    //       "Are you sure you want to cancel the process and upload again?"

    //     )

    //   ) {

    //     await clientRejectData();

    //     setOpen(false);

    //   }

    // } else {

    handleMenuClose();
    setErrorMsg(false);

    setOpen(false);

    // }
  };

  const [openError, setOpenError] = React.useState(false);

  const handleErrorOpen = () => setOpen(true);

  const handleErrorClose = async () => {
    // if (responseFromUpload?.length > 0) {

    //   if (

    //     window.confirm(

    //       "Are you sure you want to cancel the process and upload again?"

    //     )

    //   ) {

    //     await clientRejectData();

    //     setOpen(false);

    //   }

    // } else {

    // handleMenuClose();

    setOpenError(false);

    // }
  };

  const { handleAuthExpiry, logout } = useContext(AuthContext);

  const fetchDepartments = async () => {
    try {
      setLoading(true);

      // Check if token is present
      // if (!token) {
      //   toastRef.current.showToast('Please Login to access', 'error');
      //   await logout();
      //   return;
      // }

      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=500`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Check for authentication error
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setDepartment(data);
        setDepartment2(data);
      } else {
        console.error("Network response was not ok");
        // Show error toast message or handle the error as needed
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Show error toast message or handle the error as needed
    }
  };

  useEffect(() => {
    if (token) {
      fetchDepartments();
    }
  }, [token]);

  useEffect(() => {
    setAccountResponsible(usersList);

    setAccountResponsible2(usersList);
  }, [usersList, token]);

  const handleClicksearchOutside = (event) => {
    if (
      dropdownsearchRef.current &&
      !dropdownsearchRef.current.contains(event.target)
    ) {
      setIsSearchOpen(false);

      setIsAccountResponsibleOpen(false);

      setIsDepartmentOpen(false);
      setEnd(true);

      // setIsSecondaryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClicksearchOutside);

    return () => {
      document.removeEventListener("mousedown", handleClicksearchOutside);
    };
  }, []);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const dropdownRef = useRef(null);

  const dropdown2Ref = useRef(null);

  const dropdown3Ref = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsPrimaryDropdownOpen(false);

      setIsSecondaryDropdownOpen(false);
    }
  };

  // ////////////////////////////////////////

  const handleClick2Outside = (event) => {
    if (dropdown2Ref.current && !dropdown2Ref.current.contains(event.target)) {
      setIsbellDropdownOpen(false);

      // setIsSecondaryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick2Outside);

    return () => {
      document.removeEventListener("mousedown", handleClick2Outside);
    };
  }, []);

  // /////////////////////////////////////////

  const handleClick3Outside = (event) => {
    if (dropdown3Ref.current && !dropdown3Ref.current.contains(event.target)) {
      setIsthirdDropdownOpen(false);

      // setIsSecondaryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick3Outside);

    return () => {
      document.removeEventListener("mousedown", handleClick3Outside);
    };
  }, []);

  // //////////////////////////////////////

  const [page, setPage] = useState(1);

  const loader = useRef(null);

  const [end, setEnd] = useState(false);

  const isMounted = useRef(true);

  const [componentKey, setComponentKey] = useState(0);

  const fetchCustomer = async (updated) => {
    //console.log(updated);
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${updated ? 1 : page}&ItemsPerPage=${updated ? 70 : 20
        }`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        if (toastRef.current) {
          toastRef.current.showToast("Please Login to access", "error");
        } else {
          console.error("Please Login to access");
        }
        await logout();
        return;
      }

      const handleAuthError = await handleAuthExpiry(response);
      if (handleAuthError) {
        if (toastRef.current) {
          toastRef.current.showToast("Your Session has expired", "error");
        } else {
          console.error("Your Session has expired");
        }
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          setEnd(true);
          setLoading(false);
          return;
        } else {
          setMasterdata((prevItems) => [...prevItems, ...data]);
          setSelectedMasterData((prevItems) => [...prevItems, ...data]);

          if (updated) {
            // Trigger a remount by updating the key
            setComponentKey((prevKey) => prevKey + 1);
          }
        }
      } else {
        console.error("Failed to fetch");
        setEnd(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (toastRef.current) {
        toastRef.current.showToast(
          "Error fetching data. Please try again.",
          "error"
        );
      } else {
        console.error("Error fetching data. Please try again.");
      }
      setEnd(true);
      setLoading(false);
    }
  };

  const fetchCustomerAfterEdit = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${1}&ItemsPerPage=20`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          ////console.log("End of data");

          setEnd(true);
        } else {
          ////console.log(data);

          setMasterdata(data);

          setSelectedMasterData(data);
          setPage(2);
        }
      } else {
        console.error("Failed to fetch");

        setEnd(true);
      }
    } catch (error) {
      ////console.log(error);

      setEnd(true);
    }
  };

  const loadData = async () => {
    if (selectedDepartments.length > 0) {
      const wait = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1));
      };
      await wait();

      // if (deptAdded) {
      //   alert('this also called?')
      //   await fetchSuggestionByDept();
      // }
    } else if (selectedAccountResponsible.length > 0) {
      const wait = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1));
      };
      await wait();

      if (accRespAdded) {
        await fetchSuggestionByAcc();
      }
    } else if (selectedSuggestions?.length > 0) {
      return;
    } else {
      if (isMounted.current) {
        //console.log("here??");
        await fetchCustomer(false);
      }
    }
  };
  useEffect(() => {
    isMounted.current = true;

    loadData();

    return () => {
      isMounted.current = false;
    };
  }, [page, token]);

  useEffect(() => {
    //console.log(selectedSuggestions);
    if (
      selectedSuggestions?.length == 0 &&
      selectedAccNames?.length == 0 &&
      selectedDeptNames?.length == 0
    ) {
      setSelectedMasterData(masterData);
    } else if (selectedSuggestions?.length > 0) {
      setSelectedMasterData(selectedSuggestions);
    } else if (
      selectedDeptNames?.length > 0 &&
      changeTypeDep.current === "addDep"
    ) {
      // alert('This called');
      fetchSuggestionByDept();
    } else if (
      selectedDeptNames?.length > 0 &&
      changeTypeDep.current === "removeDep"
    ) {
      if (removedDeptId) {
        setSelectedMasterData(
          selectedMasterData?.filter((d) => d.Department !== removedDeptId)
        );
      }
    } else if (
      selectedAccNames?.length > 0 &&
      changeTypeAcc.current === "addAcc"
    ) {
      fetchSuggestionByAcc();
    } else if (
      selectedAccNames?.length > 0 &&
      changeTypeAcc.current === "removeAcc"
    ) {
      if (removedAccId) {
        //console.log("gett");
        //console.log(removedAccId);
        //console.log(selectedMasterData[0]);
        setSelectedMasterData(
          selectedMasterData?.filter((a) => a.SubOwner !== removedAccId)
        );
      }
    }
  }, [selectedSuggestions, selectedAccNames, selectedDeptNames, token]);

  useEffect(() => {
    const options = {
      root: null,

      rootMargin: "20px",

      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  const handleObserver = (entities) => {
    //console.log("first");
    const target = entities[0];

    if (target.isIntersecting) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [customerMenuAnchorEl, setCustomerMenuAnchorEl] = useState(null);

  const [departmentMenuAnchorEl, setDepartmentMenuAnchorEl] = useState(null);

  const [dotsMenuAnchorEl, setDotsMenuAnchorEl] = useState(null);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);

  const [accountMenuEditAnchorEl, setAccountMenuEditAnchorEl] = useState(null);

  const [accChangeLoading, setAccChangeLoading] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBackClick = (event) => {
    setDisplayEditSection(!displayEditSection);
  };

  const handleAccountMenuClickEdit = (event) => {
    setAccountMenuEditAnchorEl(event.currentTarget);
  };

  const handleDepartmentMenuClick = (event) => {
    // setDepartmentMenuAnchorEl(event.currentTarget);

    setIsDepartmentOpen(!isDepartmentOpen);
  };

  const handleMenuClose = () => {
    setCustomerMenuAnchorEl(null);

    setDepartmentMenuAnchorEl(null);

    setDotsMenuAnchorEl(null);

    setAnchorEl(null);

    setAccountMenuAnchorEl(null);

    setAccountMenuEditAnchorEl(null);
    setEnd(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (employeeId) => {
    handleMenuClose(); // Close the menu

    navigate(`/CustomerData2/${employeeId}`); // Navigate to the desired route
  };

  const handleImportFiles = () => {
    handleMenuClose(); // Close the menu
  };

  const handleExportFiles = () => {
    handleMenuClose();
  };

  const handleAddNewCustomer = () => {
    handleMenuClose();

    navigate(`/AddClient`);
  };

  const [displayEditSection, setDisplayEditSection] = useState(false);

  const [checkedMasterDataForEdit, setCheckedMasterDataForEdit] = useState([]);

  const [waitingToCallApi, setWaitingToCallApi] = useState(false);

  const handleEditClick = () => {
    setDisplayEditSection(!displayEditSection);

    handleMenuClose();
  };

  const handleCheckboxMasterEditChange = (employee) => {
    setCheckedMasterDataForEdit((prev) => {
      const isChecked = prev.some((emp) => emp.Id === employee.Id);

      if (isChecked) {
        return prev.filter((emp) => emp.Id !== employee.Id);
      } else {
        return [...prev, employee];
      }
    });
  };

  const fetchSuggestionByText = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        // alert("Error");

        setLoading(false);

        return;
      }

      const data = await response.json();

      ////console.log(data);

      setSuggestions(data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);

      setLoading(false);
    }
  };


  // steps:
  // fetch the required ids when you selct the checkbox. when checkbox is selected, i am updating recentlyAddedDep state variable, along with selectedDepartments state variable. selectedDepartments state variable triggers this function from useEffect.
  // Check if it is the first ever department selected, if it is, clear out the selectedMasterData. check if selectedDepartments has any other department with the same name property as the recentlyAddedDep's name property. If there, otherExists is true, meaning, this is not the first selected  department. No need to clear. If not i.e if this is the first selcted item, clear the data.
  // Extract out all the department ids (reqdIds) that has a same name as recentlyAddedDep. Then create a  departmentIds variable that maps all department ids. const departmentIds = reqdIds.map(d => d.id)
  // for each department id, fetch for page 1 append it to selectedMasterData array, do the same for page 2 , page 3.. until a page returns [];move to next department Id .


  const fetchSuggestionByDept = async () => {
    if(!token){
      return;
    }
    console.log(recentlyAddedDep);
    setLoading(true);
    setEnd(false);
    // Filter to get the department IDs based on recentlyAddedDep
    const reqdIds = department?.filter(d => d?.name === recentlyAddedDep);
    console.log(reqdIds);
  
    if (!reqdIds || reqdIds.length === 0) {
      console.log("No department IDs found");
      // setLoading(false);
      return;
    }
  
    // Extract the department IDs into an array
    const departmentIds = reqdIds.map(d => d.id);
    console.log(departmentIds); // Log the department IDs (e.g., ['1', '2'])
  
    // Check if any other departments already exist in the selectedDepartments state
    const otherExist = selectedDepartments?.find(i => i?.name !== recentlyAddedDep);
    console.log(otherExist);
  
    // Clear selectedMasterData if no other department exists (i.e., this is the first selected department)
    if (!otherExist) {
      setSelectedMasterData([]);
    }
  
    let allData = [];  // Array to accumulate all fetched data
  
    try {
      // Iterate over the department IDs and make separate API calls for each
      for (let deptId of departmentIds) {
        setLoading(true);
        let currentPage = 1;  // Start with page 1 for each department
        let hasMoreData = true;  // Flag to continue fetching pages for each department
  
        while (hasMoreData) {

          setLoading(true);
          const response = await fetch(
            `${clientApiUrl}/GetAllClient?Page=${currentPage}&ItemsPerPage=20&DepartmentId=${deptId}`,
            {
              method: "GET",
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          if (response.status === 401) {
            toastRef.current.showToast(
              "Unauthorised access. Please login again.",
              "error"
            );
            await logout();
            return;
          }
  
          if (!response.ok) {
            console.error("Error fetching data for department:", deptId);
            setLoading(false);
            return;
          }
  
          const data = await response.json();
          console.log(`Fetched data for department ${deptId} on page ${currentPage}:`, data);
  
          // Append the fetched data to the allData array
          allData.push(...data);
  
          // Check if there is no more data to fetch for this department
          if (data.length === 0) {
            hasMoreData = false;  // Stop fetching more pages
          } else {
            currentPage += 1;  // Increment the page for further fetches
          }
        }
      }
  
      // After all the department data has been fetched, update the state in one go
      if (allData.length > 0) {
        if (!otherExist) {
          // If it's the first department, replace the data
          setSelectedMasterData(allData);
        } else {
          // If other departments already exist, append the new data
          setSelectedMasterData((prevItems) => [...prevItems, ...allData]);
        }
      } else {
        // setEnd(true);  // If no data is fetched, mark it as the end
      }
      // setEnd(true);
    } catch (error) {
      setEnd(true);
      console.error("Error fetching data:", error);
      setLoading(false);
    }
    setEnd(true);
    setLoading(false);
  };
  

  const fetchSuggestionByAcc = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${page}&ItemsPerPage=3&SubOwnerEMID=${selectedAccountResponsible[selectedAccountResponsible.length - 1]
          .EM_ID
        }`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        toastRef.current.showToast(
          "Something went wrong! ",
          "error"
        );
        setLoading(false);

        return;
      }

      const data = await response.json();

      ////console.log(data);

      // setSuggestions(data);

      if (selectedAccountResponsible?.length === 1 && page === 1) {
        setSelectedMasterData(data);
        if (data.length === 0) {
          setLoading(false);

          setEnd(true);
          return;
        } else {
          setPage(page + 1);
        }
      } else {
        ////console.log([...selectedMasterData, ...data]);

        setSelectedMasterData([...selectedMasterData, ...data]);

        if (data.length === 0) {
          setLoading(false);

          setEnd(true);
          return;
        } else {
          setPage(page + 1);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);

      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);

      setLoading(false);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const handleCheckboxChange = (suggestion) => {
    //console.log(suggestion);
    if (selectedDepartments?.length > 0) {
      setDeptSearch("");

      setSelectedDepartments([]);
    }

    if (selectedAccountResponsible?.length > 0) {
      setAccSearch("");

      setSelectedAccountResponsible([]);
    }

    if (selectedSuggestions?.length === 0) {
      setPage(1);
    }
    //console.log(selectedSuggestions);
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected.some((item) => item.Id === suggestion.Id)) {
        return prevSelected.filter((item) => item.Id !== suggestion.Id);
      } else {
        return [...prevSelected, suggestion];
      }
    });
  };
  const [recentlyAddedDep, setRecentlyAddedDep] = useState('')
  const handleCheckboxDepartmentChange = async (dep) => {
    setRecentlyAddedDep(dep.name)
    console.log(dep);
    // return;
    //console.log(selectedDepartments);
    //console.log(selectedMasterData);
    await new Promise((resolve) => setTimeout(resolve, 1));

    setPage(1);

    if (selectedSuggestions?.length > 0) {
      setQuery("");

      setSelectedSuggestions([]);
    }

    if (selectedAccountResponsible?.length > 0) {
      setAccSearch("");
      setSelectedAccNames([]);
      setSelectedAccountResponsible([]);
    }

    if (selectedDepartments?.length) {
      setPage(1);
    }

    setSelectedDepartments((prevSelected) => {
      const isRemoving = prevSelected.some((item) => item.name === dep.name);

      changeTypeDep.current = isRemoving ? "removeDep" : "addDep";

      if (isRemoving) {
        setDeptAdded(false);
        setRemovedDeptId(dep.name);
        setSelectedDeptNames(
          selectedDeptNames?.filter((item) => item.name !== dep.name)
        );
        //console.log(selectedDeptNames);

        return prevSelected.filter((item) => item.name !== dep.name);
      } else {
        setDeptAdded(true);
        setSelectedDeptNames([...selectedDeptNames, ...department?.filter(d => d?.name == dep.name)]);
        //console.log(selectedDeptNames);
        return [...prevSelected, ...department?.filter(d => d?.name == dep.name)];
      }
    });

    ////console.log(selectedDepartments);
  };

  const handleCheckboxAccountResponsibleChange = (acc) => {
    ////console.log(acc);
    setPage(1);
    //console.log(acc);
    //console.log(selectedAccountResponsible);
    //console.log(selectedMasterData);

    if (selectedSuggestions?.length > 0) {
      setQuery("");

      setSelectedSuggestions([]);
    }

    if (selectedDepartments?.length > 0) {
      setDeptSearch("");
      setSelectedDeptNames([]);
      setSelectedDepartments([]);
    }

    if (selectedAccountResponsible?.length) {
      //console.log("Working");
      setPage(1);
    }

    setSelectedAccountResponsible((prevSelected) => {
      const isRemoving = prevSelected.some(
        (item) => item.EM_FirstName+' '+ item.EM_LastName === acc.EM_FirstName+ ' '+acc.EM_LastName
      );

      changeTypeAcc.current = isRemoving ? "removeAcc" : "addAcc";

      if (isRemoving) {
        setAccRespAdded(false);
        //console.log("thhtyhth");
        //console.log(selectedAccountResponsible);
        setRemovedAccId(acc.EM_ID);
        setSelectedAccNames(
          selectedAccountResponsible.filter(
            (item) => item.EM_FirstName+' '+item.EM_LastName !== acc.EM_FirstName+' '+item.EM_LastName
          )
        );
     
        return prevSelected.filter(
          (item) => item.EM_FirstName+' '+item.EM_LastName !== acc.EM_FirstName+' '+acc.EM_LastName
        );
      } else {
        setAccRespAdded(true);
        setSelectedAccNames([...selectedAccNames, acc]);
        return [...prevSelected, acc];
      }
    });

    ////console.log(selectedAccountResponsible);
  };

  const handleClearAll = () => {
    setQuery("");

    setSelectedSuggestions([]);
  };

  const handleClearAllDept = () => {
    setDeptSearch("");

    setSelectedDepartments([]);
    setSelectedDeptNames([]);
  };

  const handleClearAllAcc = () => {
    setAccSearch("");

    setSelectedAccountResponsible([]);
    setSelectedAccNames([]);
  };

  const handleDownload = async () => {
    try {
      // Fetch the file URL from the API
      const response = await fetch(`${clientApiUrl}/GetExcelFile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);

      if (data.Status && data.Result && data.Result.URI) {
        // Create a link element
        const link = document.createElement("a");
        link.href = data.Result.URI;
        link.download = "file.xlsx"; // Optionally specify a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Invalid response structure:", data);
      }

      handleMenuClose();
    } catch (error) {
      console.error("Error handling download:", error);
    }
  };
  const handleDownloadSample = () => {
    // Path to your Excel file in the public folder
    const fileUrl = `${process.env.PUBLIC_URL}/MasterDataSampleFile.xlsx`;

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'sample.xlsx'); // Set the file name

    // Append to the body
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Remove the link after download
  };

  function extractIds(data) {
    const ids = data.map((item) => item.Id);

    const idsString = ids.join(",");

    return idsString;
  }

  const updateAccountResponsible = async () => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    if (checkedMasterDataForEdit.length === 0) {
      return;
    }
    setAccChangeLoading(true);

    const chunkSize = 5;

    try {
      // Break the checkedMasterDataForEdit array into chunks of 30 items
      for (let i = 0; i < checkedMasterDataForEdit.length; i += chunkSize) {
        const chunk = checkedMasterDataForEdit.slice(i, i + chunkSize);
        const response = await fetch(`${clientApiUrl}/UpdateAcResponsible`, {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify({
            AccountResponsibleEmId: updateAccResponsible?.EM_ID,
            ClientIds: extractIds(chunk), // Send the chunk of 30 items
          }),
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        const data = await response.json();

        if (!data) {
          setAccChangeLoading(false);
          return;
        }
      }

      // If all requests are successful, reset the necessary states
      setAccChangeLoading(false);
      setAccSearch("");
      setDeptSearch("");
      setSelectedDepartments([]);
      setSelectedDeptNames([]);
      setSelectedAccountResponsible([]);
      setSelectedAccNames([]);
      setSelectedSuggestions([]);
      setAccountMenuEditAnchorEl(false);
      setMasterdata([]);
      setSelectedMasterData([]);
      setCheckedMasterDataForEdit([]);
      setDisplayEditSection(false);
      // setPage(1);
      fetchCustomer(true);
    } catch (e) {
      setAccChangeLoading(false);
      alert("Something went wrong");
    }
  };

  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    // //console.log("File selected:", e.target.files[0].name);
    await clientRejectData();
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      handleExcelSubmit();
    }
  }, [file]);

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const requiredColumns = [
    "Project Number",

    "Project Task Name",

    "Customer Name",

    "Customer Type",

    "Org. Number",

    "Cost Center",

    "Account Owner",

    "Authorized Accountant",

    "Type",

    "Country",

    "ERP System",

    "Industry",

    "Price Agreement",

    "Parent Account",

    "Termination Date",

    "Account Status",

    "Outsourcing Sub-processor",

    "Outsourcing start date",

    "Frequency - report to customer",

    "Customer Reporting Date (WD)",

    "Bank Remittance Type",

    "Frequency voucher processing",

    "Department",

    "Department head",

    "View - Account Owner",

    "View - Team Lead",

    "Synergy - Accountanting Responsible",

    "Synergy - Quality Consultant",

    "Synergy - Process Lead",

    "Synergy - Department Lead",

    "Accounting Info updated (Y/N)",

    "Accounting Info updated Date",

    "Outsourcing end date",

    "Supplier Invoice - Non EHF",

    "Supplier Invoice - EHF",

    "Supplier Invoice - EHF (VIC.AI)",

    "Payment Register",

    "Advanced Voucher / Manual Journal",

    "Customs Declaration",

    "Bank Reconciliation - Postings",

    "Customer / Supplier Ledger",

    "VAT Reporting & Customs Declarations",

    "Reconciliation TAX AGA",

    "Prepare Reporting",

    "Depreciation / Monthly Journals",

    "Month end tasks (Clearing and others)",

    "Review of PL - BS Accounts",

    "Balance Recons",

    "Consulting – Administration",
    "Synergy Price Agreement",
 
    "Fixed Hours for Voucher Processing(Min)",
 
    "STR Activity – Average Time(Min)",
 
    "Synergy Fixed Hours(Min)"
  ];

  const handleExcelSubmit = async () => {
    handleMenuClose();
    setOpen(true);
    setWaitingToCallApi(true);
    setInvalidMessage(false);

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }

    if (!file) {
      //console.log("No file selected");
      setWaitingToCallApi(false);
      return;
    }



    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // //console.log(jsonData);

      if (jsonData.length === 0 || jsonData[0].length === 0) {
        alert("No data found in the file");
        setInvalidMessage(true);
        return;
      }

      // Define and check required columns

      const actualColumns = jsonData[0];

      const missingColumns = requiredColumns.filter(
        (col) => !actualColumns.includes(col)
      );

      const extraColumns = actualColumns.filter(

        (col) => !requiredColumns.includes(col)

      );

      if (missingColumns.length > 0) {
        //console.log(missingColumns);

        alert("Missing columns: " + missingColumns.join(", "));
        setInvalidMessage(true);

        return;
      }

      if (extraColumns.length > 0) {

      alert("Extra columns found: " + extraColumns.join(", "));
      setInvalidMessage(true);

        return;

      }

      // let isCorrectOrder = true;

      // requiredColumns.forEach((col, index) => {

      //   if (actualColumns[index] !== col) {

      //     isCorrectOrder = false;

      //   }

      // });

      // if (!isCorrectOrder) {

      //   alert('Required columns are not in the correct order');

      //   return;

      // }

      // //console.log('All required columns are present and in the correct order');

      // Create FormData object

      const formData = new FormData();
      const uniqueFileName = `cu_${Date.now()}.xlsx`;

      formData.append("Folder", "newFolder");
      formData.append("FileNameWithExtension", uniqueFileName);
      formData.append("Attachment", file);
      formData.append("RequestDate", formatDate(new Date()));
      formData.append("RequestedBy", userData?.[0]?.EM_ID);
      formData.append("FileStatus", "P");
      formData.append("Remarks", "A");
      formData.append(
        "SignalRConnectionId",
        connectionIdMasterData?.connection?.connectionId
      );
      formData.append("HubName", "masterdataHub");

      const apiUrl = `${clientApiUrl}/Uploadmedia`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // //console.log('Server response:', result);

      if (response?.status === 200) {
        // alert('Successfully Uploaded')
        // debugger
      }
    } catch (error) {
      setWaitingToCallApi(false);
      console.error("Error during processing:", error);
    }
  };

  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#50145A",

            "&.Mui-checked": {
              color: "#50145A",
            },
          },
        },
      },
    },
  });

  const getColorForStatus = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";

      case "inactive":
        return "#B40000";

      case "inprogress":
        return "#EFA029";

      default:
        return "black"; // Default color if the status is none of the above
    }
  };

  const [uploadStatus, setUploadStatus] = useState({});

  const [responseFromUpload, setResponseFromUpload] = useState([]);

  // let count = 0;

  // const fetchUploadStatus = async () => {

  //   try {

  //     const response = await fetch(

  //       `https://fn-hampi-clientsvc-dev.azurewebsites.net/GetFileStatus?RequestBy=${userData?.[0]?.EM_ID}`

  //     );

  //     if (response.ok) {

  //       const data = await response.json();

  //       //console.log(data);

  //       if (data) {

  //         setUploadStatus(data);

  //         if (data?.FileStatus === "I") {

  //           alert("Invalid file upload again.");

  //           clientRejectData();

  //           handleMenuClose();

  //           handleClose();

  //         }

  //         if (data?.FileStatus === "P") {

  //           if (count < 5) {

  //             await new Promise((resolve) => setTimeout(resolve, 1000));

  //             count += 1;

  //             fetchUploadStatus();

  //           }

  //         } else if (data?.FileStatus === "S") {

  //           await fetchResponseFromUpload(data);

  //           setWaitingToCallApi(false);

  //         }

  //       }

  //     } else {

  //       console.error("Failed to fetch");

  //       // setEnd(true);

  //     }

  //   } catch (error) {

  //     ////console.log(error);

  //     // setEnd(true);

  //   }

  // };

  const fetchResponseFromUpload = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetTempData?RequestBy=${userData?.[0]?.EM_ID}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data) {
          setResponseFromUpload(data);

          //console.log(data);
        }
      } else {
        console.error("Failed to fetch");

        // setEnd(true);
      }
    } catch (error) {
      ////console.log(error);
      // setEnd(true);
    } finally {
      setWaitingToCallApi(false);
    }
  };

  // Received message:  File prcoessing started
  // Received message:  File Data is not correct Invalid File Data
  // Received message:  File Data Uploaded

  useEffect(() => {
    //console.log(notificationsForMasterData);
    setInvalidMessage(false);
    if (
      notificationsForMasterData[notificationsForMasterData?.length - 1] ===
      "File prcoessing started"
    ) {
      setWaitingToCallApi(true);
    } else if (
      notificationsForMasterData[notificationsForMasterData?.length - 1] ===
      "File Data Uploaded wait for action"
    ) {
      fetchResponseFromUpload();
    }
    else if (
      notificationsForMasterData?.[notificationsForMasterData?.length - 1]?.startsWith("File Data is not correct Invalid File Data")
    ) {
      setWaitingToCallApi(false);
      clientRejectData();
      // alert('invalid file')
      setErrorMsg(notificationsForMasterData[notificationsForMasterData?.length - 1])
      setInvalidMessage(true);

      // handleClose()
    }
    else if (
      notificationsForMasterData[notificationsForMasterData?.length - 1] ===
      "File header is not correct"
    ) {
      setWaitingToCallApi(false);
      clientRejectData();
      // alert('invalid file')
      setInvalidMessage(true);
      // handleClose()
    }
  }, [notificationsForMasterData]);

  const handleScroll = () => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    if (isSearchOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSearchOpen]);

  const handleScroll1 = () => {
    if (isDepartmentOpen) {
      setIsDepartmentOpen(false);
    }
  };

  useEffect(() => {
    if (isDepartmentOpen) {
      window.addEventListener("scroll", handleScroll1);
    } else {
      window.removeEventListener("scroll", handleScroll1);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll1);
    };
  }, [isDepartmentOpen]);

  const handleScroll2 = () => {
    if (isAccountResponsibleOpen) {
      setIsAccountResponsibleOpen(false);
    }
  };

  useEffect(() => {
    if (isAccountResponsibleOpen) {
      window.addEventListener("scroll", handleScroll2);
    } else {
      window.removeEventListener("scroll", handleScroll2);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, [isAccountResponsibleOpen]);

  return (
    <div className="form-Content1" key={componentKey}>
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />

      <div
        className=""
        style={{
          width: "100%",

          // padding: "24px 24px 24px",
          paddingRight: "24px",
          paddingLeft: "24px",

          display: "flex",

          justifyContent: "center",

          flexDirection: "column",

          alignItems: "center",
        }}
      >
        <div
          className="customer-details-table"
          style={{ width: "100%", maxWidth: "3000px", marginTop: "24px" }}
        >
          {displayEditSection && (
            <>
              <div
                className="customer-details-header-edit"
                onClick={handleAccountMenuClickEdit}
              >
                <span style={{ cursor: "pointer" }}>
                  <CaretLeft
                    color="#3d025f"
                    weight="fill"
                    onClick={handleBackClick}
                  />
                </span>
                Account Responsible
                <FunnelSimple
                  onClick={handleAccountMenuClickEdit}
                  weight="bold"
                  size={20}
                  color="#061347"
                />
              </div>

              <Menu
                anchorEl={accountMenuEditAnchorEl}
                open={Boolean(accountMenuEditAnchorEl)}
                onClose={handleMenuClose}
              >
                <div className="dropdown-search">
                  <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />

                  <input
  type="text"
  className="titel-Search"
  placeholder="Search Customer name"
  value={accSearch}
  onChange={(e) => {
    const value = e.target.value.toLowerCase();
    setAccSearch(value);
  
    setAccountResponsible2(
      accountResponsible.filter((acc) =>
        (acc.EM_FirstName+ ' '+ acc.EM_LastName).toLowerCase().includes(value)
      )
    );
  }}
/>



                </div>

                <ThemeProvider theme={theme}>
  <RadioGroup
    style={{ padding: "12px", color: "#50145A" }}
    value={(updateAccResponsible?.EM_FirstName + ' ' + updateAccResponsible?.EM_LastName).toLowerCase()} // Convert to lowercase here
    onChange={(e) => {
      const selectedAcc = accountResponsible2?.find(
        (acc) =>
          (acc.EM_FirstName + ' ' + acc.EM_LastName).toLowerCase() ==
          e.target.value.toLowerCase()// Ensure comparison is case-insensitive
      );

      setUpdateAccResponsible(selectedAcc);
    }}
  >
    <div
      style={{
        maxHeight: "300px",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {accountResponsible2.map((acc) => {
console.log(acc)
      
       return (acc?.EM_ID&&<FormControlLabel
          key={acc.EM_FirstName + ' ' + acc.EM_LastName}
          value={(acc.EM_FirstName + ' ' + acc.EM_LastName).toLowerCase()} // Convert to lowercase here
          control={<Radio />}
          label={acc.EM_FirstName + ' ' + acc.EM_LastName}
        />)
})}
    </div>

    {!accChangeLoading ? (
      <button
        className="flex items-center justify-center mx-auto text-center text-white rounded-lg font-poppins w-44 h-9 Color-submit"
        onClick={updateAccountResponsible}
      >
        Submit
      </button>
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          width={20}
          style={{
            color: "purple",
            display: "flex",
            alignItems: "center",
          }}
        />
      </div>
    )}
  </RadioGroup>
</ThemeProvider>

              </Menu>
            </>
          )}

          <div className="customer-details-header">
            <div className="header_title">
              Customer Name
              <FunnelSimple
                className="icon-search-dropdown"
                onClick={handleCustomersearchClick}
                weight="bold"
                size={20}
                color="#061347"
              />
              {isSearchOpen && (
                <div ref={dropdownsearchRef} className="search-drop-design">
                  <div className="search-drop-page">
                    <div className="main-suggestion">
                      <div className="input-suggestion">
                        <div className="icon-search-phospher">
                          <MagnifyingGlass
                            size={20}
                            color="#ada4a4"
                            weight="fill"
                          />{" "}
                        </div>

                        <input
                          type="text"
                          value={query}
                          className="input-suggestion-search"
                          onChange={(e) => setQuery(e.target.value)}
                          placeholder="Start typing..."
                        />

                        <button
                          className="clear-title"
                          onClick={handleClearAll}
                        >
                          Clear All
                        </button>
                      </div>
                      {loading ? (
                        <p className="suggestion-title">
                          <CircularProgress
                            width={30}
                            style={{ color: "purple" }}
                          />
                        </p>
                      ) : (
                        <div style={{ maxHeight: "500px" }} tabIndex={1}>
                          {suggestions.map((suggestion) => (
                            <div
                              key={suggestion.Id}
                              className="suggestion-list-li"
                            >
                              <input
                                type="checkbox"
                                className="check-searchbox"
                                checked={selectedSuggestions.some(
                                  (item) => item.Id === suggestion.Id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(suggestion)
                                }
                              />

                              <label className="li-content">
                                {suggestion.Name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* <div>

                        <h3 className="suggestion-title-2">

                          Selected Suggestions :

                        </h3>

                        <ul>

                          {selectedSuggestions.map((suggestion) => (

                            <li className="suggestion-list" key={suggestion.Id}>

                              {suggestion.Name}

                            </li>

                          ))}

                        </ul>

                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="header_title" style={{ paddingLeft: "1%" }}>
              Project Code
            </div>

            <div className="header_title" style={{ paddingLeft: "5%" }}>
              Department
              <FunnelSimple
                className="icon-search-dropdown"
                onClick={handleDepartmentMenuClick}
                weight="bold"
                size={20}
                color="#061347"
              />
              {isDepartmentOpen && (
                <div ref={dropdownsearchRef} className="search-drop-design">
                  <div className="search-drop-page">
                    {/* <SuggestionComponent /> */}

                    <div className="main-suggestion">
                      <div className="input-suggestion">
                        <div className="icon-search-phospher">
                          <MagnifyingGlass
                            size={20}
                            color="#ada4a4"
                            weight="fill"
                          />{" "}
                        </div>

                        {/* <MagnifyingGlass size={20} className='icon-search-phospher' color="#ada4a4" weight="fill" />  */}

                        <input
                          type="text"
                          value={deptSearch}
                          className="input-suggestion-search"
                          onChange={(e) => {
                            const value = e.target.value.toLowerCase();

                            setDeptSearch(value);

                            setDepartment2(
                              department.filter((dep) =>
                                dep.name.toLowerCase().includes(value)
                              )
                            );
                          }}
                          placeholder="Start typing..."
                        />

                        <button
                          className="clear-title"
                          onClick={handleClearAllDept}
                        >
                          Clear All
                        </button>
                      </div>

                      {/* <button style={{ display: 'inline' }} onClick={handleClearAll}>Clear All</button> */}

                      {
                        <div
                          // style={{ overflow: "scroll", maxHeight: "300px" }}
                          style={{ maxHeight: "300px" }}
                          tabIndex={1}
                        >
                          {department2
                            ?.filter(
                              (dep, index, self) =>
                                index === self.findIndex((d) => d.name === dep.name) // Remove duplicates by name
                            )
                            .map((dep) => {
                              return (
                                <div key={dep.id} className="suggestion-list-li">
                                  <input
                                    type="checkbox"
                                    className="check-searchbox"
                                    checked={selectedDepartments.some((item) => item.name === dep.name)}
                                    onChange={() => handleCheckboxDepartmentChange(dep)}
                                  />

                                  <label className="li-content">{dep.name}</label>
                                </div>
                              );
                            })}

                        </div>
                      }

                      {/* <div>

                        <h3 className="suggestion-title-2">

                          Selected Suggestions :

                        </h3>

                        <ul>

                          {selectedDepartments.map((dep) => (

                            <li className="suggestion-list" key={dep.id}>

                              {dep.name}

                            </li>

                          ))}

                        </ul>

                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="header_title" style={{ paddingLeft: "3%" }}>
              Account Responsible
              <FunnelSimple
                className="icon-search-dropdown"
                onClick={handleAccountingResponsibleClick}
                weight="bold"
                size={20}
                color="#061347"
              />
              {isAccountResponsibleOpen && (
                <div ref={dropdownsearchRef} className="search-drop-design">
                  <div className="search-drop-page">
                    {/* <SuggestionComponent /> */}

                    <div className="main-suggestion">
                      <div className="input-suggestion">
                        <div className="icon-search-phospher">
                          <MagnifyingGlass
                            size={20}
                            color="#ada4a4"
                            weight="fill"
                          />{" "}
                        </div>

                        {/* <MagnifyingGlass size={20} className='icon-search-phospher' color="#ada4a4" weight="fill" />  */}

                        <input
                          type="text"
                          value={accSearch}
                          className="input-suggestion-search"
                          onChange={(e) => {
                            const value = e.target.value.toLowerCase();
                            console.log(value)
                            setAccSearch(value);

                            setAccountResponsible2(
                              accountResponsible.filter((acc) =>
                                (acc.EM_FirstName+ ' '+ acc.EM_LastName).toLowerCase().includes(value)
                              )
                            );
                          }}
                          placeholder="Start typing..."
                        />

                        <button
                          className="clear-title"
                          onClick={handleClearAllAcc}
                        >
                          Clear All
                        </button>
                      </div>

                      {/* <button style={{ display: 'inline' }} onClick={handleClearAll}>Clear All</button> */}

                      {
                        // loading ? (

                        //   <p className='suggestion-title'>Loading...</p>

                        // ) :

                        <div style={{ maxHeight: "500px" }} tabIndex={1}>
                          {accountResponsible2.map((acc) => (
                            <div key={acc.EM_ID} className="suggestion-list-li">
                              <input
                                type="checkbox"
                                className="check-searchbox"
                                checked={selectedAccountResponsible.some(
                                  (item) =>
                                    (item.EM_FirstName+' '+item.EM_LastName) === (acc.EM_FirstName+' '+acc.EM_LastName)
                                )}
                                onChange={() =>
                                  handleCheckboxAccountResponsibleChange(acc)
                                }
                              />

                              <label className="li-content">
                                {acc.EM_FirstName+' '+acc.EM_LastName}
                              </label>
                            </div>
                          ))}
                        </div>
                      }

                      {/* <div>

                        <h3 className="suggestion-title-2">

                          Selected Suggestions :

                        </h3>

                        <ul>

                          {selectedAccountResponsible.map((acc) => (

                            <li className="suggestion-list" key={acc.EM_ID}>

                              {acc.EM_FirstName}

                            </li>

                          ))}

                        </ul>

                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="header_title">Out Sourcing Sub Processor</div>

            <div className="header_title" style={{ paddingRight: "2%" }}>
              Status
            </div>

            <div className="header_title" onClick={handleMenuClick}>
              <span>
                <DotsThreeOutlineVertical
                  size={20}
                  color="#061347"
                  weight="fill"
                />
              </span>
            </div>
          </div>

          <div className="menu_items">
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className="custom-menu"
            >
              <MenuItem onClick={handleEditClick}>
                <PencilSimpleLine
                  weight="fill"
                  color="#50145A"
                  size={18}
                  style={{ marginRight: "8px" }}
                />
                Edit
              </MenuItem>

              <MenuItem>
                <label
                  style={{
                    display: "flex",

                    alignItems: "center",

                    cursor: "pointer",

                    width: "100%",

                    height: "100%",

                    color: "#000",

                    fontSize: "16px",
                  }}
                >
                  <Download
                    weight="fill"
                    color="#50145A"
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  Import Files
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
              </MenuItem>

              <MenuItem onClick={handleDownload}>
                {" "}
                <Export
                  weight="fill"
                  color="#50145A"
                  size={18}
                  style={{ marginRight: "8px" }}
                // onClick={handleDownload}
                />
                Export Files{" "}
              </MenuItem>
              <MenuItem onClick={handleDownloadSample}>
                {" "}
                <Export
                  weight="fill"
                  color="#50145A"
                  size={18}
                  style={{ marginRight: "8px" }}
                // onClick={handleDownload}
                />
                Download Sample{" "}
              </MenuItem>

              <MenuItem onClick={handleAddNewCustomer}>
                <PlusCircle
                  weight="fill"
                  color="#50145A"
                  size={18}
                  style={{ marginRight: "8px" }}
                />
                Add New Customer
              </MenuItem>
            </Menu>
          </div>

          <Menu
            anchorEl={accountMenuAnchorEl}
            open={Boolean(accountMenuAnchorEl)}
            onClose={handleMenuClose}
          >
            <div className="dropdown-search">
              <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />

              <input
                type="text"
                className="titel-Search"
                placeholder="Search Customer name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

          
          </Menu>



          <Menu
            anchorEl={departmentMenuAnchorEl}
            open={Boolean(departmentMenuAnchorEl)}
            onClose={handleMenuClose}
          >
            <div className="dropdown-search">
              <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />

              <input
                type="text"
                className="titel-Search"
                placeholder="Search Customer name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <MenuItem onClick={handleMenuClose}>
              <Checkbox /> All
            </MenuItem>

            <MenuItem onClick={handleMenuClose}>
              <Checkbox /> Department
            </MenuItem>
          </Menu>

          <div className="customer-details-body">
            {displayEditSection && (
              <div style={{ background: "none", marginTop: "10px" }}>
                <input
                  type="checkbox"
                  className="check-searchbox-select"
                  style={{ marginLeft: "10px" }}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setCheckedMasterDataForEdit(selectedMasterData);
                    } else {
                      setCheckedMasterDataForEdit([]);
                    }
                  }}
                />

                <span style={{ margin: "26px", fontFamily: "Poppins" }}>
                  {" "}
                  Select All
                </span>
              </div>
            )}
            {selectedMasterData?.map((employee, index) => (
              <>
                <div
                  className={`Content-main1 line2 ${employee.active ? "active" : "inactive"
                    }`}
                  key={index}
                  style={{ background: index % 2 === 0 ? "none" : "white" }}
                >
                  {displayEditSection && (
                    <input
                      type="checkbox"
                      className="check-searchbox"
                      style={{ marginLeft: "10px" }}
                      checked={checkedMasterDataForEdit.some(
                        (emp) => emp.Id === employee.Id
                      )}
                      onChange={() => handleCheckboxMasterEditChange(employee)}
                    />
                  )}

                  <div
                    className="Content-main1"
                    style={{ background: index % 2 === 0 ? "none" : "white" }}
                  >
                    <div
                      className="Content-m11"
                      onClick={() => handleEdit(employee.Id)}
                    >
                      <div className="Content-m21">
                        <div className="Content-master">{employee.Name}</div>
                      </div>

                      <div className="Content-m21">
                        <div className="Content-master">
                          {employee.ProjectNumber}
                        </div>
                      </div>

                      <div className="Content-m21">
                        <div className="Content-master">
                          {employee.Department}
                        </div>
                      </div>

                      <div className="Content-m21">
                        <div className="Content-master">
                          {
                            usersList?.find((e) => e.EM_ID == employee.SubOwner)
                              ?.EM_FirstName +' '+ usersList?.find((e) => e.EM_ID == employee.SubOwner)
                              ?.EM_LastName
                          }
                        </div>
                      </div>

                      <div className="Content-m21">
                        <div className="Content-master">
                          {employee.OutsourcingSubprocessor}
                        </div>
                      </div>

                      <div
                        className="Content-m21"
                        style={{
                          minWidth: "90px",

                          display: "flex",

                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            color: getColorForStatus(employee.Status),

                            textTransform: "uppercase",

                            textAlign: "left",

                            minWidth: "98px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            // width: "20%",
                            width: "100px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {employee.Status}
                        </div>
                      </div>

                      <div className="Content-m21">
                        <div
                          style={{
                            minWidth: "20px",

                            display: "flex",

                            textAlign: "left",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {(!end || loading) &&  (
          <div ref={loader} tabIndex={1}>
            <div style={{ width: "100%", margin: "24rem auto" }}>
              <CircularProgress width={30} style={{ color: "purple" }} />
            </div>
          </div>
        )}

        {end && (
          <div style={{ margin: "20px auto", fontFamily: "Poppins" }}>
            End Of Results
          </div>
        )}
      </div>

      {/* <SuggestionComponent style={{alignSelf:'flex-start'}}/> */}

      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                fontSize: "24px",
                color: " rgba(80, 20, 90, 1)",
                fontWeight: "700",
              }}
            >
              Importing File{" "}
              <span
                style={{ color: "black", fontSize: "16px", fontWeight: "400" }}
              >
                ({uploadStatus?.FileName})
              </span>
            </div>

            {waitingToCallApi && !invalidMessage && (
              <>
                <div
                  style={{
                    width: "100%",
                    margin: "2rem auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{ color: "purple" }} />
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "2rem auto",
                    textAlign: "center",
                    fontSize: "32px",
                  }}
                >
                  Processing Data. Please do not exit.
                </div>
              </>
            )}

            {invalidMessage && !errorMsg && (
              <div
                style={{
                  width: "100%",
                  margin: "2rem auto",
                  textAlign: "center",
                  fontSize: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                The Uploaded file is invalid . Please check headers and Re-Upload.
                <button className="btn" onClick={handleClose}>
                  Okay
                </button>
              </div>
            )}
            {errorMsg && <div
              style={{
                width: "100%",
                margin: "2rem auto",
                textAlign: "center",
                fontSize: "32px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {errorMsg}
              <button className="btn" onClick={handleClose}>
                Okay
              </button>
            </div>}

            {!waitingToCallApi && !errorMsg && !invalidMessage && (
              <>
                <div
                  style={{
                    display: "flex",
                    background: "white",
                    width: "100%",
                    borderRadius: "10px",
                    // minHeight: "50px",
                    margin: "1rem auto",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "30px",
                  }}
                >
                  {responseFromUpload
                    ?.filter((i) => i?.TCM_DataStaus !== "ERROR")
                    ?.map((item, index) => (
                      <div key={index} style={{ marginLeft: "24px" }}>
                        <span
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "20px",
                          }}
                        >
                          {item?.TCM_DataStaus}
                        </span>{" "}
                        :{" "}
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          ({item?.Count} files)
                        </span>
                      </div>
                    ))}
                </div>

                {responseFromUpload?.some(
                  (i) => i?.TCM_DataStaus === "ERROR"
                ) && (
                    <div
                      style={{
                        background: "white",
                        width: "100%",
                        borderRadius: "10px",
                        maxHeight: "500px",
                        overflow: "scroll",
                      }}
                    >
                      <div
                        style={{
                          padding: "5%",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        Invalid Files
                      </div>

                      {responseFromUpload
                        ?.filter((i) => i?.TCM_DataStaus === "ERROR")
                        ?.map((item, index) => {
                          // Get the invalid fields (excluding the specified ones)
                          const invalidFields = Object.keys(item)
                            .filter(
                              (key) =>
                                item[key] !== null &&
                                item[key] !== undefined &&
                                !item[key]
                            )
                            .map((key) => key.replace("TCM_", ""));

                          return (
                            <Accordion
                              key={index}
                              style={{
                                width: "90%",
                                margin: "0 auto",
                                borderBottom: "0.2px solid gray",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                              >
                                <div style={{ color: "gray", fontSize: "20px" }}>
                                  {item?.TCM_ProjectNumber} {item?.TCM_CustomerName}
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                {invalidFields.length > 0 ? (
                                  <>
                                    <div>
                                      <strong>These fields are invalid:</strong>
                                    </div>
                                    <ul>
                                    {invalidFields.filter(i => i !== "TerminationDate" && i !== "AccountingInfoupdatedDate" && i !== "Outsourcingenddate")
?.map((field, fieldIndex) => (
  <li key={fieldIndex}>{field}</li>
))}
                                    </ul>
                                    {invalidFields.includes("OutsourcingSubprocessor") && (
  <p>Note: If OutSourcing SubProcessor is not Synergy, some of the fields shown here are not mandatory.</p>
)}                                  </>
                                ) : (
                                  <div>No invalid fields found.</div>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}

                    </div>
                  )}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "24px",
                    gap: "40px",
                    justifyContent: "flex-end",
                  }}
                >
                  {responseFromUpload && !invalidMessage && (
                    <>
                      <button
                        className="btn2"
                        onClick={async () => {
                          await clientRejectData();
                          handleClose();
                        }}
                        style={{ background: "white", padding: "8px" }}
                      >
                        Reject
                      </button>

                      {!responseFromUpload.some(
                        (i) => i?.TCM_DataStaus === "ERROR"
                      ) && !approveLoading && (
                          <button
                            className="btn"
                            onClick={async () => {
                             await clientApproveData();
                            
                              handleClose();
                            }}
                          >
                            Approve
                          </button>
                        )}
                      {!responseFromUpload.some(
                        (i) => i?.TCM_DataStaus === "ERROR"
                      ) && approveLoading && (
                          <CircularProgress width={40}
                            style={{ color: "purple" }} />
                        )}
                    </>
                  )}
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default CustomerDetail;
