import { Eye, PencilSimple, ShareNetwork } from 'phosphor-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Assuming React Router
import { reportApiUrl } from '../utils/GetUrl';
import { AuthContext } from '../context/AuthContext';
import { CircularProgress } from '@mui/material';
import ToastComponent from './ToastComponent';

function PublicReports() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const toastRef = useRef();
  const [filteredReport, setFilteredReport] = useState(null); // State for the selected report
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchReports = async () => {
      if (!userData?.[0]?.EM_ID) return;
      setLoading(true); 
      try {
        const response = await fetch(`${reportApiUrl}/GetAllCustomiseReport`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast("Unauthorised access. Please login again.","error");
          await logout();
          return;
        }
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          toastRef.current.showToast("Your session has expired", "error");
          logout();
          return false;
        }
        if (response.ok) {
          const data = await response.json();
          const publicReports = data.filter(report => report.ReportType === "public");
          setReportData(publicReports);
        } else {
          console.error('Failed to fetch reports');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      finally {
        setLoading(false); 
      }
    };
    
    fetchReports();
  }, [userData, token, logout, handleAuthExpiry]);

  const handleShare = (CustomiseReportId) => {
    if (!CustomiseReportId) {
      console.error("Invalid CustomiseReportId:", CustomiseReportId);
      toastRef.current?.showToast("Unable to share: Invalid report ID.", "error");
      return;
    }
  
    const shareableUrl = `${window.location.origin}${location.pathname}?id=${CustomiseReportId}`;
    console.log("Shareable URL:", shareableUrl);
  
    navigator.clipboard.writeText(shareableUrl)
      .then(() => {
        toastRef.current?.showToast("Link copied to clipboard!", "success");
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
      });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");  
    if (id && reportData.length > 0) {
      const matchedReport = reportData.find(report => {
          return report.CustomiseReportId === parseInt(id, 10);
      });
        setFilteredReport(matchedReport || null);
    } else {
      setFilteredReport(null); 
    }
  }, [location.search, reportData]);
  
  const handleRowClick = (CustomiseReportId) => {
    navigate(`/PublicReportDetails/${CustomiseReportId}`);
  };
  

  return (
    <div>
    <ToastComponent ref={toastRef} timeout={4000} />
    <div className="lookup_activity_contents"   style={{ maxHeight: "700px", overflowY: "auto" }}
    >
    <div 
    className="table_head_custom" 
    style={{
      position: "sticky",
      top: "0",
      backgroundColor: "#fff",
      zIndex: 10,
      display: "flex",
      padding: "10px 0",
      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)" 
    }}
  >
        <div className="lookup_activity_name" style={{ marginRight: "40px" }}>List of Reports</div>
        <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Description</div>
        <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Created By</div>
        <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Created On</div>
        <div className="lookup_activity_name" style={{ marginRight: "60px" }}></div>
      </div>
      {loading ? (
  <div className="loading-indicator">
    <CircularProgress sx={{ color: '#50145A' }} />
  </div>
) : filteredReport ? (
  <div className="table_row_custom row_gray">
    <div className="custom_activity_data">{filteredReport.ReportName}</div>
    <div className="custom_activity_data">{filteredReport.ReportDescription}</div>
    <div className="custom_activity_data">{filteredReport.CreatedBy}</div>
    <div className="custom_activity_data">{new Date(filteredReport.CreatedDate).toLocaleDateString('en-GB')}</div>
  </div>
) : (
  reportData.map((row, index) => (
    <div key={index} className={`table_row_custom ${index % 2 === 0 ? 'row_gray' : 'row_white'}`}>
      <div className="custom_activity_data">{row.ReportName}</div>
      <div className="custom_activity_data">{row.ReportDescription}</div>
      <div className="custom_activity_data">{row.CreatedBy}</div>
      <div className="custom_activity_data">{new Date(row.CreatedDate).toLocaleDateString('en-GB')}</div>
      <div className="custom_activity_data_last">
        <ShareNetwork
          weight="fill"
          size={16}
          style={{ cursor: 'pointer' }}
          onClick={() => handleShare(row.CustomiseReportId)} 
        />
                        <Eye weight="fill" size={16} onClick={() => handleRowClick(row.CustomiseReportId)}  style={{ cursor: 'pointer' }}/>

      </div>
    </div>
  ))
)}

    </div>
  </div>
  );
}

export default PublicReports;
