import React, { useContext, useEffect, useRef, useState } from 'react';
import { FunnelSimple, PencilSimple, ShareNetwork, Spinner, Trash } from 'phosphor-react';
import { reportApiUrl } from '../utils/GetUrl';
import { AuthContext } from '../context/AuthContext';
import ToastComponent from './ToastComponent';
import { CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

function PrivateReports() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const toastRef = useRef();
  const navigate = useNavigate();
  const [filteredReport, setFilteredReport] = useState(null); // State for the selected report
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);
  const location = useLocation();
  const [deleteLoading, setDeleteLoading] = useState(false); // Add a loading state

 
  const fetchReports = async () => {
    if (!userData?.[0]?.EM_ID) return;
    setLoading(true); 
    try {
      // Concatenating first and last name to form the CreatedBy parameter
      const createdBy = `${userData?.[0]?.EM_FirstName} ${userData?.[0]?.EM_LastName}`;
      
      const response = await fetch(`${reportApiUrl}/GetAllCustomiseReport?CreatedBy=${encodeURIComponent(createdBy)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your session has expired", "error");
        logout();
        return false;
      }
  
      if (response.ok) {
        const data = await response.json();
        setReportData(data);
      } else {
        console.error('Failed to fetch reports');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
    }
  };
  
  useEffect(() => {
    fetchReports();
  }, [userData, token, logout, handleAuthExpiry]);
  

  const handleRowClick = (CustomiseReportId) => {
    navigate(`/PrivateReportDetails/${CustomiseReportId}`);
  };

  const handleShare = (CustomiseReportId) => {
    if (!CustomiseReportId) {
      console.error("Invalid CustomiseReportId:", CustomiseReportId);
      toastRef.current?.showToast("Unable to share: Invalid report ID.", "error");
      return;
    }

    const shareableUrl = `${window.location.origin}${location.pathname}?id=${CustomiseReportId}`;
    console.log("Shareable URL:", shareableUrl);

    navigator.clipboard.writeText(shareableUrl)
      .then(() => {
        toastRef.current?.showToast("Link copied to clipboard!", "success");
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    console.log("URL Parameters:", location.search);

    if (id && reportData.length > 0) {
      const matchedReport = reportData.find(report => {
        console.log("Report ID:", report.CustomiseReportId, "Queried ID:", id);
        return report.CustomiseReportId === parseInt(id, 10);
      });
      setFilteredReport(matchedReport || null);
    } else {
      setFilteredReport(null); 
    }
  }, [location.search, reportData]);
  const handleDelete = async (CustomiseReportId) => {
    if (!Number.isInteger(CustomiseReportId)) {
      console.error("Invalid CustomiseReportId:", CustomiseReportId);
      toastRef.current?.showToast("Invalid report ID. Please try again.", "error");
      return;
    }
  
    setDeleteLoading((prev) => ({ ...prev, [CustomiseReportId]: true }));
    try {
      const response = await fetch(
        `${reportApiUrl}/DeleteCustomiseReport?customisereportid=${CustomiseReportId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 401) {
        toastRef.current?.showToast("Unauthorized access. Please log in again.", "error");
        await logout();
        return;
      }
  
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current?.showToast("Your session has expired.", "error");
        logout();
        return;
      }
  
      if (response.ok) {
        toastRef.current?.showToast("Report deleted successfully.", "success");
        setReportData((prevReports) =>
          prevReports.filter((report) => report.CustomiseReportId !== CustomiseReportId)
        );
        fetchReports();
      } else {
        console.error("Failed to delete report:", response.statusText);
        toastRef.current?.showToast("Failed to delete report.", "error");
      }
    } catch (error) {
      console.error("Error deleting report:", error);
      toastRef.current?.showToast("An error occurred while deleting the report.", "error");
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [CustomiseReportId]: false }));
    }
  };
  
  
  
  
  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="lookup_activity_contents"   style={{ maxHeight: "700px", overflowY: "auto" }}
      >
      <div 
    className="table_head_custom" 
    style={{
      position: "sticky",
      top: "0",
      backgroundColor: "#fff",
      zIndex: 10,
      display: "flex",
      padding: "10px 0",
      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)" 
    }}
  >
          <div className="lookup_activity_name" style={{ marginRight: "40px" }}>List of Reports</div>
          <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Description</div>
          <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Created By</div>
          <div className="lookup_activity_name" style={{ marginRight: "60px" }}>Created On</div>
          <div className="lookup_activity_name" style={{ marginRight: "60px" }}></div>
        </div>
        {loading ? (
          <div className="loading-indicator">
            <CircularProgress sx={{ color: '#50145A' }} />
          </div>
        ) : filteredReport ? (
          <div className="table_row_custom row_gray">
            <div className="custom_activity_data">{filteredReport.ReportName}</div>
            <div className="custom_activity_data">{filteredReport.ReportDescription}</div>
            <div className="custom_activity_data">{filteredReport.CreatedBy}</div>
            <div className="custom_activity_data">{new Date(filteredReport.CreatedDate).toLocaleDateString('en-GB')}</div>
            <div className="custom_activity_data_last">
              <ShareNetwork
                weight="fill"
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => handleShare(filteredReport.CustomiseReportId)} 
              />
            </div>
          </div>
        ) : (
          reportData.map((row, index) => (
            <div key={index} className={`table_row_custom ${index % 2 === 0 ? 'row_gray' : 'row_white'}`}>
              <div className="custom_activity_data">{row.ReportName}</div>
              <div className="custom_activity_data">{row.ReportDescription}</div>
              <div className="custom_activity_data">{row.CreatedBy}</div>
              <div className="custom_activity_data">{new Date(row.CreatedDate).toLocaleDateString('en-GB')}</div>
              <div className="custom_activity_data_last">
                <ShareNetwork
                  weight="fill"
                  size={16}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleShare(row.CustomiseReportId)} 
                />
                {/* <Trash weight="fill" size={16}  style={{ cursor: 'pointer' }}   onClick={() => handleDelete(row.CustomiseReportId)}/> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
      {deleteLoading[row.CustomiseReportId] ? (
        <CircularProgress size={16} sx={{ color: '#50145A' }}  />
      ) : (
        <Trash
          weight="fill"
          size={16}
          style={{ cursor: 'pointer', color: 'black' }}
          onClick={() => handleDelete(row.CustomiseReportId)}
        />
      )}
    </div>


                <PencilSimple weight="fill" size={16} onClick={() => handleRowClick(row.CustomiseReportId)}  style={{ cursor: 'pointer' }}/>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default PrivateReports;
