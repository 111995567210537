import React, { useContext, useEffect, useRef, useState } from "react";
import NavSub from "../components/NavSub";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { CircularProgress, MenuItem } from "@mui/material";
import { MagnifyingGlass, CaretDown } from "phosphor-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Paper from "@mui/material/Paper";
import ToastComponent from "../components/ToastComponent";
import TableContainer from "@mui/material/TableContainer";
import "../Styles/NewreportingActivities.css";
import "../Styles/ReportinActivitiesC1.css";
import "../Styles/QualityControl.css";
import  isGuestUser  from '../utils/guestUser';
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function Reporting() {
  const [selectedMasterData, setSelectedMasterData] = useState([]);
  const { token, userData, logout } = useContext(AuthContext);
  const { usersList } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const [frequency, setFrequency] = useState([]);

  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [selectedActivities, setSelectedActivities] = useState([]);
  const [reportingActivities, setReportingActivities] = useState([]);
  const [checkedRows, setCheckedRows] = useState(new Set());
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toISOString().slice(0, 7)
  );
  const [isAbleToEdit, setIsAbleToEdit] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const debounceTimeout = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [currentAccResponsible, setCurrentResponsible] = useState(null);

  const toastRef = useRef();
  async function fetchData() {
    try {
      if(!token){
        return;
      }
      const response = await fetch(
       
        `${clientApiUrl}/GetAllClient?id=${selectedClient?.Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      //console.log("Fetched Data for subdata:", data);

      setSelectedMasterData(data);
      const isSynergyScope = data?.[0]?.OutsourcingSubprocessor === "Synergy";
      //console.log(isSynergyScope);
        try {
          // Make API call to fetch additional data
          // const response = await ;
          const additionalData = await fetchAdhocData();
          //console.log(additionalData);
          // Filter and map the additional data

          const formattedAdditionalData = additionalData
            .filter((item) => item.AL_Type === "Adhoc" && item.AL_Status == "1")
            .map((item) => ({
              SATId: parseInt(item.AL_ID),
              SATActivity: parseInt(item.AL_ID),
              SATClient: 0,
              SATProject: 0,
              SATTime: 0,
              ActivityName: item.AL_Name,
              SalseforceCode: item.AL_SalseforceCode,
              ActivityType: item.AL_Type,
            }));
          // Append the formatted additional data to selectedActivities
          setSelectedActivities([
            ...data?.[0]?.standardActivitiesTime?.filter(
              (i) => i.ActivityType === "Reporting Activites"
            ),
            ...formattedAdditionalData,
          ]);
          getAllReportingTasks(
            data?.[0]?.Id,
            [
              ...data?.[0]?.standardActivitiesTime?.filter(
                (i) => i.ActivityType === "Reporting Activites"
              ),
              ...formattedAdditionalData,
            ],
            selectedMonth
          );
        } catch (error) {
          console.error(error);
        }
     
    } catch (error) {
      //console.log(error);
    }
  }
  const handleCaretDownClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  const fetchAdhocData = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);

        return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchFrequencies = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllProjectList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setFrequency(data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = new Set(prevCheckedRows);
      if (newCheckedRows.has(id)) {
        newCheckedRows.delete(id);
      } else {
        newCheckedRows.add(id);
      }
      return newCheckedRows;
    });
  };

  useEffect(() => {
    if (selectedClient?.Id !== null) {
      fetchData();
    }
  }, [selectedClient]);

  const fetchSuggestionByText = async () => {
    if(!token){
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        toastRef.current.showToast("Something went wrong.", "error");
        setLoading(false);
        return;
      }
      const data = await response.json();
      //console.log("Suggestions Data for integration", data);
      setSuggestions(data?.filter(c=>c?.Status!=="Inactive"));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);
      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);
      setLoading(false);
    }
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const handleSuggestionClick = (suggestion) => {
    setSelectedClient(suggestion);
    setSuggestions([]);
    setShowSearch(false); // Hide search box after selection
  };
  const fetchActivityListForAuthCheck = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllActivity`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  useEffect(()=>{
    // fetchActivityListForAuthCheck();
    fetchFrequencies()
  },[token])

  const getAllReportingTasks = async (id, masterData, yearMonth) => {
    try {
      // Use yearMonth if available, otherwise fall back to selectedMonth
      const monthYear = yearMonth || selectedMonth;
      //console.log(monthYear);
      const year = monthYear.split("-")[0];
      const month = monthYear.split("-")[1];
      //console.log(year, month);
      // setSelectedActivities(selectedMasterData?.[0]?.standardActivitiesTime?.filter(i => i.ActivityType === "Reporting Activites"));

      // return;

      if (!monthYear) {
        throw new Error("Month and year information is missing.");
      }

      // const [year, month] = monthYear.split("-");

      if (!year || !month) {
        throw new Error(
          "Invalid monthYear format. Expected format is 'YYYY-MM'."
        );
      }

      const response = await fetch(
        `${taskApiUrl}/GetAllTask?inputMonth=${month}&inputYear=${year}&clientId=${id}&ActivityType=Reporting Activities`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();
      //console.log(data);
      setReportingActivities(data);
      mergeActivities(data, masterData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateCheckedRows = async () => {

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    //console.log(checkedRows);
    if (!checkedRows.size) {
      return;
    }

   

    for (const id of checkedRows) {
      console.log(id);
     
      const activity = selectedActivities.find((act) => act.SATId === id);
      if (activity) {

        console.log(activity);
        if(parseFloat(activity?.AjdTime) && !activity?.ExtraTimeRemarks){
          toastRef.current.showToast("Add Reason for Extra Time", "warning");
          return false;
        }
        // console.log(activity);
        try {
          const payload = {
            ...activity,
            Status: "C",
            AjdTime:parseFloat(activity.AjdTime)||0,
            TotalTime: (parseFloat(activity.AjdTime)+parseFloat(activity.StdTime))||0,
            ExtraTimeRemarks: activity.ExtraTimeRemarks,
            ApprovalIteration: "1",
            ReviewCount:1,
            CreatedBy: userData?.[0]?.EM_ID,
            DepartmentId: selectedMasterData?.[0]?.Department,
            ClientName: selectedMasterData?.[0]?.Name,
            SATActivity: undefined,
            SATClient: undefined,
            SATId: undefined,
            SATProject: undefined,
            SATTime: undefined,
            SalseforceCode: undefined,
            ActivityType: undefined,
          };

          const response = await fetch(`${taskApiUrl}/UpdateTask`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
          if (response.status === 401) {
            toastRef.current.showToast(
              "Unauthorised access. Please login again.",
              "error"
            );
            await logout();
            return;
          }

          if (!response.ok) {
            throw new Error("Failed to update activity");
          }

          const updatedData = await response.json();
          if (updatedData?.Status === false) {
            if (updatedData?.Message === "Activity Period Close") {
              toastRef.current.showToast(
                "Period is closed. Please check the data you have updated.",
                "error"
              );
            } else {
              toastRef.current.showToast("Something went wrong.", "error");
            }
            return false;
          }
    
          if (updatedData?.Status === true) {
            if (updatedData?.Result === true) {
              toastRef.current.showToast("Task updated successfully.", "success");
              // fetchRelatedVouchersOnDate();
              // fetchData();
              // notifyAssignedUser(updatedActivity?.SalseforceCode, value);
            } else if (updatedData?.Result === false) {
              toastRef.current.showToast(
                "Please check the data you have updated.",
                "warning"
              );
              return false;
            }
          } else {
            toastRef.current.showToast("Something went wrong.", "error");
          }
          //console.log("Updated Data:", updatedData);
        } catch (error) {
          console.error("Error updating activity:", error);
        }
      }
    }

    // Refresh the data after updating
    // getAllReportingTasks(selectedMasterData?.[0]?.Id, selectedMasterData?.[0]?.standardActivitiesTime?.filter(i => i.ActivityType === "Reporting Activites"),selectedMonth);
    fetchData();
    setCheckedRows(new Set());
  };

  const mergeActivities = (rep, sel) => {
    const mergedActivities = sel?.map((activity) => {
      //console.log(activity);
      //console.log(sel);
      const matchingReportingActivity = rep?.find(
        (ra) => ra.ActivityListId == activity.SATActivity
      );
      //console.log(matchingReportingActivity);
      return matchingReportingActivity
        ? { ...activity, ...matchingReportingActivity }
        : activity;
    });
    //console.log(mergedActivities);
    setSelectedActivities(mergedActivities);
  };

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleChange = async (act, field, value) => {
    console.log(field);
    const updatedActivity = { ...act, [field]: value };

    setSelectedActivities((prevState) =>
      prevState.map((item) => {
        if (item.SATId === act.SATId) {
          const newTotalTime = parseFloat(value) + parseFloat(act.SATTime);

          return { ...item, [field]: value, TotalTime: newTotalTime };
        }
        return item;
      })
    );


    const existingActivity = reportingActivities.find(
      (item) => item.ActivityListId?.toString() == act.SATActivity?.toString()
    );

    const updatedAct = { ...existingActivity, [field]: value };

    if (field === "AjdTime" || field === "ExtraTimeRemarks") {
      if (field === "AjdTime") {
        setSelectedActivities((prevState) =>
          prevState.map((item) =>
            item.SATId === act.SATId
              ? {
                  ...item,
                  TotalTime: (parseFloat(value) + parseFloat(act.SATTime))||0,
                }
              : item
          )
        );
      }
      return;
    }

    const isActivityPresent = !!existingActivity;
    const endpoint = isActivityPresent ? "UpdateTask" : "AddTask";
    const url = `${taskApiUrl}/${endpoint}`;
    const method = isActivityPresent ? "PUT" : "POST";

    const payload = {
      Id: isActivityPresent ? existingActivity.Id : undefined,
      Code: "Abcd",
      Type: updatedAct?.Type ? updatedAct.Type : "Reporting Activities",
      ApprovalIteration: "0",
      Client_CMID: selectedMasterData?.[0]?.Id,
      ClientName: selectedMasterData?.[0]?.Name,
      DepartmentId: selectedMasterData?.[0]?.Department,
      ActivityListId:  updatedActivity.SATActivity,
      SalesforceCode: updatedActivity.SalseforceCode,
      AssignedTo:
        updatedAct.AssignedTo?.length > 5
          ? updatedAct.AssignedTo
          : selectedMasterData?.[0]?.SubOwner,
      CreatedBy: updatedAct.CreatedBy
        ? updatedAct.CreatedBy
        : userData?.[0]?.EM_ID,
      CreateDate: formatDate(Date.now()),
      Status: updatedAct.Status ? updatedAct.Status : "P",
      StdTime: updatedActivity.SATTime,
      AjdTime: updatedActivity.AjdTime || 0,
      TotalTime:
        (parseFloat(updatedActivity.SATTime) +
        parseFloat(updatedActivity.AjdTime))||0,
      ExtraTimeRemarks: updatedActivity?.ExtraTimeRemarks || "",
      Error_SEID: 0,
      ErrorRemarks: " ",
      ReviewedBy_EMID: " ",
      ReviewStatus: " ",
      ReviewDate: " ",
      ReviewCount: 0,
      ErrorCount: 0,
    };

    if(parseFloat(updatedActivity?.AjdTime) && !updatedActivity?.ExtraTimeRemarks){
      toastRef.current.showToast("Add Reason for Extra Time", "warning");
      return false;
    }

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to update activity");
      }

      const updatedData = await response.json();
      if (updatedData?.Status === false) {
        if (updatedData?.Message === "Activity Period Close") {
          toastRef.current.showToast(
            "Period is closed. Please check the data you have updated.",
            "error"
          );
        } else {
          toastRef.current.showToast("Something went wrong.", "error");
        }
        return false;
      }

      if (updatedData?.Status === true) {
        if (updatedData?.Result === true) {
          toastRef.current.showToast("Task updated successfully.", "success");
          // fetchRelatedVouchersOnDate();
          fetchData();
          if (field == "AssignedTo") {
            if (act.AssignedTo != value) {
              notifyAssignedUser(updatedActivity?.SalseforceCode, value);
            }
          }
          return true;
        } else if (updatedData?.Result === false) {
          toastRef.current.showToast(
            "Please check the data you have updated.",
            "warning"
          );
          return false;
        }
      } else {
        toastRef.current.showToast("Something went wrong.", "error");
      }

     
    } catch (error) {
      console.error("Error updating activity:", error);
    }
  };

  const notifyAssignedUser = async (code, id) => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      const message = `assigned you a  ${code} task in Reporting Activity for client ${selectedMasterData?.[0]?.Name}(client ID - ${selectedMasterData?.[0]?.Id})`;
      const response = await fetch(`${notificationApiUrl}/PostNotification`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message,
          createdby: userData?.[0]?.EM_ID,
          sendTo: [id],
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        // await fetchNotifications();
        if (toastRef.current) {
          toastRef.current.showToast(
            "Notification sent successfully.",
            "success"
          );
        }
      } else {
        const result = await response.text();
        if (toastRef.current) {
          toastRef.current.showToast(
            `Failed to send notification: ${result}`,
            "error"
          );
        }
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      if (toastRef.current) {
        toastRef.current.showToast("Failed to send notification", "error");
      }
    }
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedActivities([]); // Reset selectedActivities to an empty array
    setSelectedMonth(newMonth);
    // if (selectedMasterData?.[0]?.Id) {
    //   getAllReportingTasks(selectedMasterData[0].Id, selectedMasterData?.[0]?.standardActivitiesTime?.filter(i => i.ActivityType === "Reporting Activites"), newMonth);
    // }
  };
  useEffect(() => {
    if (selectedMasterData?.[0]?.Id) {
      setSelectedActivities([]);
      // getAllReportingTasks(selectedMasterData[0].Id, selectedMasterData?.[0]?.standardActivitiesTime?.filter(i => i.ActivityType === "Reporting Activites"), selectedMonth);
      fetchData();
    }
  }, [selectedMonth, selectedClient]);

  useEffect(() => {
    // setIsAbleToEdit(userData?.[0]?.DR_Name !== "Admin");
    setIsAbleToEdit(userData?.[0]?.DR_Name !== "Consultant");
  }, [userData]);
  const searchBoxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <NavSub />
      <div className="nra-top-copfor-col">
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="nra-top-cop">
          <div className="nra-top-cop2" onClick={handleCaretDownClick}>
            <div className="Title-STR-main2" >
              {selectedClient?.Name || "CUSTOMER NAME"}
            </div>

            <FontAwesomeIcon
              className="str-caret-icon"
              icon={showSearch ? faCaretUp : faCaretDown}
              // onClick={handleCaretDownClick}
            />
          </div>
          <div className="cal-icon-change-reporting-activities">
            <input
              type="month"
              value={selectedMonth}
              onChange={handleMonthChange}
                className="calender_quality"
            />
          </div>
        </div>
        <div ref={searchBoxRef}>
          {showSearch && (
            <div className="nra-dd-main-suggestion">
              <div className="input-suggestion-nra">
                <div className="icon-search-phospher">
                  <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />{" "}
                </div>
                <input
                  type="text"
                  value={query}
                  className="input-suggestion-search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Start typing..."
                />
              </div>
              {loading ? (
                <p className="suggestion-title">
                  <CircularProgress
                    width={40}
                    style={{ color: "purple", position: "absolute" }}
                  />
                </p>
              ) : (
                <div>
                  {suggestions.length > 0 && (
                    <div className="new-dd-f-nra">
                      {suggestions.map((suggestion) => (
                        <div key={suggestion.Id} className="suggestion-list-li">
                          <label
                            className="li-content"
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.Name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="nra-top-cop2-top">
          <div>
            Scope : {selectedMasterData?.[0]?.OutsourcingSubprocessor} |{" "}
          </div>
          <div>
            Reporting day : {selectedMasterData?.[0]?.ReportingFrequency} |
          </div>
          <div>
            Reporting frequency : {frequency?.filter(f=>f?.Id==selectedMasterData?.[0]?.ReportingFrequency)?.[0]?.Name}
          </div>
        </div>
      </div>

      <div className="ra-new-tb-n">
        <TableContainer
          component={Paper}
          // sx={{ maxHeight: 600, overflow: "auto" }}
          sx={{ overflow: "auto" }}
        >
          <table
            className="ra-table"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr className="table-heading-toscroll">
                <th className="ra-table-top-heading column-select">Select</th>
                <th className="ra-table-top-heading column-activity-name">
                  Activity Name
                </th>
                <th className="ra-table-top-heading column-viewforce-code">
                  ViewForce Code
                </th>
                <th className="ra-table-top-heading column-preparer-name">
                  Preparer Name
                </th>
                <th className="ra-table-top-heading column-status">Scope</th>
                <th className="ra-table-top-heading column-standard-time">
                  Standard Time(Min)
                </th>
                <th className="ra-table-top-heading column-actual-time">
                  Adjusted Time(Min)
                </th>
                <th className="ra-table-top-heading column-total-time">
                  Total Time(Min)
                </th>
                <th className="ra-table-top-heading column-comments">
                  Comments
                </th>
              </tr>
            </thead>

            {/*TOP HEADING COMPONENT DONE */}
            <tbody className="ra-new-body-data">
              {selectedActivities?.map((act, index) => {
                return (
                  <tr>
                    <td className="first-child-td-nra ">
                      {act.Id && (
                        <input
                          type="checkbox"
                          checked={checkedRows?.has(act.SATId)}
                          onChange={() => handleCheckboxChange(act.SATId)}
                          className="check-searchbox"
                        />
                      )}
                    </td>
                    <td className="first-child-td-nra">{act.ActivityName}</td>
                    <td className="first-child-td-nra">{act.SalseforceCode}</td>
                    <td className="first-child-td-nra">
                      <select
                        className="nra-r-dd"
                        value={act.AssignedTo}
                        onChange={(e) =>
                          !checkedRows.has(act.SATId) &&
                          handleChange(act, "AssignedTo", e.target.value)
                        }
                        disabled={
                          checkedRows.has(act.SATId) ||
                          act.Status === "C" ||
                          !isAbleToEdit
                        }
                      >
                        {usersList
                          ?.filter(
                            (user) =>
                              user.EM_ID == selectedMasterData?.[0]?.SubOwner
                          )
                          ?.map((u) => (
                            <option
                              key={u.EM_ID}
                              value={u.EM_ID}
                              className="custom-option"
                            >
                      {u?.EM_FirstName + ' '+ u?.EM_LastName}
                      </option>
                          ))}
                        {usersList
                          ?.filter(
                            (user) =>
                              user.EM_ID != selectedMasterData?.[0]?.SubOwner
                          )
                          ?.map((u) => (
                            <option
                              className="custom-option"
                              key={u.EM_ID}
                              value={u.EM_ID}
                              style={{
                                font: "12px",
                              }}
                            >
                              {u.EM_FirstName + ' ' + u.EM_LastName}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="first-child-td-nra">
                      {act.Status !== "C" ? (
                        <select
                          className="nra-r-dd"
                          style={{ padding: "12px" }}
                          value={act.Status}
                          onChange={(e) =>
                            !checkedRows.has(act.SATId) &&
                            handleChange(act, "Status", e.target.value)
                          }
                          disabled={
                            checkedRows.has(act.SATId) ||
                            act.Status === "C" ||
                            (act.AssignedTo != userData?.[0]?.EM_ID &&
                              !isAbleToEdit)
                          }
                        >
                          <option className="custom-option2" value="">
                            Select
                          </option>
                          <option className="custom-option2" value="A">
                            Applicable
                          </option>
                          <option className="custom-option2" value="R">
                            Not Applicable
                          </option>
                        </select>
                      ) : (
                        <div style={{ color: "green" }}>Completed</div>
                      )}
                    </td>
                    <td className="first-child-td-nra">{act.SATTime}</td>
                    <td className="first-child-td-nra">
                      <input
                        className="nra-r-dd"
                        type="text"
                        style={{ padding: "12px " }}
                        value={act.AjdTime}
                        onChange={(e) =>
                          !checkedRows.has(act.SATId) &&
                          handleChange(act, "AjdTime", e.target.value)
                        }
                        disabled={
                          checkedRows.has(act.SATId) ||
                          (act.AssignedTo !== userData?.[0]?.EM_ID &&
                            !isAbleToEdit)
                        }
                      />
                    </td>
                    <td className="first-child-td-nra">
                      {parseFloat(act.SATTime) + parseFloat(act.AjdTime || 0)}
                    </td>
                    <td className="first-child-td-nra">
                      <input
                        className="nra-r-dd"
                        type="text"
                        style={{ padding: "12px" }}
                        value={act.ExtraTimeRemarks}
                        onChange={(e) =>
                          !checkedRows.has(act.SATId) &&
                          handleChange(act, "ExtraTimeRemarks", e.target.value)
                        }
                        disabled={
                          checkedRows.has(act.SATId) ||
                          (act.AssignedTo !== userData?.[0]?.EM_ID &&
                            !isAbleToEdit)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
      </div>
      {/*TOP HEADING BUTTON DONE */}
      <div className="bottm-submit-select-container">
        <div
          className={`bottm-submit-select-container-button ${
            checkedRows.size > 0 ? "active" : ""
          }`}
          onClick={updateCheckedRows}
        >
          SUBMIT
        </div>
      </div>
    </div>
  );
}

export default Reporting;
