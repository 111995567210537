import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/SynergyBillingReports.css";
import { Download, FunnelSimple, MagnifyingGlass } from "phosphor-react";
import NavSub from "../components/NavSub";
import { clientApiUrl, reportApiUrl } from "../utils/GetUrl";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress } from "@mui/material";
import isGuestUser from "../utils/guestUser";
import ToastComponent from "../components/ToastComponent";
function SynergyBillingReports() {
  const toastRef = useRef();

  const [years, setYears] = useState([]);
  const [department, setDepartment] = useState([]);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [query, setQuery] = useState("");
  const { token, userData } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [isDepartmentOpen, setIsDepartmentOpen] = useState(false);
  const [deptSearch, setDeptSearch] = useState("");
  const [department2, setDepartment2] = useState([]); // Filtered department suggestions
  const [selectedDepartments, setSelectedDepartments] = useState([]);
   const [end, setEnd] = useState(false);
  const dropdownsearchRef = useRef(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
const [loadingBillingReports, setLoadingBillingReports] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCustomersearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const handleDepartmentMenuClick = () => {
    setIsDepartmentOpen(!isDepartmentOpen);
  };

  const handleClearAllDept = () => {
    setSelectedDepartments([]);
    setDeptSearch("");
    setDepartment2([]);
  };
  const handleCheckboxDepartmentChange = (dep) => {
    const exists = selectedDepartments.some((item) => item.name === dep.name);
    if (exists) {
      setSelectedDepartments(
        selectedDepartments.filter((item) => item.name !== dep.name)
      );
    } else {
      setSelectedDepartments([...selectedDepartments, dep]);
    }
  };
  const handleCheckboxChange = (suggestion) => {
    const exists = selectedSuggestions.some(
      (item) => item.Id === suggestion.Id
    );
    if (exists) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item.Id !== suggestion.Id)
      );
    } else {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    }
  };

  const handleClearAll = () => {
    setSelectedSuggestions([]);
    setQuery("");
    setSuggestions([]);
  };
  const fetchSuggestionByText = async () => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch data");
        return;
      }
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 2) {

      fetchSuggestionByText();
    }
  }, [query]);
  const fetchDepartments = async () => {
    setLoadingDepartments(true);
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=500`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Check for authentication error
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setDepartment(data);
        setDepartment2(data);
      } else {
        console.error("Network response was not ok");
        // Show error toast message or handle the error as needed
      }

      setLoadingDepartments(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Show error toast message or handle the error as needed
    }
  };

  useEffect(() => {
    if (token) {
      fetchDepartments();
    }
  }, [token]);
  //   const filteredData = selectedSuggestions.length
  //     ? data.filter((row) =>
  //         selectedSuggestions.some((suggestion) => suggestion.Name === row.customerName)
  //       )
  //     : data;

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearRange = Array.from(
      { length: 21 },
      (_, i) => currentYear - 10 + i
    );
    setYears(yearRange);
  }, []);

  //integartion

  const [billingReports, setbillingReports] = useState([]);
  // const fetchBillingReports = async (startDate, endDate) => {
  //   console.log(startDate);
  //   console.log(endDate);
  //   setLoading(true);
  //   try {

  //     const response = await fetch(
  //       `${reportApiUrl}/GetBillingReport?StartDate=${startDate}&EndDate=${endDate}&IsCustomers=0`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status === 401) {
  //       toastRef.current.showToast(
  //         "Unauthorised access. Please login again.",
  //         "error"
  //       );
  //       await logout();
  //       return;
  //     }

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const data = await response.json();

  //     setbillingReports(data);
  //   }
     
    
  //   catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
 
  // };


  const fetchBillingReports = async (startDate, endDate) => {
    setLoadingBillingReports(true);
    console.log("Loading state set to true");
  
    try {
      const response = await fetch(
        `${reportApiUrl}/GetBillingReport?StartDate=${startDate}&EndDate=${endDate}&IsCustomers=0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
      setbillingReports(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Ensure loading state is updated regardless of success or error
      setLoadingBillingReports(false);
    }
  };
  



  
  useEffect(() => {
    // Set default dates (e.g., current month) on component mount
    const today = new Date();
    const defaultMonth = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;
    const startDate = `${defaultMonth}-01`;
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      .toISOString()
      .slice(0, 10);

    fetchBillingReports(startDate, endDate);
  }, [token]);
  const [selectedOption, setSelectedOption] = useState("Services");
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    "Services",
    "Accounting & Services",
    "Statutory & Tax Reporting",
  ];

  // Handle option click
  const handleOptionClick = (option) => {
    setSelectedOption(option); // Set the selected option
    setIsOpen(false); // Close dropdown after selecting an option
  };

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown visibility
  };

  // Generate dropdown options excluding the selected option

  const filteredReports =
    selectedOption === "Services"
      ? billingReports
      : billingReports.filter((row) => row.Services === selectedOption);

  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toISOString().slice(0, 7)
  );


  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);

    // Start date is always the first day of the month
    const startDate = `${newMonth}-01`;

    // Extract year and month
    const [year, month] = newMonth.split("-");

    // Calculate the last day of the month, ensuring UTC to avoid time zone shifts
    const endDate = new Date(Date.UTC(year, month, 0)) // Month is 1-based here
      .toISOString()
      .slice(0, 10);

    // Call the API with new dates
    fetchBillingReports(startDate, endDate);
  };

  const filteredRows = filteredReports.filter((row) =>
    row.CustomerName.toLowerCase().includes(query.toLowerCase())
  );

  // const handleDownload = async () => {
  //   const [year, month] = selectedMonth?.split("-");
  //   const startOfMonth = `${year}-${String(month).padStart(2, "0")}-01`;
  //   const endOfMonthDate = new Date(year, month, 0);

  //   const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
  //     endOfMonthDate.getMonth() + 1
  //   ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;

  //   if (isGuestUser(userData?.[0]?.DRM_ID)) {
  //     toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

  //     return;
  //   }
  //   try {
  //     const response = await fetch(
  //       `${reportApiUrl}/GetBillingReport?StartDate=${startOfMonth}&EndDate=${endOfMonth}&IsCustomers=0&getFile=true`,
  //       {
  //         // method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.status === 401) {
  //       toastRef.current.showToast(
  //         "Unauthorised access. Please login again.",
  //         "error"
  //       );
  //       await logout();
  //       return;
  //     }
  //     const result = await response.json();
  //     if (result.Status) {
  //       const fileUrl = result.Result.URI;
  //       window.location.href = fileUrl;
  //     } else {
  //       console.error("Error in fetching report:", result);
  //     }
  //   } catch (error) {
  //     console.error("Error in fetch:", error);
  //   }
  // };
  
  const handleDownload = async (event) => {
    // Prevent event bubbling to parent elements
    event.stopPropagation();
  
    const [year, month] = selectedMonth?.split("-");
    const startOfMonth = `${year}-${String(month).padStart(2, "0")}-01`;
    const endOfMonthDate = new Date(year, month, 0);
  
    const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
      endOfMonthDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;
  
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);
      return;
    }
    try {
      const response = await fetch(
        `${reportApiUrl}/GetBillingReport?StartDate=${startOfMonth}&EndDate=${endOfMonth}&IsCustomers=0&getFile=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const result = await response.json();
      if (result.Status) {
        const fileUrl = result.Result.URI;
        window.location.href = fileUrl;
      } else {
        console.error("Error in fetching report:", result);
      }
    } catch (error) {
      console.error("Error in fetch:", error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsDepartmentOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <ToastComponent ref={toastRef} timeout={10000} />
      <NavSub />
      <div className="SynergyBillingReports_header">

      <div
    className="Import_field_btn_billing"
    onClick={(e) => handleDownload(e)} // Pass the event to handleDownload
  >
    <Download size={35} color="#3d025f" weight="fill" />
    <label className="Nav-Sub-btn"> DOWNLOAD</label>
  </div>

        <div className="cal-icon-change-reporting-activities">
          <input
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="calender_quality"
          />
        </div>
      </div>
      <div
        className="synergy-billing-reports"
        style={{
          width: "95%",
          overflow: "auto",
          height: "80vh",
          zIndex: 1,
        }}
      >
        <table>
          <thead
            style={{
              position: "sticky" /* Makes the header sticky */,
              top: 0 /* Keeps it at the top of the table container */,
              backgroundColor: "#D9D9D9",
              zIndex: 1 /* Ensures the header stays on top of the body when scrolling */,
            }}
          >
            <tr>
              {/*CUSTOMER NAME */}
              <th>
                {" "}
                <span style={{ display: "flex" }}>
                  Customer Name
                  <FunnelSimple
                    onClick={handleCustomersearchClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                  {isSearchOpen && (
                    <div ref={dropdownsearchRef}
                    className="search-drop-design-report"
                    style={{
                      marginLeft: "140px !important",
                      marginTop: "23px !important",
                    }}>
                      <div className="search-drop-page">
                        <div className="main-suggestion">
                          <div className="input-suggestion">
                            <div className="icon-search-phospher">
                              <MagnifyingGlass
                                size={20}
                                color="#ada4a4"
                                weight="fill"
                              />
                            </div>
                            <input
                              type="text"
                              value={query}
                              className="input-suggestion-search"
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                              placeholder="Start typing..."
                            />
                            <button
                              className="clear-title"
                              onClick={handleClearAll}
                            >
                              Clear All
                            </button>
                          </div>
                          {loading ? (
                            <p className="suggestion-title">
                              <CircularProgress
                                width={30}
                                style={{ color: "purple" }}
                              />
                            </p>
                          ) : (
                            <div
                              style={{ maxHeight: "500px", overflowY: "auto" }}
                            >
                              {suggestions.map((suggestion) => (
                                <div
                                  key={suggestion.Id}
                                  className="suggestion-list-li"
                                >
                                  <input
                                    type="checkbox"
                                    className="check-searchbox"
                                    checked={selectedSuggestions.some(
                                      (item) => item.Id === suggestion.Id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(suggestion)
                                    }
                                  />
                                  <label className="li-content">
                                    {suggestion.Name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </span>
              </th>
              {/*SERVICES */}
              <th>
                <div>
                  <div className="header_title-new">
                    <div className="header_title">{selectedOption}</div>

                    <FunnelSimple
                      weight="bold"
                      size={20}
                      color="#061347"
                      style={{ cursor: "pointer" }}
                      onClick={toggleDropdown} //
                    />
                  </div>

                  {isOpen && (
                    <div className="dropdown-list-billingreports">
                      {options.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-item-billing-reports"
                          onClick={() => handleOptionClick(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </th>
              {/*DEPARMENT */}
              <th>
                <div className="header_title">
                  Department
                  <FunnelSimple
                    onClick={handleDepartmentMenuClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                  {isDepartmentOpen && (
                    <div ref={dropdownsearchRef}>
                      <div className="search-drop-page">
                        <div className="main-suggestion_reporting">
                          <div className="input-suggestion">
                            <div className="icon-search-phospher">
                              <MagnifyingGlass
                                size={20}
                                color="#ada4a4"
                                weight="fill"
                              />
                            </div>
                            <input
                              type="text"
                              value={deptSearch}
                              className="input-suggestion-search"
                              onChange={(e) => {
                                const value = e.target.value.toLowerCase();
                                setDeptSearch(value);
                                setDepartment2(
                                  department.filter((dep) =>
                                    dep.name.toLowerCase().includes(value)
                                  )
                                );
                              }}
                              placeholder="Start typing..."
                            />
                            <button
                              className="clear-title"
                              onClick={handleClearAllDept}
                            >
                              Clear All
                            </button>
                          </div>
                          <div style={{ overflowY: "auto" }}>
                            {department2.map((dep) => (
                              <div key={dep.id} className="suggestion-list-li">
                                <input
                                  type="checkbox"
                                  className="check-searchbox"
                                  checked={selectedDepartments.some(
                                    (item) => item.name === dep.name
                                  )}
                                  onChange={() =>
                                    handleCheckboxDepartmentChange(dep)
                                  }
                                />
                                <label className="li-content">{dep.name}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </th>
              <th>Department Head</th>
              <th>Billing Hours</th>
              <th>Total Revenue</th>
            </tr>
          </thead>
          <tbody>
            {filteredRows
              .filter(
                (row) =>
                  selectedDepartments.length === 0 ||
                  selectedDepartments.some((dep) => dep.name === row.Department)
              )
              .map((row, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "row-white" : "row-gray"}
                >
                  <td>{row.CustomerName}</td>
                  <td>{row.Services}</td>
                  <td>{row.Department}</td>
                  <td>{row.DepartmentHead}</td>
                  <td className="text-right">
                    {row.RegisteredTime.toFixed(2)}
                  </td>
                  <td className="text-right">{row.TotalRev.toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr className="total-billing-row">
              <td colSpan={4}>Total Billing </td>
              <td className="text-right">
                {filteredRows
                  .filter(
                    (row) =>
                      selectedDepartments.length === 0 ||
                      selectedDepartments.some(
                        (dep) => dep.name === row.Department
                      )
                  )
                  .reduce((sum, row) => sum + row.RegisteredTime, 0)
                  .toFixed(2)}
              </td>
              <td className="text-right">
                {filteredRows
                  .filter(
                    (row) =>
                      selectedDepartments.length === 0 ||
                      selectedDepartments.some(
                        (dep) => dep.name === row.Department
                      )
                  )
                  .reduce((sum, row) => sum + row.TotalRev, 0)
                  .toFixed(2)}
              </td>
            </tr>
          </tfoot>
        </table>
          {(loadingDepartments || loadingBillingReports) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <CircularProgress style={{ color: "purple" }} width={40} />
                  </div>
                )}
                
      </div>
    </div>
  );
}

export default SynergyBillingReports;
