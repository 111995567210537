import "../Styles/HorizontalDateCalender.css";
import React, { useEffect, useState } from "react";
// import "../Styles/ReportingCalender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

function HorizontalDateCalender({
  selectedDay,
  setSelectedDay,
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
}) {
  const [startDate, setStartDate] = useState(1);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState();
  const [activeDate, setActiveDate] = useState(null);
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleMonthChange = (monthIndex) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(monthIndex);
    setSelectedDate(newDate);
    const monthNames = Object.keys(monthAbbreviations);
    setSelectedMonth(monthNames[monthIndex]);

    setIsDatePickerOpen(false);
  };

  const changeYear = (delta) => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(selectedDate.getFullYear() + delta);
    setSelectedDate(newDate);
    setSelectedYear(newDate.getFullYear());
  };
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const daysInFebruary = isLeapYear(selectedDate.getFullYear()) ? 29 : 28;
  const daysInMonth = {
    January: 31,
    February: daysInFebruary,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };

  const monthAbbreviations = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  const monthsPicker = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleSlideLeft = () => {
    const newStartDate = startDate - 15;
    setStartDate(Math.max(1, newStartDate));
    setTimeout(() => setStartDate(Math.max(1, newStartDate - 1)), 10);
  };

  const handleSlideRight = () => {
    const lastDayOfMonth = daysInMonth[selectedMonth];
    const newStartDate = startDate + 15;
    setStartDate(Math.min(lastDayOfMonth - 15, newStartDate));
    setTimeout(
      () => setStartDate(Math.min(lastDayOfMonth - 15, newStartDate + 1)),
      10
    );
  };

  const generateDates = () => {
    const lastDayOfMonth = daysInMonth[selectedMonth];
    const endDate = Math.min(startDate + 15, lastDayOfMonth);
    return Array.from(
      { length: endDate - startDate + 1 },
      (_, index) => index + startDate
    );
  };

  useEffect(() => {
    if (selectedDay > 15) {
      handleSlideRight();
      // if (selectedDay > 27) {
      //   handleSlideRight();
      // }
    }
  }, []);

  const handleClick = (date) => {
    setSelectedDay(date);
    setActiveDate(date);
  };
  return (
    <div className="RC-container">
      <div className="RC-main-container">
        <div className="RC-month-data">
          <div className="RC-arrows-column">
            <FontAwesomeIcon
              icon={faCaretLeft}
              onClick={handleSlideLeft}
              className="l-a-ra"
            />
            <FontAwesomeIcon
              icon={faCaretRight}
              onClick={handleSlideRight}
              className="r-a-ra"
            />
          </div>
          {generateDates().map((date) => {
            const isToday =
              date === currentDate.getDate() &&
              selectedMonth ===
                currentDate.toLocaleString("default", { month: "long" }) &&
              selectedYear === currentDate.getFullYear();

            const isActive = date === selectedDay;

            return (
              <div
                key={date}
                className={`RC-day-data ${isActive ? "activeee" : ""}`}
                onClick={() => handleClick(date)}
              >
                <div>
                  {monthAbbreviations[selectedMonth]} {date}
                  <br />
                  {isToday && <div className="today-text activeee">Today</div>}
                </div>
              </div>
            );
          })}

          <div className="RC-dropdown">
            <div className="RA-months-dropdown">
              {selectedMonth} {selectedDate.getFullYear()}
              <FontAwesomeIcon
                icon={isDatePickerOpen ? faCaretUp : faCaretDown}
                className="dropdown-icon"
                onClick={toggleDatePicker}
              />
              {isDatePickerOpen && (
                <div>
                  <div className="date-picker">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "14px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCaretLeft}
                        onClick={() => changeYear(-1)}
                        style={{ cursor: "pointer" }}
                      />
                      <span className="selected-year-color-in-cal">
                        {selectedDate.getFullYear()}
                      </span>
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        onClick={() => changeYear(1)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: "14px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      {monthsPicker.map((month, index) => (
                        <div
                          key={index}
                          onClick={() => handleMonthChange(index)}
                          style={{ fontWeight: "bold" }}
                        >
                          {month}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalDateCalender;
