import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/SynergyBillingReports.css";
import { Download, FunnelSimple, MagnifyingGlass } from "phosphor-react";
import NavSub from "../components/NavSub";
import { clientApiUrl, reportApiUrl } from "../utils/GetUrl";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
import * as XLSX from "xlsx";
import { CircularProgress } from "@mui/material";

function BillingByDepartment() {
  const toastRef = useRef();
  const [years, setYears] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { token } = useContext(AuthContext);
  const { handleAuthExpiry, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isDepartmentOpen, setIsDepartmentOpen] = useState(false);
  const [deptSearch, setDeptSearch] = useState("");
  const [department2, setDepartment2] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [loadingBillingReports, setLoadingBillingReports] = useState(false);
  const dropdownsearchRef = useRef(null);
    const [end, setEnd] = useState(false);
  const handleDepartmentMenuClick = () => {
    setIsDepartmentOpen(!isDepartmentOpen);
  };

  const handleClearAllDept = () => {
    setSelectedDepartments([]);
    setDeptSearch("");
    setDepartment2([]);
  };
  const handleCheckboxDepartmentChange = (dep) => {
    const exists = selectedDepartments.some((item) => item.name === dep.name);
    if (exists) {
      setSelectedDepartments(
        selectedDepartments.filter((item) => item.name !== dep.name)
      );
    } else {
      setSelectedDepartments([...selectedDepartments, dep]);
    }
  };
  const fetchDepartments = async () => {
    setLoadingDepartments(true);
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=500`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setDepartment(data);
        setDepartment2(data);
      } else {
        console.error("Network response was not ok");
      }
      setLoadingDepartments(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchDepartments();
    }
  }, [token]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearRange = Array.from(
      { length: 21 },
      (_, i) => currentYear - 10 + i
    );
    setYears(yearRange);
  }, []);

  //integration

  const [billingReports, setbillingReports] = useState([]);
  const fetchBillingReports = async (startDate, endDate) => {
    setLoadingBillingReports(true);
    try {
      const response = await fetch(
        `${reportApiUrl}/GetBillingReport?StartDate=${startDate}&EndDate=${endDate}&IsCustomers=1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log("data to check", data);
      setbillingReports(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setLoadingBillingReports(false);
    }
  };
  useEffect(() => {
    // Set default dates (e.g., current month) on component mount
    const today = new Date();
    const defaultMonth = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;
    const startDate = `${defaultMonth}-01`;
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      .toISOString()
      .slice(0, 10);

    fetchBillingReports(startDate, endDate);
  }, [token]);

  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toISOString().slice(0, 7)
  );

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);

    // Start date is always the first day of the month
    const startDate = `${newMonth}-01`;

    // Extract year and month
    const [year, month] = newMonth.split("-");

    // Calculate the last day of the month, ensuring UTC to avoid time zone shifts
    const endDate = new Date(Date.UTC(year, month, 0)) // Month is 1-based here
      .toISOString()
      .slice(0, 10);

    // Call the API with new dates
    fetchBillingReports(startDate, endDate);
  };
  const handleDownload = () => {
    const aggregatedData = billingReports.reduce((acc, row) => {
      const key = `${row.Department}-${row.Services}`;

      const existing = acc.find((item) => item.key === key);

      if (existing) {
        existing.BillingHours += row.BillingHours;
        existing.TotalRev += row.TotalRev;
        existing.Customers = row.Customers;
      } else {
        acc.push({
          key,
          Services: row.Services,
          Department: row.Department,
          "Department Head": row.DepartmentHead,
          BillingHours: row.BillingHours,
          TotalRev: row.TotalRev,
          Customers: row.Customers,
        });
      }

      return acc;
    }, []);

    const tableData = aggregatedData
      .filter((row) => {
        if (selectedDepartments.length === 0) {
          return true;
        }
        return selectedDepartments.some((item) => item.name === row.Department);
      })
      .map((row) => ({
        Services: row.Services,
        Department: row.Department,
        "Department Head": row["Department Head"],
        "Billing Hours": row.BillingHours.toFixed(2),
        "Total Revenue": row.TotalRev.toFixed(2),
        "No. of Customers": row.Customers,
      }));

    const totalBillingHours = aggregatedData.reduce(
      (sum, row) => sum + row.BillingHours,
      0
    );
    const totalRevenue = aggregatedData.reduce(
      (sum, row) => sum + row.TotalRev,
      0
    );
    const totalCustomers = aggregatedData.reduce(
      (sum, row) => sum + row.Customers,
      0
    );

    tableData.push({
      Services: "Total",
      Department: "",
      "Department Head": "",
      "Billing Hours": totalBillingHours.toFixed(2),
      "Total Revenue": totalRevenue.toFixed(2),
      "No. of Customers": totalCustomers,
    });

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing Report");

    XLSX.writeFile(workbook, "BillingReport.xlsx");
  };

  return (
    <div>
      <ToastComponent ref={toastRef} timeout={10000} />
      <NavSub />
      <div className="SynergyBillingReports_header">
        <div className="Import_field_btn_billing" onClick={handleDownload}>
          <Download size={35} color="#3d025f" weight="fill" />
          <label className="Nav-Sub-btn">
            {" "}
            DOWNLOAD
            {/* <input type="file" style={{ display: "none" }} /> */}
          </label>
        </div>
        <div className="cal-icon-change-reporting-activities">
          <input
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="calender_quality"
          />
        </div>
      </div>
      <div
        className="synergy-billing-reports"
        style={{
          width: "95%",
          overflow: "auto",
          height: "80vh",
          zIndex: 1,
        }}
      >
        <table>
          <thead
            style={{
              position: "sticky" /* Makes the header sticky */,
              top: 0 /* Keeps it at the top of the table container */,
              zIndex: 1 /* Ensures the header stays on top of the body when scrolling */,
            }}
          >
            <tr>
              <th>Services</th>
              <th>
                <div className="header_title">
                  Department
                  <FunnelSimple
                    onClick={handleDepartmentMenuClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                  {isDepartmentOpen && (
                    <div ref={dropdownsearchRef}>
                      <div className="search-drop-page">
                        <div className="main-suggestion_reporting">
                          <div className="input-suggestion">
                            <div className="icon-search-phospher">
                              <MagnifyingGlass
                                size={20}
                                color="#ada4a4"
                                weight="fill"
                              />
                            </div>
                            <input
                              type="text"
                              value={deptSearch}
                              className="input-suggestion-search"
                              onChange={(e) => {
                                const value = e.target.value.toLowerCase();
                                setDeptSearch(value);
                                setDepartment2(
                                  department.filter((dep) =>
                                    dep.name.toLowerCase().includes(value)
                                  )
                                );
                              }}
                              placeholder="Start typing..."
                            />
                            <button
                              className="clear-title"
                              onClick={handleClearAllDept}
                            >
                              Clear All
                            </button>
                          </div>
                          <div style={{ overflowY: "auto" }}>
                            {department2.map((dep) => (
                              <div key={dep.id} className="suggestion-list-li">
                                <input
                                  type="checkbox"
                                  className="check-searchbox"
                                  checked={selectedDepartments.some(
                                    (item) => item.name === dep.name
                                  )}
                                  onChange={() =>
                                    handleCheckboxDepartmentChange(dep)
                                  }
                                />
                                <label className="li-content">{dep.name}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </th>
              <th>Department Head</th>
              <th>Billing Hours</th>
              <th>Total Revenue</th>
              <th>No. of Customers</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(
              billingReports
                .filter((row) => {
                  if (selectedDepartments.length === 0) {
                    return true;
                  }
                  return selectedDepartments.some(
                    (item) => item.name === row.Department
                  );
                })
                .reduce((acc, row) => {
                  const serviceKey = `${row.Department} - ${row.Services}`; // Group by both department and service
                  if (!acc[serviceKey]) {
                    acc[serviceKey] = {
                      ...row,
                      RegisteredTime: 0,
                      TotalRev: 0,
                    };
                  }
                  acc[serviceKey].RegisteredTime += row.RegisteredTime;
                  acc[serviceKey].TotalRev += row.TotalRev;
                  return acc;
                }, {})
            ).map(([serviceKey, data], index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "row-white" : "row-gray"}
              >
                <td>{data.Services}</td>
                <td>{data.Department}</td>
                <td>{data.DepartmentHead}</td>
                <td className="text-right">{data.RegisteredTime}</td>
                <td className="text-right">{data.TotalRev.toFixed(2)}</td>
                <td>{data.Customers}</td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className="total-billing-row">
              <td colSpan={3}>Total Billing</td>
    
              <td className="text-right">
  {(
    billingReports
      .filter((row) => {
        if (selectedDepartments.length === 0) {
          return true;
        }
        return selectedDepartments.some((item) => item.name === row.Department);
      })
      .reduce((sum, row) => sum + row.RegisteredTime, 0)
  ).toFixed(2)}
</td>

              <td className="text-right">
                {billingReports
                  .filter((row) => {
                    if (selectedDepartments.length === 0) {
                      return true;
                    }
                    return selectedDepartments.some(
                      (item) => item.name === row.Department
                    );
                  })
                  .reduce((sum, row) => sum + row.TotalRev, 0)
                  .toFixed(2)}
              </td>
              <td className="text-right">
                {Object.entries(
                  billingReports
                    .filter((row) => {
                      if (selectedDepartments.length === 0) {
                        return true;
                      }
                      return selectedDepartments.some(
                        (item) => item.name === row.Department
                      );
                    })
                    .reduce((acc, row) => {
                      const serviceKey = `${row.Department} - ${row.Services}`; // Group by department-service
                      if (!acc[serviceKey]) {
                        acc[serviceKey] = row; // Store first row's customer value (since it's the same)
                      }
                      return acc;
                    }, {})
                ).reduce((sum, [serviceKey, data]) => sum + data.Customers, 0)}
              </td>
            </tr>
          </tfoot>
        </table>
        {(loadingDepartments || loadingBillingReports) && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "20px",
                            }}
                          >
                            <CircularProgress style={{ color: "purple" }} width={40} />
                          </div>
                        )}
                      
      </div>
    </div>
  );
}

export default BillingByDepartment;
