const hostname = window.location.hostname;

let clientApiUrl;
let notificationApiUrl;
let taskApiUrl;
let userApiUrl;
let reportApiUrl;

if (hostname.includes("localhost") || hostname.includes("devhampi")) {
  clientApiUrl = "https://fn-hampi-clientsvc-dev.azurewebsites.net/api";
  notificationApiUrl =
    "https://fn-hampi-notificationsvc-dev.azurewebsites.net/api";
  taskApiUrl = "https://fn-hampi-taskmanager.azurewebsites.net/api/";
  userApiUrl = "https://fn-hampi-userservice-dev.azurewebsites.net/api";
  reportApiUrl = "https://fn-hampi-reportsvc-dev.azurewebsites.net/api";
} 
  else {
  clientApiUrl = "https://fn-hampi-clientsvc-prod.azurewebsites.net/api";
  notificationApiUrl = "https://fn-hampi-notificationsvc-prod.azurewebsites.net/api";
  taskApiUrl = "https://fn-hampi-taskmanager-prod.azurewebsites.net/api/";
  userApiUrl = "https://fn-hampi-usersvc-prod.azurewebsites.net/api";
  reportApiUrl = "https://fn-hampi-reportsvc-prod.azurewebsites.net/api";
}

export { clientApiUrl, notificationApiUrl, taskApiUrl, userApiUrl, reportApiUrl };

// Task 1 - Verify all the dev and prod endpoints. clientApiUrl and taskApiUrl for dev is verified.
