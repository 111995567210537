import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/Calender.css";
import { CaretLeft, CaretRight, XCircle } from "@phosphor-icons/react";
import HolidayMenu from "../components/HolidayMenu ";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CircularProgress } from "@mui/material";
import  isGuestUser  from '../utils/guestUser';
// import { useParams } from 'react-router-dom';
import ToastComponent from "../components/ToastComponent";
import { AuthContext } from "../context/AuthContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function LookUpCalender() {
  const toastRef = useRef();
  const currentDate = new Date();
  const { userData, token, logout } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear()); // Initialize with current year
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const { id } = useParams();
  const [holidays, setHolidays] = useState([]);
  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const [newHoliday, setNewHoliday] = useState({
    date: "",
    occasion: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    date: "",
    occasion: "",
  });

  const monthsPicker = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [loading, setLoading] = useState(true);

  const fetchHolidays = async (selectedYear) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${clientApiUrl}/GetAllholiday?inputYear=${selectedYear}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setHolidays(data);
        console.log(data);
        setLoading(false);
        setEditMode(false);
      } else {
        toastRef.current.showToast("Failed to fetch holidays", "error");
      }
    } catch (error) {
      toastRef.current.showToast("Error fetching holidays", "error");
    }
  };

  useEffect(() => {
    fetchHolidays(year);
  }, []);
  useEffect(() => {
    fetchHolidays(year);
  }, [year]);
  const handleYearChange = (newYear) => {
    setYear(newYear);
    fetchHolidays();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  // const changeYear = (delta) => {
  //   const newDate = new Date(selectedDate);
  //   newDate.setFullYear(selectedDate.getFullYear() + delta);
  //   setSelectedDate(newDate);
  // };
  const changeYear = (delta) => {
    const newYear = year + delta;
    setYear(newYear);
    const newDate = new Date(selectedDate);
    newDate.setFullYear(newYear);
    setSelectedDate(newDate);
  };
  const handleMonthChange = (monthIndex) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(monthIndex);
    setSelectedDate(newDate);
  };
  const getDaysInMonth = () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the total number of days in the month
    const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the day of the week for the first day of the month
    const daysArray = [];
    let offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    for (let i = 0; i < offset; i++) {
      daysArray.push("");
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    return daysArray;
  };

  const getWeekdayLabel = (index) => {
    return `WD${index}`;
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleSelectHoliday = (id) => {
    setSelectedHolidays((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
    console.log("Selected Holidays:", selectedHolidays);
  };
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const handleDeleteSelectedHolidays = async (holidayIds) => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      // Filter out the holidays that are selected for deletion
      const updatedHolidays = holidays.filter(
        (holiday) => !holidayIds.includes(holiday.HL_ID)
      );
      setHolidays(updatedHolidays);

      // Loop through each holiday ID to delete
      for (const idToDelete of holidayIds) {
        console.log("Deleting holiday with ID:", idToDelete);
        const response = await fetch(
          `${clientApiUrl}/DeleteHoliday?id=${idToDelete}`,
          {
            method: "DELETE",
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }

        if (!response.ok) {
          toastRef.current.showToast(`Failed to delete Holiday`, "error");
        } else {
          toastRef.current.showToast(`Holiday deleted successfully`, "success");
        }
      }

      setSelectedHolidays([]);
      fetchHolidays(year);
      setEditMode(false);
    } catch (error) {
      console.error("Error deleting holidays:", error);
      toastRef.current.showToast("Error deleting holidays", "error");
    }
  };

  // const handleAddHoliday = async () => {
  //   try {
  //     const response = await fetch('https://fn-hampi-clientsvc-dev.azurewebsites.net/api/AddHoliday', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         Date: newHoliday.date,
  //         Occasion: newHoliday.occasion,
  //       }),
  //     });
  //     if (response.ok) {
  //       const addedHoliday = await response.json();
  //       setHolidays([...holidays, addedHoliday]);
  //       setIsModalOpen(false);
  //       toastRef.current.showToast('Holiday added successfully', 'success');
  //       fetchHolidays();
  //     } else {
  //       toastRef.current.showToast('Failed to add holiday', 'error');
  //       setIsModalOpen(false);
  //     }
  //   } catch (error) {
  //     toastRef.current.showToast('Error adding holiday', 'error');
  //     setIsModalOpen(false);
  //   }
  // };
  const handleAddHoliday = async (values) => {
    // Check if the date already exists in the holidays
    const dateExists = holidays.some((holiday) => holiday.date === values.date);

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }

    if (dateExists) {
      toastRef.current.showToast(
        "Holiday for the selected date already exists",
        "error"
      );
      return;
    }

    try {
      const response = await fetch(`${clientApiUrl}/AddHoliday`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Date: values.date,
          Occasion: values.occasion,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();

      if (response.ok && data === true) {
        // Assuming the response body indicates success with data === true
        setHolidays([
          ...holidays,
          { date: values.date, occasion: values.occasion },
        ]);
        setIsModalOpen(false);
        toastRef.current.showToast("Holiday added successfully", "success");
        fetchHolidays(year);
      } else {
        toastRef.current.showToast("Failed to add holiday", "error");
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error adding holiday:", error);
      toastRef.current.showToast("Error adding holiday", "error");
      setIsModalOpen(false);
    }
  };

  const getDayFromDate = (dateString) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return days[dayOfWeek];
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewHoliday((prevHoliday) => ({ ...prevHoliday, [name]: value }));
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "date" && !value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        date: "Please select a date",
      }));
    } else if (name === "occasion" && !value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        occasion: "Please enter an occasion",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    setNewHoliday((prevHoliday) => ({ ...prevHoliday, [name]: value }));
  };

  return (
    <div className="calender_layout">
      <ToastComponent ref={toastRef} timeout={10000} />

      <div className="calendar">
        <div className="calendar-header">
          <div>TODAY</div>
          <h2 onClick={toggleDatePicker}>
            {selectedDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </h2>
          {isDatePickerOpen && (
            <div className="date-picker-pop-up">
              <div
                style={{
                  display: "inline-flex",
                  padding: "16px",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  borderRadius: "8px",
                  background: "#FFF",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  position: "absolute",
                  marginLeft: "-103px",
                  marginTop: "18px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CaretLeft
                    onClick={() => changeYear(-1)}
                    style={{ cursor: "pointer" }}
                  />
                  <span>{selectedDate.getFullYear()}</span>
                  <CaretRight
                    onClick={() => changeYear(1)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "24px",
                    width: "185px",
                    cursor: "pointer",
                  }}
                >
                  {monthsPicker.map((month, index) => (
                    <div key={index} onClick={() => handleMonthChange(index)}>
                      {month}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="calendar-grid">
          <div className="grid-header">
            <div className="grid-cell">Mon</div>
            <div className="grid-cell">Tue</div>
            <div className="grid-cell">Wed</div>
            <div className="grid-cell">Thu</div>
            <div className="grid-cell">Fri</div>
            <div className="grid-cell">Sat</div>
            <div className="grid-cell">Sun</div>
          </div>
          <div className="grid-body">
            {(() => {
              let weekdayCounter = 0; // Counter for working days (WD1, WD2, etc.)
              return getDaysInMonth().map((day, index) => {
                const currentDate = new Date(
                  selectedDate.getFullYear(),
                  selectedDate.getMonth(),
                  day
                );
                const isCurrentMonth =
                  currentDate.getMonth() === selectedDate.getMonth();
                const isCurrentDay =
                  currentDate.toDateString() === new Date().toDateString();
                const dayOfWeek = currentDate.getDay();
                const currentDayHolidays = holidays
                  ? holidays.filter((holiday) => {
                      const holidayDate = new Date(holiday.HL_Date);
                      return (
                        holidayDate.toDateString() ===
                        currentDate.toDateString()
                      );
                    })
                  : [];

                // Determine the label for the day
                let dayLabel = "";
                if (day > 0 && isCurrentMonth) {
                  if (currentDayHolidays.length > 0) {
                    // If it's a holiday, do not increment the weekday counter
                    dayLabel = ""; // No label if there's a holiday
                  } else {
                    weekdayCounter++; // Increment the weekday counter for non-holiday days
                    dayLabel = getWeekdayLabel(weekdayCounter); // Use weekday label
                  }
                }

                return (
                  <div
                    key={index}
                    className={`grid-cell-body ${
                      !isCurrentMonth ? "not-current-month" : ""
                    }`}
                  >
                    <div className="currentDay">
                      <button
                        onClick={() => handleDateChange(currentDate)}
                        className={`day-button
              ${
                day === selectedDate.getDate() && isCurrentMonth
                  ? "selected"
                  : ""
              }
              ${isCurrentDay && isCurrentMonth ? "current-day" : ""}
            `}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="date-container">
                            {day > 0 && isCurrentMonth ? day : ""}
                          </div>
                          <div className="day-label">{dayLabel}</div>
                        </div>
                      </button>
                      {currentDayHolidays.map((holiday, holidayIndex) => (
                        <div key={holidayIndex} className="holiday_day_grid">
                          {holiday.HL_Occasion}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              });
            })()}
          </div>
        </div>
      </div>
      <div className="holiday_list_container">
        <div className="holiday_list_head">
          <div className="Holiday_title">HOLIDAY LIST</div>
          <div>
            <HolidayMenu
              handleEditClick={handleEditClick}
              openModal={openModal}
            />
          </div>
        </div>
        <div className="holiday_list_content">
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress style={{ color: "#50145A" }} />
            </div>
          ) : (
            <>
              {holidays?.map((holiday, index) => (
                <div key={index} className="holiday_list_Box">
                  <div className="checkbox_holiday_date">
                    {editMode && (
                      <input
                        type="checkbox"
                        className="custom_checkbox"
                        checked={selectedHolidays.includes(holiday.HL_ID)}
                        onChange={() => handleSelectHoliday(holiday.HL_ID)}
                      />
                    )}
                    <span>{holiday.HL_Date}</span>
                    <span
                      style={{
                        color: "#A8A4A4",
                        fontSize: "14px",
                        fontWeight: 400,
                        letterSpacing: "0.03em",
                      }}
                    >
                      - {getDayFromDate(holiday.HL_Date)}
                    </span>
                  </div>
                  <div>{holiday.HL_Occasion}</div>
                </div>
              ))}
            </>
          )}
        </div>
        <div>
          {editMode && selectedHolidays.length > 0 && (
            <div className="Edit_holiday_field">
              <button
                onClick={() =>
                  handleDeleteSelectedHolidays(selectedHolidays, year)
                }
                className="btn"
              >
                CANCEL SELECTED HOLIDAYS
              </button>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
          <div className="modalLayout">
            <div className="modalLayout_contact">
              <div className="modalLayoutMain_contact">
                <div className="modalStyling">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                      {" "}
                      Add Holiday
                    </div>
                    <XCircle
                      onClick={closeModal}
                      size={32}
                      weight="fill"
                      className="text-rgba-243-237-255"
                    />
                  </div>
                  <Formik
                    initialValues={newHoliday}
                    validate={(values) => {
                      const errors = {};
                      if (!values.date) {
                        errors.date = "Select date is required";
                      }
                      if (!values.occasion.trim()) {
                        errors.occasion = "Occasion is required";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      handleAddHoliday(values, year);
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="textfield_container_lookup">
                        <div className="textfile_field_lookup">
                          <div className="Roles_row__label">Select date*</div>
                          <div>
                            <Field
                              type="date"
                              name="date"
                              placeholder="DD/MM/YYYY"
                              className="textfield_Modal"
                            />
                            <ErrorMessage
                              name="date"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="textfile_field_lookup">
                          <div className="Roles_row__label">Occasion*</div>
                          <div>
                            <Field
                              type="text"
                              name="occasion"
                              placeholder="Enter Occasion"
                              className="textfield_Modal"
                            />
                            <ErrorMessage
                              name="occasion"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="modal_button_field">
                          <button
                            className="common_btn"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "SAVE"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LookUpCalender;
