import React, { useContext, useRef, useState } from 'react';
import "../Styles/HampiLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../Images/MainLogo.png";
import   { Info }  from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ToastComponent from '../components/ToastComponent';

const validationSchema = yup.object({
    email: yup
        .string("Enter a valid email")
        .trim("Enter a valid email")
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string("Password is required")
        .required("Password is required"),
});

export default function HampiLogin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordEntered, setPasswordEntered] = useState(false);
    const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
    const {login, getUserInfo} = useContext(AuthContext);
    const toastRef = useRef();

    const handleSubmit = async (values) => {
        //console.log(values);
        try {
            setLoading(true);
            const response = await login(values);
            if (response?.Token) {
                getUserInfo(response?.Id);

            if(response?.DR_ID==="7"){
                toastRef.current.showToast('Guest User Does not have Add or Edit Access')
                await new Promise(resolve => setTimeout(resolve, 2000));
                navigate('/VoucherReports');

                return ;
            }

            if (!response?.Firstlogin) {
                toastRef.current.showToast('Logged in  Successfully', 'success')
                await new Promise(resolve => setTimeout(resolve, 2000));
                navigate('/dashboard');
                


            }
            else{
                toastRef.current.showToast('Logged in  Successfully', 'success')
                await new Promise(resolve => setTimeout(resolve, 2000));
                navigate('/HampiReset');
            }
            } else {
                // Login failed
                setInvalidCredentials(true);
                toastRef.current.showToast('Invalid Credentials', 'error')

            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error appropriately
        } finally {
            setLoading(false);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPasswordEntered(value !== "");
    };

    const toggleInfoMenu = () => {
        setIsInfoMenuOpen(!isInfoMenuOpen);
    };

    const closeInfoMenu = () => {
        setIsInfoMenuOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            checkbox: false,
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <div className='main-l'>
                        <ToastComponent ref={toastRef} timeout={4000} />

            <div className='Container-l'>
                <div>
                    <div className='img1'><img className='img-logo' loading="lazy" src={logo} alt="" /></div>
                    <div className='title'>LOG IN </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='main-user-info'>
                        <div className='user-input-box'>Email</div>
                        <TextField
                            autoFocus
                            // variant="outlined"
                            fullWidth
                            type="text"
                            name="email"
                            // className='form-control'
                            style={{width:'450px', marginBottom:'20px', borderRadius:'8px'}}

                            placeholder="Enter Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />

                        <div className='user-input-box'>Password
                            {/* <button type="button" onClick={toggleInfoMenu}>
                                <Info size={20} weight='duotone' />
                            </button> */}
                        </div>
                        {/* {isInfoMenuOpen && (
                            <div className="overlay1" onClick={closeInfoMenu}>
                                <div className="info-menu1">
                                    <ul>
                                        <li>Use Atleast 8 Characters.</li>
                                        <li>Use  at least one uppercase.</li>
                                        <li>Use a mix of letters, numbers and atleast one special character.</li>
                                    </ul>
                                </div>
                            </div>
                        )} */}
                        <TextField
                            autoFocus
                            // variant="outlined"
                            fullWidth
                            // type={showPassword ? "text" : "password"}
                            name="password"
                            type='password'
                            // className='form-control'
                            style={{width:'450px',  marginBottom:'20px', borderRadius:'8px'}}
                            placeholder="Enter Password"
                            value={formik.values.password}
                            onChange={(event) => {
                                formik.handleChange(event);
                                handlePasswordChange(event);
                            }}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            // InputProps={{
                            //     endAdornment: passwordEntered && (
                            //         <IconButton onClick={handleTogglePassword} style={{padding:'0px'}}>
                            //             {showPassword ? <VisibilityOff /> : <Visibility />}
                            //         </IconButton>
                            //     ),
                            // }}
                        />
                    </div>
                    {loading ? (
                        <div className="input-field">
                            <CircularProgress style={{color:'purple'}}/>
                        </div>
                    ) : (
                        // <div className='input-field'>
                        //     <div className='btn' type="submit"><button >LOG IN</button></div>
                        // </div>
                        // <input type="submit" className='btn' value="Log In" style={{width:'80%', margin:'0 auto', textAlign:'left'}} />
                        <button type="submit" className='' value="Log In" style={{width:'100%', margin:'0 auto', textAlign:'center', color:'white',padding:'8px', borderRadius:'5px',  background:'#50145A'}}>Log In</button>
                    )}
                    {/* {invalidCredentials && (
                        <div className="input-field">
                            <p className="error-message">Invalid email or password.</p>
                        </div>
                    )} */}
                </form>
            </div>
        </div>
    );
}
