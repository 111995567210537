import React, { useContext, useEffect, useRef, useState } from 'react';
import "../Styles/addCustReport.css";
import { UserContext } from '../context/UserContext';
import {Checkbox,FormControl,InputLabel,ListItemText,MenuItem,Select,Button,Menu,TableContainer,CircularProgress,} from '@mui/material';
import { CaretLeft, Columns, Download, File, FileArrowUp, Funnel, MagnifyingGlass, Table, XCircle } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { clientApiUrl, reportApiUrl, taskApiUrl } from '../utils/GetUrl';
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ToastComponent from '../components/ToastComponent';
import * as XLSX from "xlsx";
import NavSub from './NavSub';

function AddReports() {
  const {  teamNames ,usersList} = useContext(UserContext);
  const toastRef = useRef();
  const [expandedActivities, setExpandedActivities] = useState(true);
  const[selectedEmployees,setSelectedEmployees]=useState("");
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({"Customer Name": false,"Project Code": false,"Department": false,"Account Responsible": false,"Outsourcing Sub-Processor": false,"Status": false,"BillableTime":false,"RegisteredTime":false,"VoucherFrequencyName":false,"Lead":false});
 const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [accSearch, setAccSearch] = useState("");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarWrapperRef = useRef(null);
  const teams = teamNames?.filter((team) => team?.superTeamId === 0 && team?.type === "T" && team?.status === true);
  const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  const reportItemsColumns = ["Customer Name","Cost Center","ERPSystem","Project Code","Department","Account Responsible","Outsourcing Sub-Processor","Status","RegisteredTime","BillableTime","OutsourcingStdt","OutsourcingEddt","CustType","OrgNum","VoucherFrequencyName","ReportingFrequency","Lead","View Account Owner","Synergy Quality Consultant","Synergy Process Lead", "TerminationDate","TaskName","HOD","SynergyPriceAgreement","SynergyFixedMinutes","FixedMinutesVoucherProcessing","STRActivityAverageTime"];
  const reportItemsFilters = ["Customer","Departments","AccountResponsible","Team Wise","Time Code Wise","Project Number","Date Range","Yearly","Preparer Name","Monthly"];
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);
  const dropdownsearchRef = useRef(null);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState([]);
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] =useState();
  const [selectedPrepareName, setSelectedPrepareName] = useState(null);
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
 const handleCustomizeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
const handleCheckboxChange = (item) => {
  setSelectedOptions((prev) => {
    const updatedOptions = { ...prev, [item]: !prev[item] };
    return updatedOptions;
  });
};
const handleClearAll = () => {
    setSelectedOptions({});
  };
  //activity fetch
  const [allActivities, setAllActivities] = useState([]);

  const fetchAllActivities = async () => {
    try {  const response = await fetch(`${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast("Unauthorised access. Please login again.","error");
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setAllActivities(data?.filter((act) => act?.AL_Type === "Vouchers" || act?.AL_Type === "Reporting Activites" || act?.AL_Type === "Control Point" && act?.AL_Status === "1"));
                return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  useEffect(() => {
    if (token) {
      fetchAllActivities();
    }
  }, [token]);
//data fetch
const [masterData, setMasterData] = useState([]);
const [mergedData, setMergedData] = useState([]);
const todaysDate = new Date();
const [isTableLoaded, setIsTableLoaded] = useState(false);
const fetchInProgress = useRef(false); 
const [columns, setColumns] = useState('');
const [filters, setFilters] = useState('');
const [shares, setShares] = useState('');
const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());
const formatDate = (day, month, year) => {
  const formattedMonth = String(month).padStart(2, "0"); // Ensure two-digit month
  const formattedDay = String(day).padStart(2, "0"); // Ensure two-digit day if needed
  return `${year}-${formattedMonth}-${formattedDay || "01"}`;
};
const formatSelectedEmployees = (selected) => {
  const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
  return emIds?.join(",");
};
  const fetchCustomer = async (dateStart, dateEnd, first) => {
      if(!allActivities?.length) return;
      if (loading || fetchInProgress.current) return;
      fetchInProgress.current = true; // Set the flag to true
      setLoading(true);
    
      const date = formatDate(selectedDay, selectedMonth, selectedYear);

      try {
        setIsTableLoaded(false);
        const response = await fetch(
          `${clientApiUrl}/GetAllClient?Page=${first ? 1 : page}&ItemsPerPage=20&CreatDate=${date}${selectedEmployees.length > 0 || selectedPrepareName ? `&SubOwnerEMIDs=${formatSelectedEmployees(selectedEmployees)}` : ''}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast("Unauthorized access. Please log in again.","error");
          await logout();
          return;
        }
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          toastRef.current.showToast("Your Session has expired", "error");
          logout();
          return false;
        }
        if (response.ok) {
          const data = await response.json();
    
          if (data.length === 0) {
            setEnd(true);
          } else {
            const formattedIds = data.map((d) => `'${d?.Id}'`).join(", ");
            await fetchAndMergeData(data, formattedIds, dateStart, dateEnd);
          }
        } else {
          setLoading(false);
          setEnd(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        fetchInProgress.current = false; 
      }
    };
    
    const fetchAndMergeData = async (clientData, ids, dateStart, dateEnd) => {
      if (!ids || !dateStart || !dateEnd) return;
    
      const startOfMonth = `${selectedYear}-${String(selectedMonth).padStart(2, "0")}-01`;
      const endOfMonthDate = new Date(selectedYear, selectedMonth, 0);
      const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
        endOfMonthDate.getMonth() + 1
      ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;
    
      try {
        const formattedIdsForGetTime = clientData.map((client) => client.Id).join(",");

        const [controlPointResponse, reportingResponse, voucherResponse,timeResponse] = await Promise.all([
          fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Control%20Point`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Reporting%20Activities`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Vouchers`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(
            `${taskApiUrl}/GetTime?ClientId=${formattedIdsForGetTime}&DateRangeFrom=${startOfMonth}&DateRangeTo=${endOfMonth}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);
    
        const [controlPointTasks, reportingTasks, voucherTasks,timeData] = await Promise.all([
          controlPointResponse.ok ? controlPointResponse.json() : [],
          reportingResponse.ok ? reportingResponse.json() : [],
          voucherResponse.ok ? voucherResponse.json() : [],
          timeResponse.ok ? timeResponse.json() : [],
        ]);
    
        const mergedData = clientData.map((client) => {
          const taskDataForClient = allActivities.map((activity) => {
            const controlPointTask = controlPointTasks.find(
              (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
            );
            const reportingTask = reportingTasks.find(
              (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
            );
            const voucherTask = voucherTasks.find(
              (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
            );
    
            let taskStatus = "Pending";
            let taskCompletionStatus = "";
    
            if (controlPointTask) {
              taskCompletionStatus = controlPointTask.TaskStatus === "R" ? "No" : "Yes";
              taskStatus = controlPointTask.TaskStatus;
            } else if (reportingTask) {
              taskCompletionStatus =
                reportingTask.TaskStatus === "C"
                  ? "Completed"
                  : reportingTask.TaskStatus === "A"
                  ? "Applicable"
                  : reportingTask.TaskStatus === "R"
                  ? "Not Completed"
                  : "Pending";
              taskStatus = reportingTask.TaskStatus;
            }else if (voucherTask) {
              const totalTime = parseFloat(voucherTask.TotalTime || "0");
              taskCompletionStatus = totalTime > 0 ? totalTime.toFixed(2) : "0.00";
              taskStatus = totalTime > 0 ? "" : "N/A"; // Show N/A if no total time
            }
            return {
              ActivityName: activity.AL_Name,
              ActivityId: activity.AL_ID,
              TaskStatus: taskStatus,
              TaskCompletionStatus: taskCompletionStatus,
            };
          });
          const clientTimeData = timeData.find((time) => time.ClientID === String(client.Id));
          // const registeredTime = clientTimeData?.GetAllTime?.[0]?.RegisteredTime || "0.00";
          // const billableTime = clientTimeData?.GetAllTime?.[0]?.BillableTime || "0.00";
          const totalRegisteredTime = clientTimeData?.GetAllTime?.reduce(
            (sum, timeEntry) => sum + parseFloat(timeEntry.RegisteredTime || "0"),
            0
          );
          const totalBillableTime = clientTimeData?.GetAllTime?.reduce(
            (sum, timeEntry) => sum + parseFloat(timeEntry.BillableTime || "0"),
            0
          );
    
          return {
            ...client,
            tasks: taskDataForClient,
            RegisteredTime: totalRegisteredTime?.toFixed(2) || "0.00",
            BillableTime: totalBillableTime?.toFixed(2) || "0.00",
          };
        });
        setMergedData((prev) => [...prev, ...mergedData]);
        setIsTableLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    
useEffect(() => {
  const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
  const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
  fetchCustomer(dateStart, dateEnd, false);
}, [page,allActivities]);
useEffect(() => {
  if (isInitialRender.current) {
    isInitialRender.current = false;
    return;
  }
  setEnd(false);
  setPage(1);
  setMergedData([]);
  setMasterData([]);
  const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
  const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
  fetchCustomer(dateStart, dateEnd, true);
}, [selectedDay, selectedMonth, selectedYear, selectedEmployees]);

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const handleScroll = debounce(() => {
  // if (!isTableLoaded || loading || end) return;

  if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
    setPage((p) => p + 1);
  }
}, 1000);
const isInitialRender = useRef(true);

useEffect(() => {
  const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
  const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
  fetchCustomer(dateStart, dateEnd, false);
}, [page, allActivities]);
useEffect(() => {
  if (isInitialRender.current) {
    isInitialRender.current = false;
    return;
  }

  setEnd(false);
  setPage(1);
  setMergedData([]);
  setMasterData([]);
  // new Promise(resolve => setTimeout(resolve, 2000));

  const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
  const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
  fetchCustomer(dateStart, dateEnd, true);
}, [selectedDay, selectedMonth, selectedYear, selectedEmployees]);

useEffect(() => {
  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
}, []);
  //filters
  const handleAccountingResponsibleClick = () => {
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };
    const handleCheckboxAccountResponsibleChange = (acc) => {
    console.log(acc);
    setSelectedAccountResponsible([acc]);
    if(selectedTeams?.length){
      setSelectedTeams([]);
    }
    setSelectedEmployees([acc]);
    setSelectedPrepareName(acc);
  }
  const handlePreviousYear = () => {
    setSelectedYear((prevYear) => prevYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear((prevYear) => prevYear + 1);
  };
  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    setIsCalendarOpen(false); 
  };
  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportName, setReportName] = useState('');
  const [description, setDescription] = useState('');
  const [reportType, setReportType] = useState('');
  const openModal = () => {
    console.log('Opening modal');
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
  };  
  const toggleTeamSelector = () => {
  };
  const handleSave = async () => {
    const selectedColumns = Object.keys(selectedOptions)
      .filter((key) => selectedOptions[key])
      .join(',');
    const currentDate = new Date();
    const formattedDate = `${selectedYear}-${String(selectedMonth).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    const formattedTeams = selectedTeams?.length > 0
      ? selectedTeams.map((teamId) => {
          const team = teams.find((t) => t.id === teamId);
          return team ? team.name : '';
        }).join(',')
      : '';
    const formattedPrepareName = selectedPrepareName?.EM_FirstName && selectedPrepareName?.EM_LastName
      ? `${selectedPrepareName.EM_FirstName} ${selectedPrepareName.EM_LastName}`
      : '';
    const filters = [
      formattedDate,
      formattedTeams && `Teams:${formattedTeams}`,
      formattedPrepareName && `Preparer:${formattedPrepareName}`,
    ]
      .filter(Boolean)
      .join(',');
  
    const EM_ID = `${userData?.[0]?.EM_FirstName} ${userData?.[0]?.EM_LastName}`;
  
    const reportData = {
      ReportName: reportName,
      ReportDescription: description,
      CreatedBy: EM_ID,
      ReportType: reportType,
      Coulmns: selectedColumns,
      Filters: filters,
      Shares: shares,
    };
  
    try {
      const response = await fetch(`${reportApiUrl}/AddcustomiseReport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reportData),
      });
      if (response.status === 401) {
        if (toastRef?.current) {
          toastRef.current.showToast("Unauthorized access. Please log in again.", "error");
        }
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        if (toastRef?.current) {
          toastRef.current.showToast("Your Session has expired", "error");
        }
        logout();
        return false;
      }
      
      const responseData = await response.json();
      if (response.ok && responseData === true) {
        if (toastRef?.current) {
          toastRef.current.showToast("Report saved successfully.", "success");
        }
        closeModal();
      } else {
        if (toastRef?.current) {
          toastRef.current.showToast("Failed to save report.", "error");
        }
        closeModal();
      }
    } catch (error) {
      console.error("Error saving report:", error);
      if (toastRef?.current) {
        toastRef.current.showToast("An error occurred while saving the report.", "error");
      }
    }
  };
  
  

  const handleTeamChange = (event) => {
    // Don't reset selected teams if there's no change
    if (event.target.value?.length === 0) {
      setSelectedTeams([]); // Only clear if no team is selected
    } else {
      setSelectedTeams(event.target.value); // Update with the new selected teams
    }
  };
  
  const handleClearAllTeam = (event) => {
    event.preventDefault(); // Prevent the default action
    setSelectedTeams([]); // Clear the selected teams only
  };
  
  
  useEffect(() => {
    if (!selectedTeams?.length) {
      // Only set selected employees when no teams are selected
      setSelectedEmployees(usersList);
    }
  }, [selectedTeams, token]);
  
  
  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
    setSelectedEmployees(filteredEmployees);

    console.log(filteredEmployees);
    console.log(selectedEmployees);
  }, [selectedTeams, usersList, token]);
  const handleDownload = () => {
    // Get visible headers from the DOM
    const headers = [];
    if (selectedOptions["Customer Name"]) headers.push("Customer Name");
    if (selectedOptions["Cost Center"]) headers.push("Cost Center");
    if (selectedOptions["ERPSystem"]) headers.push("ERPSystem");
    if (selectedOptions["Project Code"]) headers.push("Project Code");
    if (selectedOptions["Department"]) headers.push("Department");
    if (selectedOptions["Account Responsible"]) headers.push("Account Responsible");
    if (selectedOptions["Outsourcing Sub-Processor"]) headers.push("Outsourcing Sub-Processor");
    if (selectedOptions["OutsourcingStdt"]) headers.push("Outsourcing StartDate");
    if (selectedOptions["OutsourcingEddt"]) headers.push("Outsourcing EndDate");
    if (selectedOptions["CustType"]) headers.push("Cust Type");
    if (selectedOptions["OrgNum"]) headers.push("Organisation Number");
    if (selectedOptions["VoucherFrequencyName"]) headers.push("Voucher Frequency");
    if (selectedOptions["ReportingFrequency"]) headers.push("Reporting Frequency");
    if (selectedOptions["Lead"]) headers.push("View Team Lead");
    if (selectedOptions["View Account Owner"]) headers.push("View Account Owner");
    if (selectedOptions["Synergy Quality Consultant"]) headers.push("Synergy Quality Consultant");
    if (selectedOptions["Synergy Process Lead"]) headers.push("Synergy Process Lead");
    if (selectedOptions["TerminationDate"]) headers.push("TerminationDate");
    if (selectedOptions["TaskName"]) headers.push("TaskName");
    if (selectedOptions["HOD"]) headers.push("HOD");
    if (selectedOptions["SynergyPriceAgreement"]) headers.push("SynergyPriceAgreement");
    if (selectedOptions["SynergyFixedMinutes"]) headers.push("SynergyFixedMinutes");
    if (selectedOptions["FixedMinutesVoucherProcessing"]) headers.push("FixedMinutesVoucherProcessing");
    if (selectedOptions["STRActivityAverageTime"]) headers.push("STRActivityAverageTime");
    if (selectedOptions["Status"]) headers.push("Status");
    if (selectedOptions["RegisteredTime"]) headers.push("Registered Time");
    if (selectedOptions["BillableTime"]) headers.push("Billable Time");
  
    // Add expanded activity headers if visible
    if (expandedActivities) {
      allActivities?.forEach((activity) => {
        if (selectedOptions[activity.AL_Name]) headers.push(activity.AL_Name);
      });
    }
  
    // Get visible table data based on selectedOptions
    const tableData = mergedData.map((data) => {
      const row = [];
      if (selectedOptions["Customer Name"]) row.push(data.Name || "");
      if (selectedOptions["Cost Center"]) row.push(data.CostCenter || "");
      if (selectedOptions["ERPSystem"]) row.push(data.ERPSystem || "");
      if (selectedOptions["Project Code"]) row.push(data.ProjectNumber || "");
      if (selectedOptions["Department"]) row.push(data.Department || "");
      if (selectedOptions["Account Responsible"]) row.push(getUserFirstName(data.SubOwner) || "");
      if (selectedOptions["Outsourcing Sub-Processor"]) row.push(data.OutsourcingSubprocessor || "");
      if (selectedOptions["OutsourcingStdt"]) row.push(data.OutsourcingStdt || "");
      if (selectedOptions["OutsourcingEddt"]) row.push(data.OutsourcingEddt || "");
      if (selectedOptions["CustType"]) row.push(data.CustType || "");
      if (selectedOptions["OrgNum"]) row.push(data.OrgNum || "");
      if (selectedOptions["VoucherFrequencyName"]) row.push(data.VoucherFrequencyName || "");
      if (selectedOptions["ReportingFrequency"]) row.push(data.ReportingFrequencyName || "");
      if (selectedOptions["Lead"]) row.push(data.LeadId || "");
      if (selectedOptions["View Account Owner"]) row.push(data.OwnerEMId || "");
      if (selectedOptions["Synergy Quality Consultant"]) row.push(getUserFirstName(data.SubQC) || "");
      if (selectedOptions["Synergy Process Lead"]) row.push(getUserFirstName(data.SubProcLead) || "");
      if (selectedOptions["TerminationDate"]) row.push(data.TerminationDate || "");
      if (selectedOptions["TaskName"]) row.push(data.TaskName || "");
      if (selectedOptions["HOD"]) row.push(data.HODName || "");
      if (selectedOptions["SynergyPriceAgreement"]) row.push(data.SynergyPriceAgreement || "");
      if (selectedOptions["SynergyFixedMinutes"]) row.push(data.SynergyFixedMinutes || "");
      if (selectedOptions["FixedMinutesVoucherProcessing"]) row.push(data.FixedMinutesVoucherProcessing || "");
      if (selectedOptions["STRActivityAverageTime"]) row.push(data.STRActivityAverageTime || "");
      if (selectedOptions["Status"]) row.push(data.Status || "");
      if (selectedOptions["RegisteredTime"]) row.push(data.RegisteredTime || "");
      if (selectedOptions["BillableTime"]) row.push(data.BillableTime || "");
        if (expandedActivities) {
        allActivities
          ?.filter((activity) => selectedOptions[activity.AL_Name])
          .forEach((activity) => {
            const task = data.tasks.find((t) => t.ActivityId === activity.AL_ID);
            row.push(task ? `${task.TaskCompletionStatus} (${task.TaskStatus})` : "0.00");
          });
      }
      return row;
    });
      const ws = XLSX.utils.aoa_to_sheet([headers, ...tableData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CUSTOMIZE REPORTS");
    XLSX.writeFile(wb, "CUSTOMIZE_REPORTS.xlsx");
  };
  
  const getUserFirstName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user ? user.EM_FirstName + " " + user.EM_LastName : "";
  };
  useEffect(() => {
    const tableContainer = document.querySelector(".table-container-cust");

    if (tableContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        tableContainer.classList.add("active");
        startX = e.pageX - tableContainer.offsetLeft;
        scrollLeft = tableContainer.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseUp = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - tableContainer.offsetLeft;
        const walk = (x - startX) * 2; // Adjust multiplier for speed
        tableContainer.scrollLeft = scrollLeft - walk;
      };

      tableContainer.addEventListener("mousedown", handleMouseDown);
      tableContainer.addEventListener("mouseleave", handleMouseLeave);
      tableContainer.addEventListener("mouseup", handleMouseUp);
      tableContainer.addEventListener("mousemove", handleMouseMove);

      return () => {
        tableContainer.removeEventListener("mousedown", handleMouseDown);
        tableContainer.removeEventListener("mouseleave", handleMouseLeave);
        tableContainer.removeEventListener("mouseup", handleMouseUp);
        tableContainer.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);
  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className='add_layout_cust_report'>

        <div className="table_head_custom_add">
          <div className='individual_btn_cust'>
          <FormControl className="select-dropdown-user">
  <InputLabel
    id="team-selector-label"
    className="select-dropdown-label"
    shrink={false}
    onClick={toggleTeamSelector}
    style={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }}
  >
    SELECT TEAMS
  </InputLabel>

  <Select
    labelId="team-selector-label"
    id="team-selector"
    multiple
    value={selectedTeams}
    onChange={handleTeamChange}
    renderValue={(selected) =>
      selected
        .map((teamId) => {
          const team = teams.find((team) => team.id === teamId);
          return team?.Name;
        })
        .join(" ")
    }
    style={{ width: "150px" }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 250,
        },
      },
    }}
  >
    {selectedTeams?.length > 0 && (
      <MenuItem onClick={(e) => handleClearAllTeam(e)}>
        <ListItemText style={{ color: "red" }} primary={"Clear All"} />
      </MenuItem>
    )}
    {teams.map((team) => (
      <MenuItem key={team.id} value={team.id}>
        <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
        <ListItemText primary={team.name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>


              <div className="Title-STR-main2" onClick={handleAccountingResponsibleClick}>
          {selectedPrepareName?.EM_FirstName + selectedPrepareName?.EM_LastName || "Preparer Name"}
          <FontAwesomeIcon
            className="str-caret-icon"
            icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}

          />
          {isAccountResponsibleOpen && (
            <div ref={dropdownsearchRef} className="search-drop-DailyVoucher">
              <div className="search-drop-page">
                <div className="main-suggestion">
                  <div className="input-suggestion">
                    <div className="icon-search-phospher">
                      <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />
                    </div>
                    <input
                      type="text"
                      value={accSearch}
                      className="input-suggestion-search"
                      onClick={(e) => e.stopPropagation()} 
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        setAccSearch(value);
                        setAccountResponsible2(
                          accountResponsible?.filter((acc) =>
                            (acc.EM_FirstName + ' ' + acc?.EM_LastName).toLowerCase().includes(value)
                          )
                        );
                      }}
                      placeholder="Start typing..."
                    />
                  </div>
                  <div tabIndex={1}>
                  <div style={{color:'#B00000', padding:'2px 22px'}} onClick={()=>{setSelectedPrepareName(); setSelectedAccountResponsible([]); setSelectedEmployees([])}}>
                      Clear All
                    </div>
                    {accountResponsible2?.map((acc) => (
                      <div key={acc.EM_ID} className="suggestion-list-li">
                        <input
                          type="radio"
                          className="radio-searchbox"
                          style={{
                            accentColor: 'rgb(80, 20, 90)',
                            width: '18px',
                            height: '18px',
                          }}
                          checked={selectedAccountResponsible?.some(
                            (item) => item.EM_ID === acc?.EM_ID  
                          )}
                          onChange={() =>
                            handleCheckboxAccountResponsibleChange(acc)
                          }
                        />
                        <label className="li-content">
                          {acc.EM_FirstName + ' ' + acc.EM_LastName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
        <div style={{display:"flex",justifyContent:"flex-end",gap:"24px"}}>
 <div className="calendar-picker" ref={calendarWrapperRef}>
      <button onClick={toggleCalendar} className="calendar-button">
        {`${months[selectedMonth - 1]} ${selectedYear}`}
      </button>
      {isCalendarOpen && (
        <div className="calendar-dropdown">
          <div className="year-selector">
            <button onClick={handlePreviousYear} className="year-nav">&lt;</button>
            <span className="year-display">{selectedYear}</span>
            <button onClick={handleNextYear} className="year-nav">&gt;</button>
          </div>

          <div className="month-grid">
            {months.map((month, index) => (
              <div
                key={index}
                className={`month-item ${index + 1 === selectedMonth ? "selected" : ""}`}
                onClick={() => handleMonthChange(index + 1)}
              >
                {month}
              </div>
            ))}
          </div>
          
        </div>
      )}
      
    </div>
 </div>
              
            </div>
            <div className='individual_btn_cust'>

            <div className='cust_common_btn' onClick={handleCustomizeClick}>Customize Report</div>
            <Menu anchorEl={anchorEl} open={open}
              onClose={handleClose} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}>
              <div className="dropdown-container">
                <div className="dropdown-section columns-section">
                  <div className='dropdwon_coumns_head'><Columns size={24} /><div className='title_reprot_filter'>Columns</div></div>
                  {/* <div className="search-container_reort"><MagnifyingGlass size={16} color="#A8A4A4" /> <input type="text" placeholder="Search..." className="search-input-cust-report"/></div> */}
                  {reportItemsColumns.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleCheckboxChange(item)}>
                      <Checkbox checked={!!selectedOptions[item]} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                   {allActivities.map((activity, index) => (
          <MenuItem key={`activity-${index}`} onClick={() => handleCheckboxChange(activity.AL_Name)}>
            <Checkbox checked={!!selectedOptions[activity.AL_Name]} />
            <ListItemText primary={activity.AL_Name} />
          </MenuItem>
        ))}
                </div>
             
              </div>
              <div className="dropdown-footer">
                <button className="common_btn_cust_repo_claer_all" onClick={handleClearAll}>Clear All</button>
                <button onClick={handleClose} className='common_btn_cust_repo'>Apply</button>
              </div>
            </Menu>
            <div className='cust_common_btn' onClick={handleDownload}><Download weight='fill' size={20} /> DOWNLOAD files</div>
            <div className='cust_common_btn'  onClick={openModal}><File weight='fill' size={20} />Save As</div>
            {isModalOpen && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
          <div className="modalLayout">
            <div className="modalLayout_contact">
              <div className="modalLayoutMain_contact">
                <div className="modalStyling">
                  <div className='Cust_report_modal_layout'>
                  <div className="flex justify-between gap-10 items-center">
                    <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                      Customize Report
                    </div>
                    <XCircle
                      onClick={closeModal}
                      size={32}
                      weight="fill"
                      className="text-rgba-243-237-255 cursor-pointer"
                    />
                  </div>
                  <div className="textfield_container_lookup">
                                       <div className="textfile_field_lookup">
                      <label htmlFor="reportName" className="text-sm font-medium text-gray-700">Report Name</label>
                      <input
                        type="text"
                        id="reportName"
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                        placeholder="Enter Name"
                        className="w-full p-2 mt-2 border rounded-md"
                      />
                    </div>
                   <div className="textfile_field_lookup">
                      <label htmlFor="description" className="text-sm font-medium text-gray-700">Description</label>
                      <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description"
                  className="w-full p-2 mt-2 border rounded-md"
                  rows="4" 
                />
                    </div>
                    <div className="textfile_field_lookup">
                <label htmlFor="reportType" className="text-sm font-medium text-gray-700">Report Type</label>
                <select
                  id="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  className="w-full p-2 mt-2 border rounded-md"
                >
                 <option value="">Select Report Type</option>
                  <option value="private">Private Report</option>
                  <option value="public">Public Report</option>
                </select>
              </div>
                    <div className="flex justify-end mt-4">
                      <button
                        onClick={handleSave}
                        className="common_btn"
                        >
                        SAVE
                      </button>
                    </div>
</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
            </div>
 </div>
        <div className="table-container-cust">
        <table className="custom-table"> 
  <thead>
    <tr className="table-header-cust">
      {selectedOptions["Customer Name"] && <th className="table-header-cell-cust">Customer Name</th>}
      {selectedOptions["Cost Center"] && <th className="table-header-cell-cust">Cost Center</th>}
      {selectedOptions["ERPSystem"] && <th className="table-header-cell-cust">ERPSystem</th>}
      {selectedOptions["Project Code"] && <th className="table-header-cell-cust">Project Code</th>}
      {selectedOptions["Department"] && <th className="table-header-cell-cust">Department</th>}
      {selectedOptions["Account Responsible"] && <th className="table-header-cell-cust">Account Responsible</th>}
      {selectedOptions["Outsourcing Sub-Processor"] && <th className="table-header-cell-cust">Outsourcing Sub-Processor</th>}
      {selectedOptions["OutsourcingStdt"] && <th className="table-header-cell-cust">Outsourcing StartDate</th>}
      {selectedOptions["OutsourcingEddt"] && <th className="table-header-cell-cust">Outsourcing EndDate</th>}
      {selectedOptions["CustType"] && <th className="table-header-cell-cust">Cust Type</th>}
      {selectedOptions["OrgNum"] && <th className="table-header-cell-cust">Organisation Number</th>}
      {selectedOptions["VoucherFrequencyName"] && <th className="table-header-cell-cust">Voucher Frequency</th>}
      {selectedOptions["ReportingFrequency"] && <th className="table-header-cell-cust">Reporting Frequency</th>}
      {selectedOptions["Lead"] && <th className="table-header-cell-cust">View Team Lead	</th>}
      {selectedOptions["View Account Owner"] && <th className="table-header-cell-cust">View Account Owner</th>}

      {selectedOptions["Synergy Quality Consultant"] && <th className="table-header-cell-cust">Synergy Quality Consultant</th>}
      {selectedOptions["Synergy Process Lead"] && <th className="table-header-cell-cust">Synergy Process Lead	</th>}
      {/* {selectedOptions["Synergy Department Lead"] && <th className="table-header-cell-cust">Synergy Department Lead</th>} */}
      {selectedOptions["TerminationDate"] && <th className="table-header-cell-cust">TerminationDate</th>}
      {selectedOptions["TaskName"] && <th className="table-header-cell-cust">TaskName</th>}
      {selectedOptions["HOD"] && <th className="table-header-cell-cust">HOD</th>}
      {selectedOptions["SynergyPriceAgreement"] && <th className="table-header-cell-cust">SynergyPriceAgreement</th>}
      {selectedOptions["SynergyFixedMinutes"] && <th className="table-header-cell-cust">SynergyFixedMinutes</th>}
      {selectedOptions["FixedMinutesVoucherProcessing"] && <th className="table-header-cell-cust">FixedMinutesVoucherProcessing</th>}
      {selectedOptions["STRActivityAverageTime"] && <th className="table-header-cell-cust">STRActivityAverageTime</th>}
  {selectedOptions["Status"] && <th className="table-header-cell-cust">Status</th>}
      {selectedOptions["RegisteredTime"] &&<th className="table-header-cell-cust">Registered Time</th>}
      {selectedOptions["BillableTime"] &&<th className="table-header-cell-cust">Billable Time</th>}
      {expandedActivities && allActivities?.map((act) => {
        if (selectedOptions[act.AL_Name]) { 
          return (
            <th className="Expand-styles" style={{ minWidth: '250px', padding: "15px" }} key={act.AL_ID}>
              {act.AL_Name}
            </th>
          );
        }
        return null;
      })}
    </tr>
  </thead>
  <tbody>
    {mergedData.map((data, index) => (
      <tr key={index} className={index % 2 === 0 ? "table-row-white" : "table-row-alternate"}>
        {selectedOptions["Customer Name"] && <td className="table-cell-cust">{data.Name}</td>}
        {selectedOptions["Cost Center"] && <td className="table-cell-cust">{data.CostCenter}</td>}
        {selectedOptions["ERPSystem"] && <td className="table-cell-cust">{data.ERPSystem}</td>}
        {selectedOptions["Project Code"] && <td className="table-cell-cust">{data.ProjectNumber}</td>}
        {selectedOptions["Department"] && <td className="table-cell-cust">{data.Department}</td>}
        {selectedOptions["Account Responsible"] && (
          <td className="table-cell-cust">{getUserFirstName(data?.SubOwner)}</td>
        )}
                {selectedOptions["Outsourcing Sub-Processor"] && <td className="table-cell-cust">{data.OutsourcingSubprocessor}</td>}
                {selectedOptions["OutsourcingStdt"] && <td className="table-header-cell-cust">{data.OutsourcingStdt}</td>}
      {selectedOptions["OutsourcingEddt"] && <td className="table-header-cell-cust">{data.OutsourcingEddt}</td>}
      {selectedOptions["CustType"] && <td className="table-header-cell-cust">{data.CustType}</td>}
      {selectedOptions["OrgNum"] && <td className="table-header-cell-cust">{data.OrgNum}</td>}
      {selectedOptions["VoucherFrequencyName"] && <td className="table-header-cell-cust">{data.VoucherFrequencyName}</td>}
      {selectedOptions["ReportingFrequency"] && <td className="table-header-cell-cust">{data.ReportingFrequencyName}</td>}
      {selectedOptions["Lead"] && <td className="table-header-cell-cust">{data.LeadId}</td>}
      {selectedOptions["View Account Owner"] && <td className="table-header-cell-cust">{data.OwnerEMId}</td>}

      {selectedOptions["Synergy Quality Consultant"] && <td className="table-header-cell-cust">{getUserFirstName(data?.SubQC)}	</td>}
      {selectedOptions["Synergy Process Lead"] && <td className="table-header-cell-cust">{getUserFirstName(data?.SubProcLead)}</td>}
      {/* {selectedOptions["Synergy Department Lead"] && <td className="table-header-cell-cust">{getUserFirstName(data?.SubLead)}</td>} */}
      {selectedOptions["TerminationDate"] && <td className="table-header-cell-cust">{data.TerminationDate}</td>}
      {selectedOptions["TaskName"] && <td className="table-header-cell-cust">{data.TaskName}</td>}
      {selectedOptions["HOD"] && <td className="table-header-cell-cust">{data.HODName}</td>}
      {selectedOptions["SynergyPriceAgreement"] && <td className="table-header-cell-cust">{data.SynergyPriceAgreement}</td>}
      {selectedOptions["SynergyFixedMinutes"] && <td className="table-header-cell-cust">{data.SynergyFixedMinutes}</td>}
      {selectedOptions["FixedMinutesVoucherProcessing"] && <td className="table-header-cell-cust">{data.FixedMinutesVoucherProcessing}</td>}
      {selectedOptions["STRActivityAverageTime"] && <td className="table-header-cell-cust">{data.STRActivityAverageTime}</td>}
        {selectedOptions["Status"] && <td className="table-cell-cust">{data.Status}</td>}
        {selectedOptions["RegisteredTime"] &&<td className="table-cell-cust">{data.RegisteredTime}</td>}
        {selectedOptions["BillableTime"] && <td className="table-cell-cust">{data.BillableTime}</td>}
        {expandedActivities &&
      allActivities
        ?.filter((activity) => selectedOptions[activity.AL_Name])
        .map((activity) => {
          const task = data.tasks.find((t) => t.ActivityId === activity.AL_ID);
          return (
            <td
              key={activity.AL_ID}
              className="table-cell-cust"
              style={{ textAlign: "center" }}
            >
              {task ? `${task.TaskCompletionStatus} (${task.TaskStatus})` : "0.00"}
            </td>
          );
        })
    }
</tr>
    ))}
  </tbody>
</table>

  {loading && (<div className="loading-indicator"><CircularProgress sx={{ color: '#50145A' }} /></div>)}
  {end && (<div className="no-more-data"><p>No data to load</p></div>)}
</div>
  </div>
    </div>
  );
}
export default AddReports;
