import React, { useContext, useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import  isGuestUser  from '../utils/guestUser';
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
import "../Styles/VoucherEntry.css";
export default function VoucherReviewMUI({
  data,
  activityList,
  selectedOption,
  selectedMasterData,
  fetchRelatedVouchersOnDate,
  ...props
}) {
  //console.log(selectedOption);
  const [rows, setRows] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElError, setAnchorElError] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const { userData } = useContext(AuthContext);
  const [errors, setErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { token, logout } = useContext(AuthContext);

  const [isEditable, setIsEditable] = useState(true);

  // useEffect(() => {
  //   //console.log(props.selectedDay, props.selectedMonth, props.selectedYear);

  //   const monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   const monthIndex = monthNames.indexOf(props.selectedMonth);

  //   if (monthIndex === -1) {
  //     console.error("Invalid month name");
  //     return;
  //   }

  //   const today = new Date();
  //   const todayDateOnly = new Date(
  //     today.getFullYear(),
  //     today.getMonth(),
  //     today.getDate()
  //   );

  //   const targetDate = new Date(
  //     props.selectedYear,
  //     monthIndex,
  //     props.selectedDay
  //   );

  //   // Log the dates for debugging
  //   //console.log("Today:", todayDateOnly);
  //   //console.log("Target Date:", targetDate);

  //   if (
  //     todayDateOnly.getFullYear() === targetDate.getFullYear() &&
  //     todayDateOnly.getMonth() === targetDate.getMonth() &&
  //     todayDateOnly.getDate() === targetDate.getDate()
  //   ) {
  //     setIsEditable(true);
  //   } else {
  //     setIsEditable(false);
  //   }
  // }, [props.selectedDay, props.selectedMonth, props.selectedYear]);
  // const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElError(null);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(index);
    //console.log(index);
  };
  const handleClickError = (event, index) => {
    setAnchorElError(event.currentTarget);
    setCurrentRow(index);
    //console.log(index);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  const columns = [
    // { id: "select", label: "", minWidth: 50 }, // Checkbox Column
    { id: "customerName", label: "Customer Name", minWidth: 250 },
    { id: "activityName", label: "VOUCHER NUMBER", minWidth: 160 },
    { id: "viewForceCode", label: "VOUCHER TYPE", minWidth: 300 },
    {
      id: "standardTime",
      label: "Standard Time (Min)",
      minWidth: 140,
      align: "left",
      textAlign: "center",
    },
    {
      id: "actualTime",
      label: "Adjusted Time (Min)",
      minWidth: 140,
      align: "left",
      textAlign: "center",
    },
    {
      id: "totalTime",
      label: "Total Time (Min)",
      minWidth: 140,
      align: "left",
      textAlign: "center",
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 150,
      align: "left",
      textAlign: "left",
    },
    { id: "errorSEID", label: "Error SEID", minWidth: 180, align: "left" }, // New Column
    {
      id: "errorRemarks",
      label: "Error Remarks",
      minWidth: 140,
      align: "left",
    }, // New Column
    {
      id: "reviewCount",
      label: "Review Count",
      minWidth: 130,
      align: "left",
    }, // New Column
    { id: "errorCount", label: "Error Count", minWidth: 130, align: "left" }, // New Column
    {
      id: "edit",
      label: "Edit",
      minWidth: 50,
      align: "left",
      textAlign: "center",
    },
  ];

  const editTask = async (row) => {

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    if (row.AjdTime?.startsWith("0")) {
      row.AjdTime = row?.AjdTime.substring(1); // Removes the leading '0'
    }

      if (row.AjdTime!="" && isNaN(parseFloat(row.AjdTime))) {
        // Handle the case where row.AjdTime is not a valid number
        toastRef.current.showToast("Invalid Adjusted Time", "warning");
  
        return false;
      }
    
  
   
    if(parseFloat(row?.AjdTime) && !row?.ExtraTimeRemarks){
      toastRef.current.showToast("Add Reason for Adjusted Time", "warning");
      return false;
    }
    if(parseFloat(row?.Error_SEID) && !row?.ErrorRemarks){
      toastRef.current.showToast("Add Reason for Error", "warning");
      return false;
    }

    const updatedRow = {
      ...row,
      // Client_CMID: selectedOption?.Id,
      // ClientName: selectedOption?.Name,
      // CreatedBy: userData?.[0]?.EM_ID,
      // DepartmentId: selectedMasterData?.[0]?.Department,
      ErrorCount: row?.Error_SEID? 1:0,
      AjdTime:parseInt(row.AjdTime)||0,
      TotalTime: parseInt(row.StdTime) + (parseInt(row.AjdTime)||0),
    };

    try {
      const response = await fetch(`${taskApiUrl}/UpdateTask`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const data = await response.json();
      console.log(data);
      if (data.Result == true && data.Status == true) {
        toastRef.current.showToast("Updated successfully.", "success");
        return true
      }
      else if (data.Message === "Activity Period Close") {
        toastRef.current.showToast(
          "Period is closed. Unable to update.",
          "error"
        );
      } else {
        toastRef.current.showToast("Check the data.", "error");
      }
    } catch (e) {
      console.error(e);
      toastRef.current.showToast(
        "An error occurred while updating the task.",
        "error"
      );
    }
  };

  const handleSelect = (AL_ID) => {
    const time = selectedMasterData?.[0]?.standardActivitiesTime?.filter(
      (a) => a.SATActivity == AL_ID
    )?.[0]?.SATTime;

    //console.log(AL_ID, time);
    //console.log(time);
    //console.log(currentRow, rows?.length);
    if (currentRow != undefined) {
      const updatedRows = rows.map((row, index) => {
        //console.log("ttt");
        //console.log(index, currentRow);
        if (index === currentRow) {
          setEditedRow((editedRow) => ({
            ...editedRow,
            ActivityListId: AL_ID,
            StdTime: time,
            TotalTime: parseFloat(time) + parseFloat(editedRow.AjdTime),
          }));
          return { ...editedRow, ActivityListId: AL_ID, StdTime: time };
        }
        return row;
      });
      //console.log(updatedRows);
      setRows(updatedRows);
    } else {
      //console.log("mmm");
    }
    handleClose();
  };

  const handleSelectError = (AL_ID) => {
    const er = errors?.filter((a) => a.StdId == AL_ID)?.[0]?.StdId;
  
    if (currentRow != undefined) {
      const updatedRows = rows.map((row, index) => {
        if (index === currentRow) {
          // Check if the Error_SEID is already the selected AL_ID
          const newError_SEID = editedRow?.Error_SEID === AL_ID ? null : AL_ID;
  
          setEditedRow((editedRow) => ({ ...editedRow, Error_SEID: newError_SEID }));
          return { ...editedRow, Error_SEID: newError_SEID };
        }
        return row;
      });
      //console.log(updatedRows);
      setRows(updatedRows);
    }
  
    handleClose();
  };
  

  const handleSelectedRows = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(row)) {
        return prevSelectedRows.filter((r) => r !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleInputChange = (e, rowIndex = null) => {
    const { name, value } = e.target;

    if (rowIndex !== null) {
      // If editing an existing row
      setEditedRow((prevRow) => {
        const updatedRow = {
          ...prevRow,
          TotalTime: parseFloat(prevRow.StdTime) + parseFloat(prevRow.AjdTime),
          [name]: value,
        };
        if (name === "StdTime" || name === "AjdTime") {
          updatedRow.TotalTime = (
            parseFloat(updatedRow.StdTime) + parseFloat(updatedRow.AjdTime)
          ).toFixed(2);
        }
        if(name==="Error_SEID"){

        }
        return updatedRow;
      });
    } else {
      // If updating the new row
    }
  };

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleEditClick = (index) => {
    //console.log(index);
    setEditRowIndex(index);
    setEditedRow(rows[index]);
  };

  const handleSaveClick = async (index) => {
    const updatedRows = rows.map((row, i) => (i === index ? editedRow : row));

    

    try {
     const ed= await editTask(editedRow);
     if(ed){
      setRows(updatedRows);
    setEditRowIndex(null);
    setEditedRow({});
     }
    } catch (error) {
      console.error("Failed to save the data:", error);
      toastRef.current.showToast("Failed to submit data", "error");
    }
  };

  const getErrors = async () => {
    const response = await fetch(`${taskApiUrl}/GetAllStandardError`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      toastRef.current.showToast(
        "Unauthorised access. Please login again.",
        "error"
      );
      await logout();
      return;
    }
    const data = await response.json();
    //console.log(data);
    setErrors(data);
  };

  const approveData = async () => {
    console.log(selectedRows);

    try {
      for (let i = 0; i < selectedRows?.length; i++) {
        const editedRow = {
          ...selectedRows[i],
          TotalTime:
            parseInt(selectedRows[i].StdTime) +
            parseInt(selectedRows[i].AjdTime),
          ExtraTimeRemarks: selectedRows[i].ExtraTimeRemarks,
          ReviewCount: parseFloat(selectedRows[i]?.ReviewCount) + 1,
          ReviewedBy_EMID: userData?.[0]?.EM_ID,
          ReviewDate: formatDate(),
          Status: "C",
        };
        if(parseFloat(selectedRows[i]?.AjdTime) && !selectedRows[i]?.ExtraTimeRemarks){
          toastRef.current.showToast("Add Reason for Adjusted Time", "warning");
          return;
        }
        if(parseFloat(selectedRows[i]?.Error_SEID) && !selectedRows[i]?.ErrorRemarks){
          toastRef.current.showToast("Add Reason for Error", "warning");
          return false;
        }

        const edit = await editTask(editedRow);
        

      }
        fetchRelatedVouchersOnDate(props.selectedPrepareName?.EM_ID || '');
      setSelectedRows([]);
    } catch (error) {
      console.error("Failed to approve data:", error);
      toastRef.current.showToast(
        "Failed to approve data due to an unexpected error.",
        "error"
      );
    }
  };

  useEffect(() => {
    getErrors();
  }, []);
  const toastRef = useRef();
  return (
    <div className="RA-T" style={{ background: "#efe6ff", paddingTop: "20px" }}>
      <Paper sx={{ borderRadius: "8px" }}>
        <TableContainer>
          <ToastComponent ref={toastRef} timeout={4000} />
          <Table>
            <TableHead>
              <TableRow>
              <Checkbox style={{margin:' 10px 20px 0px 20px'}}
  checked={selectedRows.length === rows.length && rows.length > 0} // Checked only if all rows are selected
  onChange={(event) => {
    if (event.target.checked) {
      setSelectedRows(rows); // Select all rows when checked
    } else {
      setSelectedRows([]); // Deselect all rows when unchecked
    }
  }}
/>             
                       {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: column.textAlign,
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {column.label === "VOUCHER TYPE" ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {column.label}
                      </div>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 1 ? "" : "#F1F1F1",
                  }}
                >
                  {/* Checkbox Column */}
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    {index != editRowIndex && (
                      <Checkbox
                        checked={selectedRows.includes(row)}
                        onChange={() => handleSelectedRows(row)}
                      />
                    )}
                  </TableCell>
                  {/* Existing Columns */}
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    
                     { row?.ClientName}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="Code"
                        value={editedRow.Code}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.Code
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      textAlign: "left",
                      cursor: isEditable ? "pointer" : "default",
                    }}
                  >
                    {
                      activityList?.filter(
                        (act) => act.AL_ID === row.ActivityListId
                      )[0]?.AL_Name
                    }
                    {editRowIndex === index && (
                      <ArrowDropDownIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                          marginLeft: "50px",
                        }}
                        onClick={
                          isEditable ? (e) => handleClick(e, index) : null
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      textAlign: "center",
                    }}
                  >
                    <Typography name="StdTime">{row.StdTime}</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="AjdTime"
                        value={editedRow.AjdTime}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.AjdTime||0
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      textAlign: "center",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="TotalTime"
                        value={
                          (parseFloat(editedRow.StdTime)||0) +
                          (parseFloat(editedRow.AjdTime)||0)
                        }
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      (parseFloat(row.StdTime)||0) +
                      (parseFloat(row.AjdTime)||0)                  
                        )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="ExtraTimeRemarks"
                        value={editedRow.ExtraTimeRemarks}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.ExtraTimeRemarks
                    )}
                  </TableCell>

                  {/* New Columns */}

                  <TableCell
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      textAlign: "left",
                      cursor: isEditable ? "pointer" : "default",
                    }}
                  >
                    {errors?.filter((err) => err.StdId == row.Error_SEID)[0]
                      ?.StdName || "Select Error Type"}
                    {editRowIndex === index && (
                      <ArrowDropDownIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                          marginLeft: "50px",
                        }}
                        onClick={
                          isEditable ? (e) => handleClickError(e, index) : null
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="ErrorRemarks"
                        value={editedRow.ErrorRemarks}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.ErrorRemarks
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="ReviewCount"
                        value={editedRow.ReviewCount}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.ReviewCount
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="ErrorCount"
                        value={parseFloat(editedRow.Error_SEID)>0?1:0}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      parseFloat(row.Error_SEID)>0?1:0
                    )}
                  </TableCell>

                  {/* Edit Column */}
                  <TableCell align="center">
                    {isEditable &&
                      (editRowIndex === index ? (
                        <IconButton
                          aria-label="save"
                          onClick={() => handleSaveClick(index)}
                        >
                          <SaveIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditClick(index)}
                        >
                          <EditIcon color="red" />
                        </IconButton>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {activityList.map((activity) => (
                  <MenuItem
                    key={activity.AL_ID}
                    onClick={() => handleSelect(activity.AL_ID)}
                  >
                    <Checkbox
                      checked={
                        rows[currentRow]?.ActivityListId === activity.AL_ID
                      }
                    />
                    {activity.AL_Name}
                  </MenuItem>
                ))}
              </Menu>

              <Menu
                anchorEl={anchorElError}
                open={Boolean(anchorElError)}
                onClose={handleClose}
              >
                {errors?.map((err) => (
                  <MenuItem
                    key={err.StdId}
                    onClick={() => handleSelectError(err.StdId)}
                  >
                    <Checkbox
                      checked={rows[currentRow]?.Error_SEID === err.StdId}
                    />
                    {err.StdName}
                  </MenuItem>
                ))}
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <div
        className="bottm-approve-select-container"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          padding: "20px",
          color: "#000000",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          textAlign: "left",
        }}
      >
        <div
          className="bottm-approve-select-container-button "
          style={{
            background: "#50145a",
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "6px",
            paddingBottom: "6px",
            borderRadius: "8px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => {
            approveData();
          }}
        >
          Approve
        </div>
      </div>
    </div>
  );
}
