import React, { useState, useEffect, useContext, useRef } from "react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import NavSub from "../components/NavSub";
import isGuestUser from "../utils/guestUser";

import ToastComponent from "../components/ToastComponent";
import { clientApiUrl, taskApiUrl, reportApiUrl } from "../utils/GetUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/VoucherReports.css";
import {
  CaretCircleDoubleLeft,
  CaretCircleDoubleRight,
  FileArrowUp,
  FunnelSimple,
} from "phosphor-react";
import * as XLSX from "xlsx";
const VoucherReports = () => {
  // /////////////////////////////////////////////
  const [query, setQuery] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const debounceTimeout = useRef(null);

  const fetchSuggestionByText = async () => {
    if (!token) {
      console.error("Token is missing.");
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        console.error("Failed to fetch suggestions:", response.statusText);
        setLoading(false);
        return;
      }
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);

      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);

      setLoading(false);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const handleCustomersearchClick = () => {
    // return;

    setIsSearchOpen(!isSearchOpen);
  };

  const handleClearAllSearch = () => {
    setQuery("");
    setPage(1);
    setSelectedSuggestions([]);
  };
  const handleCheckboxChange = (suggestion) => {
    if (selectedSuggestions?.length === 0) {
      setPage(1);
    }
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected?.some((item) => item.Id === suggestion.Id)) {
        return prevSelected?.filter((item) => item.Id !== suggestion.Id);
      } else {
        return [suggestion];
      }
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsSearchOpen(false);
      }
    };

    const handleScroll = () => {
      setIsSearchOpen(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // /////////////////////////////////////////////////////////////////////////////

  const {
    teamsData,
    setTeamsData,
    teamNames,
    setTeamNames,
    usersList,
    fetchTeams,
  } = useContext(UserContext);
  const { token, logout, handleAuthExpiry, userData } = useContext(AuthContext);
  console.log(userData)
  // const [selectedDate, setSelectedDate] = useState('');
  const [chosenDate, setChosenDate] = useState("");
  const [teams, setTeams] = useState(
    teamNames?.filter(
      (team) =>
        team?.superTeamId === 0 && team?.type === "T" && team?.status === true
    )
  ); 
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] = useState();
  const [selectedPrepareName, setSelectedPrepareName] = useState(userData?.[0]);
  const [accSearch, setAccSearch] = useState("");
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState(
    []
  );
  const dropdownsearchRef = useRef(null);
  const { toastRef } = useRef();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([userData?.[0]]);
  const [allActivities, setAllActivities] = useState([]);

  const formatDateToYYYYMM = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    return `${year}-${month}`;
  };

  const todaysDate = new Date();
  const [selectedYear, setSelectedYear] = useState(todaysDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    formatDateToYYYYMM(todaysDate)
  );

  const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());
  const [isTableLoaded, setIsTableLoaded] = useState(false);


    useEffect(()=>{
      console.log([userData?.[0]])
      setSelectedEmployees([userData?.[0]])
    }, [userData])


  const handleCheckboxAccountResponsibleChange = (acc) => {
    console.log(acc);
    setSelectedAccountResponsible([acc]);
    setSelectedTeams([]);
    setSelectedEmployees([acc]);
    setSelectedPrepareName(acc);
  };

  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList]);

  const toggleTeamSelector = () => {
    // setTeamSelectorOpen((prev) => !prev);
  };

  const handleAccountingResponsibleClick = () => {
    // setSelectedOption({});
    // setShowOptions(true);
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };

  const handleChange = (event) => {
    console.log(selectedEmployees);
    setSelectedPrepareName();
    //console.log(event.target.value);
    if (event.target.value?.[event.target.value?.length - 1] === undefined) {
      //console.log("mmmmmmmmmmmmmmmm");
      setSelectedTeams([]);
      return;
    }
    setSelectedTeams(event.target.value);
  };
  const handleClearAll = (event) => {
    event.preventDefault();
    console.log(selectedEmployees);
    setSelectedTeams([]);
  };
  useEffect(() => {
    //console.log(selectedTeams.length);
    if (!selectedTeams?.length) {
      //console.log("this");
      setSelectedEmployees([userData?.[0]]);
    }
  }, [selectedTeams, token]);

  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
    setSelectedEmployees(filteredEmployees);

    console.log(filteredEmployees);
    console.log(selectedEmployees);
  }, [selectedTeams, usersList, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsAccountResponsibleOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownsearchRef]);

  const [expanded, setExpanded] = useState(true);
  const [projectData, setProjectData] = useState("");
  const fetchProjectList = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllProjectList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setProjectData(data);
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      fetchProjectList(token);
    }
  }, [token]);

  const fetchAllActivities = async () => {
    try {
      // setLoadingActivities(true); //
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        console.log(
          data
            ?.filter(
              (act) => act?.AL_Type === "Vouchers" && act?.AL_Status == "1"
            )
            .map((a) => a.AL_SalseforceCode)
        );
        setAllActivities(
          data?.filter(
            (act) => act?.AL_Type === "Vouchers" && act?.AL_Status == "1"
          )
        );
        return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoadingActivities(false); // End loading
    }
  };

  useEffect(() => {
    if (token) {
      fetchAllActivities();
    }
  }, [token]);
  //fetching data
  const [page, setPage] = useState(1);
  const [masterData, setMasterData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);
  const fetchInProgress = useRef(false);

  const formatSelectedEmployees = (selected) => {
    console.log(selected)
    if(!selected?.[0]?.EM_ID)return 
    console.log('sel', selectedEmployees)
    const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
    console.log(emIds?.join(","))
    return emIds?.join(",");
  };
  useEffect(() => {
    console.log(selectedEmployees);
  }, [selectedEmployees, selectedTeams, token]);

  const fetchCustomer = async (dateStart, dateEnd, first) => {
    // debugger
    const clientId = selectedSuggestions?.[0]?.Id;
    if (!allActivities?.length) return;
    console.log(selectedEmployees);
    // if(!selectedEmployees?.length){
    //   console.log('Select Employees'); return;
    // }
    console.log(loading, end, fetchInProgress);
    if (loading || fetchInProgress.current) return;
    console.log("calll");
    fetchInProgress.current = true; // Set the flag to true
    setLoading(true);
    console.log("Fetching data for page:", page);
    const date = formatDate(selectedDay, selectedMonth, selectedYear);
    try {
      setIsTableLoaded(false);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${
          first ? 1 : page
        }&ItemsPerPage=${
          clientId ? 1 : 20
        }&CreatDate=${date}${
          clientId
            ? `&id=${clientId}` 
            : selectedEmployees.length > 0 || selectedPrepareName
        ?`&SubOwnerEMIDs=${
          selectedEmployees.length > 0
            ? formatSelectedEmployees(selectedEmployees)
            : formatSelectedEmployees([userData?.[0]])
                  }`
                  :""
        }`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          setEnd(true);
        } else {
          const formattedIds = data.map((d) => `'${d?.Id}'`).join(", ");
          await fetchedReportsPerClient(data, formattedIds, dateStart, dateEnd);
        }
      } else {
        setLoading(false);
        setEnd(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);

      fetchInProgress.current = false; // Reset the flag
    }
  };
  const fetchedReportsPerClient = async (
    clientData,
    ids,
    dateStart,
    dateEnd
  ) => {
    if (!ids || !dateStart || !dateEnd) return;

    try {
      const response = await fetch(
        `${taskApiUrl}GetTaskTotalTime?ClientIds=${ids}&DateRange1=${dateStart}&DateRange2=${dateEnd}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();

        const currentMergedData = clientData.map((client) => {
          const matchingReport = data.find(
            (report) => report.ClientId === client.Id
          );

          // Include all client properties first
          const mergedClient = { ...client };

          let total = 0; // Initialize total for the client

          // Dynamically add activity times to the client object
          allActivities.forEach((activity) => {
            const matchingReport = data.find(
              (report) =>
                report.ActivityId === activity.AL_ID &&
                report.ClientId === client.Id
            );

            // Add total time for this activity or default it to "0.00"
            const activityTime = matchingReport
              ? parseFloat(matchingReport.TotalTime)
              : 0;
            mergedClient[activity.AL_Name] = activityTime.toFixed(2);

            // Accumulate total time
            total += activityTime;
          });

          mergedClient.total = total.toFixed(2);

          return{
            ...mergedClient,
            ...(matchingReport),

          } 
        });

        console.log(currentMergedData);
        setMergedData((prevData) => [...prevData, ...currentMergedData]);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleScroll = debounce(() => {
    // if (!isTableLoaded || loading || end) return;

    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setPage((p) => p + 1);
    }
  }, 1000);

  const isInitialRender = useRef(true);

  const getStartAndEndDates = (monthString) => {
    console.log(monthString);
    const [year, month] = monthString?.split("-"); // Extract year and month from "YYYY-MM"
    const firstDay = `${year}-${month}-01`;

    // Calculate the last day of the month
    const lastDay = new Date(year, parseInt(month, 10), 0).getDate();
    const lastDateString = `${year}-${month}-${lastDay
      .toString()
      .padStart(2, "0")}`; // Format as YYYY-MM-DD

    return { firstDay, lastDay: lastDateString };
  };

  useEffect(() => {
    // const monthString = `${(selectedMonth + 1)
    //   .toString()
    //   .padStart(2, "0")}`; // Format as "YYYY-MM"
    const monthString = selectedMonth;
    const { firstDay, lastDay } = getStartAndEndDates(monthString);

    fetchCustomer(firstDay, lastDay, false);
  }, [page, allActivities]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setEnd(false);
    setPage(1);
    setMergedData([]);
    setMasterData([]);

    const monthString = `${selectedMonth.toString().padStart(2, "0")}`; // Format as "YYYY-MM"

    const { firstDay, lastDay } = getStartAndEndDates(monthString);
    
    fetchCustomer(firstDay, lastDay, true);
  }, [selectedMonth, selectedEmployees, selectedSuggestions]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const formatDate = (day, month, year) => {
    const monthMapping = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const formattedMonth = monthMapping[month];
    const formattedDay = String(day).padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  const toggleExpanded = () => setExpanded((prev) => !prev);

  // ////////////////////////////////////////////////////
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date(); // Current date
    today.setDate(1); // Set to the first day of the current month
    return today.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  });

  const getUserFirstName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user ? user.EM_FirstName + " " + user.EM_LastName : "";
  };

  const getUserTeamName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user
      ? teamNames?.find((team) => team.id === parseFloat(user.STM_Team))
          ?.name || "Unknown Team"
      : "Unknown Team"; // Return team name or default to "Unknown Team"
  };

  const handleDownload = async () => {
    const monthString = selectedMonth;
    const { firstDay, lastDay } = getStartAndEndDates(monthString);

    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }

    try {
      const response = await fetch(`${reportApiUrl}/GetReportFile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DateRange1: firstDay,
          DateRange2: lastDay,
          ReportType: "Vouchers",
        }),
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const result = await response.json();
      if (result.Status) {
        const fileUrl = result.Result.URI;
        window.open(fileUrl, "_blank"); // Opens the URL in a new tab
      } else {
        console.error("Error in fetching report:", result);
      }
    } catch (error) {
      console.error("Error in fetch:", error);
    }
  };

  useEffect(() => {
    const tableContainer = document.querySelector(".scroll-voucherreports");

    if (tableContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        tableContainer.classList.add("active");
        startX = e.pageX - tableContainer.offsetLeft;
        scrollLeft = tableContainer.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseUp = () => {
        isDown = false;
        tableContainer.classList.remove("active");
      };

      const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - tableContainer.offsetLeft;
        const walk = (x - startX) * 2; // Adjust multiplier for speed
        tableContainer.scrollLeft = scrollLeft - walk;
      };

      tableContainer.addEventListener("mousedown", handleMouseDown);
      tableContainer.addEventListener("mouseleave", handleMouseLeave);
      tableContainer.addEventListener("mouseup", handleMouseUp);
      tableContainer.addEventListener("mousemove", handleMouseMove);

      return () => {
        tableContainer.removeEventListener("mousedown", handleMouseDown);
        tableContainer.removeEventListener("mouseleave", handleMouseLeave);
        tableContainer.removeEventListener("mouseup", handleMouseUp);
        tableContainer.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  return (
    <div>
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />

      <div
        style={{
          display: "flex",
          gap: "60px",
          padding: "20px 24px",
          width: "98%",
          margin: "1rem auto",
          background: "white",
          borderRadius: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "28px" }}>
          <FormControl className="select-dropdown-user">
            <InputLabel
              id="team-selector-label"
              className="select-dropdown-label"
              shrink={false}
              onClick={toggleTeamSelector}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }} // Ensures clickable area
            >
              SELECT TEAMS
            </InputLabel>

            <Select
              labelId="team-selector-label"
              id="team-selector"
              multiple
              value={selectedTeams}
              onChange={handleChange}
              renderValue={(selected) =>
                selected
                  .map((teamId) => {
                    const team = teams.find((team) => team.id === teamId);
                    return team?.Name;
                  })
                  .join(" ")
              }
              style={{ width: "150px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {selectedTeams?.length > 0 && (
                <MenuItem onClick={(e) => handleClearAll(e)}>
                  <ListItemText
                    style={{ color: "red" }}
                    primary={"Clear All"}
                  />
                </MenuItem>
              )}
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div
            className="Title-STR-main2"
            onClick={handleAccountingResponsibleClick}
          >
            {selectedPrepareName?.EM_FirstName +
              selectedPrepareName?.EM_LastName || "Preparer Name"}
            <FontAwesomeIcon
              className="str-caret-icon"
              icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}
            />
            {isAccountResponsibleOpen && (
              <div ref={dropdownsearchRef} className="search-drop-Report">
                <div className="search-drop-page">
                  <div className="main-suggestion">
                    <div className="input-suggestion">
                      <div className="icon-search-phospher">
                        <MagnifyingGlass
                          size={20}
                          color="#ada4a4"
                          weight="fill"
                        />
                      </div>
                      <input
                        type="text"
                        value={accSearch}
                        className="input-suggestion-search"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const value = e.target.value.toLowerCase();
                          setAccSearch(value);
                          setAccountResponsible2(
                            accountResponsible?.filter((acc) =>
                              (acc.EM_FirstName + " " + acc?.EM_LastName)
                                .toLowerCase()
                                .includes(value)
                            )
                          );
                        }}
                        placeholder="Start typing..."
                      />
                    </div>
                    <div tabIndex={1}>
                      <div
                        style={{ color: "red", padding: "2px 22px" }}
                        onClick={() => {
                          setSelectedPrepareName();
                          setSelectedAccountResponsible([]);
                          setSelectedEmployees([]);
                        }}
                      >
                        Clear All
                      </div>
                      {accountResponsible2?.map((acc) => (
                        <div key={acc.EM_ID} className="suggestion-list-li">
                          <input
                            type="radio"
                            className="radio-searchbox"
                            style={{
                              accentColor: "rgb(80, 20, 90)",
                              width: "18px",
                              height: "18px",
                            }}
                            checked={selectedAccountResponsible?.some(
                              (item) => item.EM_ID === acc.EM_ID
                            )}
                            onChange={() =>
                              handleCheckboxAccountResponsibleChange(acc)
                            }
                          />
                          <label className="li-content">
                            {acc.EM_FirstName + " " + acc.EM_LastName}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <input
              type="month"
              className="date-design-VoucherReport"
              label="Select Date"
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Nav-btn-user" onClick={handleDownload}>
          <FileArrowUp size={25} color="#3d025f" weight="fill" />
          <button className="Nav-Sub-btn">DOWNLOAD FILES</button>
        </div>
      </div>

      <div
        style={{
          width: "98%",
          margin: "1rem auto",
          padding: "20px 8px",
          overflow: "scroll",
        }}
        className="scroll-voucherreports"
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
            fontFamily: "Arial, sans-serif",
            border: "1px solid #ddd",
          }}
        >
          <thead>
            <tr>
              <th className="header-customername">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Customer Name
                  <FunnelSimple
                    className="icon-search-dropdown"
                    onClick={handleCustomersearchClick}
                    weight="bold"
                    size={20}
                    color="#061347"
                  />
                </div>
                {isSearchOpen && (
                  <div
                    ref={dropdownsearchRef}
                    className="search-drop-design-report"
                    style={{
                      marginLeft: "140px !important",
                      marginTop: "23px !important",
                    }}
                  >
                    <div className="search-drop-page">
                      <div className="main-suggestion">
                        <div className="input-suggestion">
                          <div className="icon-search-phospher">
                            <MagnifyingGlass
                              size={20}
                              color="#ada4a4"
                              weight="fill"
                            />{" "}
                          </div>

                          <input
                            type="text"
                            value={query}
                            className="input-suggestion-search"
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Start typing..."
                          />

                          <button
                            className="clear-title"
                            onClick={handleClearAllSearch}
                          >
                            Clear All
                          </button>
                        </div>
                        {loading ? (
                          <p className="suggestion-title">
                            <CircularProgress
                              width={30}
                              style={{ color: "purple" }}
                            />
                          </p>
                        ) : (
                          <div style={{ maxHeight: "500px" }} tabIndex={1}>
                            {suggestions.map((suggestion) => (
                              <div
                                key={suggestion.Id}
                                className="suggestion-list-li"
                              >
                                <input
                                  type="checkbox"
                                  className="check-searchbox"
                                  checked={selectedSuggestions.some(
                                    (item) => item.Id === suggestion.Id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(suggestion)
                                  }
                                />
                                <label className="li-content">
                                  {suggestion.Name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </th>
              <th
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  position: "relative",
                  minWidth: "300px",
                  textAlign: "start",
                }}
              >
                {" "}
                Project Number
                <span
                  style={{
                    position: "absolute",
                    minWidth: "150px",
                    right: "-135px",
                    top: "16px",
                    color: "black",
                    cursor: "pointer",
                    zIndex: "999",
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  {" "}
                  {expanded ? (
                    <CaretCircleDoubleLeft size={32} color="#080808" />
                  ) : (
                    <CaretCircleDoubleRight size={32} color="#080808" />
                  )}
                </span>
              </th>
              {expanded && <th className="Expand-styles">ERP System</th>}
              {expanded && <th className="Expand-styles">Cost Center</th>}
              {expanded && <th className="Expand-styles">Department</th>}
              {expanded && <th className="Expand-styles">Department Head </th>}
              {expanded && (
                <th className="Expand-styles">View Account Owner</th>
              )}
              {expanded && <th className="Expand-styles">View Team Lead </th>}
              {expanded && (
                <th className="Expand-styles">
                  Synergy Accounting responsible
                </th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Quality Consultant </th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Process Lead</th>
              )}
              {expanded && (
                <th className="Expand-styles">Synergy Department Lead</th>
              )}
              {expanded && <th className="Expand-styles">Synergy Team</th>}
              {expanded && (
                <th className="Expand-styles">Frequency Voucher Processing</th>
              )}
              {expanded && (
                <th className="Expand-styles">Bank Remittance Type</th>
              )}

              {allActivities?.map((ac) => {
                return <th className="last-portion-style"> {ac.AL_Name}</th>;
              })}
              <th className="last-portion-style">Total Time</th>
            </tr>
          </thead>
          <tbody>
            {mergedData?.map((data, index) => {

           {/* { mergedData
              ?.filter(i=>parseInt(i?.total)!=0)?. map((data, index) => { */}
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#F1F1F1" : "#FFFFFF", // Alternating row colors
                    }}
                  >
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {data?.Name}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {data?.ProjectNumber}
                    </td>
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {data?.ERPSystem}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {selectedSuggestions?.length > 0 ? data?.CostCenterName : data?.CostCenter}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {selectedSuggestions?.length > 0 ? data?.DepartmentName : data?.Department}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {data?.HODName}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {data?.OwnerEMId}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {data?.LeadId}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {getUserFirstName(data?.SubOwner)}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {getUserFirstName(data?.SubQC)}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {getUserFirstName(data?.SubProcLead)}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {getUserFirstName(data?.SubLead)}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {getUserTeamName(data?.SubOwner)}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {" "}
                        {data?.ReportingFrequencyName}
                      </td>
                    )}
                    {expanded && (
                      <td
                        style={{
                          padding: "15px",
                          border: "1px solid #fff",
                          fontFamily: "Poppins",
                          color: "#898989",
                        }}
                      >
                        {data?.RemittanceTypeName}
                      </td>
                    )}
                    {allActivities.map((activity) => (
                      <td
                        key={activity.AL_ID}
                        style={{ fontFamily: "Poppins" }}
                      >
                        {data?.[activity.AL_Name] || "0.00"}
                      </td>
                    ))}
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {data?.total}
                    </td>
                  </tr>
                  // ))}
                );
              })
             }
          </tbody>
        </table>

       {loading && (
                         <div
                           style={{
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center",
                             padding: "20px",
                           }}
                         >
                           <CircularProgress style={{ color: "purple" }} width={40} />
                         </div>
                       )}
        {end && <div>No more Data...</div>}
      </div>
    </div>
  );
};

export default VoucherReports;
