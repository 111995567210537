import React, { useContext, useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "./ToastComponent";
import  isGuestUser  from '../utils/guestUser';
function ExcelUpload() {
  const [file, setFile] = useState(null);
  const { token, logout, userData  } = useContext(AuthContext);

  const toastRef = useRef();
  const handleFileChange = (e) => {
    // //console.log('File selected:', e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  const handleExcelSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      //console.log("No file selected");
      return;
    }

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }

    try {
      const data = await file.arrayBuffer();

      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length === 0 || jsonData[0].length === 0) {
        toastRef.current.showToast("No data found in the file",'error');
        return;
      }

      // Define and check required columns
      const requiredColumns = ["Column1", "Column2"]; // Add your required columns here
      const actualColumns = jsonData[0]; // Headers are in the first row

      const missingColumns = requiredColumns.filter(
        (col) => !actualColumns.includes(col)
      );
      const extraColumns = actualColumns.filter(
        (col) => !requiredColumns.includes(col)
      );

      if (missingColumns.length > 0) {
        alert("Missing columns:", missingColumns.join(", "));
        return;
      }

      if (extraColumns.length > 0) {
        alert("Extra columns found:", extraColumns.join(", "));
        return;
      }

      let isCorrectOrder = true;
      requiredColumns.forEach((col, index) => {
        if (actualColumns[index] !== col) {
          isCorrectOrder = false;
        }
      });

      if (!isCorrectOrder) {
        alert("Required columns are not in the correct order");
        return;
      }

      //console.log("All required columns are present and in the correct order");

      const apiUrl = "";
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message:
            "Required columns are present in the file and in the correct order.",
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      //console.log("Server response:", result);
    } catch (error) {
      console.error("Error during processing:", error);
    }
  };

  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />
      <form onSubmit={handleExcelSubmit}>
        <input type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
        {/* <button type="submit">Upload</button> */}
      </form>
    </div>
  );
}

export default ExcelUpload;
