import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clientApiUrl, reportApiUrl, taskApiUrl } from '../utils/GetUrl';
import { AuthContext } from '../context/AuthContext';
import "../Styles/reportdetails.css";
import NavSub from '../components/NavSub';
import { CaretLeft, Columns, MagnifyingGlass, PencilSimple } from 'phosphor-react';
import ToastComponent from '../components/ToastComponent';
import { Checkbox, CircularProgress, FormControl, InputLabel, ListItemText, Menu, MenuItem, Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../context/UserContext';

function ReportDetails() {
  const { reportId } = useParams();
  const [open, setOpen] = useState(false);
  const {  teamNames ,usersList} = useContext(UserContext);
  const teams = teamNames?.filter((team) => team?.superTeamId === 0 && team?.type === "T" && team?.status === true);
  const [reportDetail, setReportDetail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarWrapperRef = useRef(null);
  const[selectedEmployees,setSelectedEmployees]=useState("");
  const dropdownsearchRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
  const { token, userData } = useContext(AuthContext);
  const { handleAuthExpiry, logout } = useContext(AuthContext);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const navigate = useNavigate();
  const toastRef = useRef();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [allActivities, setAllActivities] = useState([]);
  const reportItemsColumns = ["Customer Name","Cost Center","ERPSystem","Project Code","Department","Account Responsible","Outsourcing Sub-Processor","Status","RegisteredTime","BillableTime"];
  const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [accSearch, setAccSearch] = useState("");

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  const fetchAllActivities = async () => {
    try {  const response = await fetch(`${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast("Unauthorised access. Please login again.","error");
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setAllActivities(data?.filter((act) => act?.AL_Type === "Vouchers" || act?.AL_Type === "Reporting Activites" || act?.AL_Type === "Control Point" && act?.AL_Status === "1"));
                return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  useEffect(() => {
    if (token) {
      fetchAllActivities();
    }
  }, [token]);
    const fetchReportDetails = async () => {
      if (!userData?.[0]?.EM_ID) return;

      setLoading(true);
      try {
        const response = await fetch(`${reportApiUrl}/GetAllCustomiseReport?CreatedBy=${userData?.[0]?.EM_FirstName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
            toastRef.current.showToast("Unauthorized access. Please log in again.","error");
            await logout();
            return;
          }
          const handleAuthError = handleAuthExpiry(response);
          if (handleAuthError === true) {
            toastRef.current.showToast("Your Session has expired", "error");
            logout();
            return false;
          }
        if (response.ok) {
          const data = await response.json();
          const report = data.find((report) => report.CustomiseReportId === parseInt(reportId));
          setReportDetail(report);
          setFormData(report);
        } else {
          console.error('Failed to fetch report details');
        }
      } catch (error) {
        console.error('Error fetching report details:', error);
        
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
    fetchReportDetails();
  }, [reportId, token, userData]);

  const handleBackReports = () => {
    navigate("/CustomisedReports");
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSave = async () => {
    // Combine selected columns into a comma-separated string
    const updatedColumns = Object.keys(selectedOptions)
      .filter((key) => selectedOptions[key])  // Filter only selected columns
      .join(',');  // Join them as a comma-separated string
   const subOwnerEMIDs = selectedEmployees.length > 0 ? formatSelectedEmployees(selectedEmployees) : '';
  const date = formatDate(selectedDay, selectedMonth, selectedYear);  // Assuming you have selected date fields
  
  // Concatenate SubOwnerEMIDs and date into the Filters field
  const filters = `${subOwnerEMIDs},${date}`;
    const updatedReport = {
      ...formData,
      LastUpdatedDate: new Date().toISOString(),
      CustomiseReportId: parseInt(formData.CustomiseReportId),
      Coulmns: updatedColumns,
      Filters: filters,  // Set the new 'Coulmns' field as the selected columns string
    };
  
    try {
      const response = await fetch(`${reportApiUrl}/updatecustomiseReport`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedReport),
      });
  
      if (response.status === 401) {
        toastRef.current.showToast("Unauthorized access. Please log in again.", "error");
        await logout();
        return;
      }
  
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your session has expired", "error");
        logout();
        return false;
      }
  
      if (response.ok) {
        toastRef.current.showToast("Report updated successfully", "success");
        setIsEditing(false);
        fetchReportDetails();
        setOpen(false);
        
      } else {
        toastRef.current.showToast("Failed to update report", "error");
      }
    } catch (error) {
      console.error('Error updating report:', error);
    }
  };
  
    useEffect(() => {
      if (reportDetail?.Coulmns) {
        const initialColumns = reportDetail.Coulmns.split(',').reduce((acc, column) => {
          acc[column.trim()] = true;
          return acc;
        }, {});
        setSelectedOptions(initialColumns);
      }
    }, [reportDetail]);
 
  const handleClearAll = () => {
    setSelectedOptions({});
  };
  const handleCustomizeClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor for the dropdown
    setOpen(true); // Open the dropdown
  };
  
  const handleClose = () => {
    // setOpen(false);
    setAnchorEl(null);
  };
  const handleApply = () => {
    const selectedColumns = Object.keys(selectedOptions).filter(
      (column) => selectedOptions[column]
    );
    // You can now filter or adjust the table columns based on selectedColumns
    // For example, you can update the displayed columns by passing them to a table render function
  };
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState([]);
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] =useState();
  const [selectedPrepareName, setSelectedPrepareName] = useState(null);
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
  const toggleTeamSelector = () => {
  };
  const handleAccountingResponsibleClick = () => {
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };
    const handleCheckboxAccountResponsibleChange = (acc) => {
    console.log(acc);
    setSelectedAccountResponsible([acc]);
    if(selectedTeams?.length){
      setSelectedTeams([]);
    }
    setSelectedEmployees([acc]);
    setSelectedPrepareName(acc);
  }
  const handlePreviousYear = () => {
    setSelectedYear((prevYear) => prevYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear((prevYear) => prevYear + 1);
  };
  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    setIsCalendarOpen(false); 
  };
  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList])
  const handleTeamChange = (event) => {
    if (event.target.value?.length === 0) {
      setSelectedTeams([]); // Only clear if no team is selected
    } else {
      setSelectedTeams(event.target.value); // Update with the new selected teams
    }
  };
  
  const handleClearAllTeam = (event) => {
    event.preventDefault(); // Prevent the default action
    setSelectedTeams([]); // Clear the selected teams only
  };
  
  
  useEffect(() => {
    if (!selectedTeams?.length) {
      setSelectedEmployees(usersList);
    }
  }, [selectedTeams, token]);
  
  
  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
    setSelectedEmployees(filteredEmployees);

    console.log(filteredEmployees);
    console.log(selectedEmployees);
  }, [selectedTeams, usersList, token]);
  const [selectedColumns, setSelectedColumns] = useState({}); // Object to track selected columns

  const handleColumnChange = (column) => {
    setSelectedColumns((prevSelected) => ({
      ...prevSelected,
      [column]: !prevSelected[column], // Toggle the selection state
    }));
  };
  
const [masterData, setMasterData] = useState([]);
const [mergedData, setMergedData] = useState([]);
const todaysDate = new Date();
const [isTableLoaded, setIsTableLoaded] = useState(false);
const fetchInProgress = useRef(false); 
const [columns, setColumns] = useState('');
const [filters, setFilters] = useState('');
const [shares, setShares] = useState('');
const [page, setPage] = useState(1);
const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());
const [end, setEnd] = useState(false);
const [expandedActivities, setExpandedActivities] = useState(true);

const formatDate = (day, month, year) => {
  const formattedMonth = String(month).padStart(2, "0"); // Ensure two-digit month
  const formattedDay = String(day).padStart(2, "0"); // Ensure two-digit day if needed
  return `${year}-${formattedMonth}-${formattedDay || "01"}`;
};
const formatSelectedEmployees = (selected) => {
  const emIds = selected?.map((item) => `'${item["EM_ID"]}'`);
  return emIds?.join(",");
};
const columnToDataKeyMap = {
  "Customer Name": "Name",
  "Cost Center": "CostCenter",
  "ERPSystem": "ERPSystem",
  "Project Code": "ProjectNumber",
  "Department": "Department",
  "Account Responsible": "SubOwner",
  "Outsourcing Sub-Processor": "OutsourcingSubprocessor",
  "Status": "Status",
  "RegisteredTime": "RegisteredTime",
  "BillableTime": "BillableTime"
};
const fetchCustomer = async (dateStart, dateEnd, first) => {
  if(!allActivities?.length) return;
  if (loading || fetchInProgress.current) return;
  fetchInProgress.current = true; // Set the flag to true
  setLoading(true);

  const date = formatDate(selectedDay, selectedMonth, selectedYear);

  try {
    setIsTableLoaded(false);
    const response = await fetch(
      `${clientApiUrl}/GetAllClient?Page=${first ? 1 : page}&ItemsPerPage=20&CreatDate=${date}${selectedEmployees.length > 0 || selectedPrepareName ? `&SubOwnerEMIDs=${formatSelectedEmployees(selectedEmployees)}` : ''}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 401) {
      toastRef.current.showToast("Unauthorized access. Please log in again.","error");
      await logout();
      return;
    }
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      toastRef.current.showToast("Your Session has expired", "error");
      logout();
      return false;
    }
    if (response.ok) {
      const data = await response.json();

      if (data.length === 0) {
        setEnd(true);
      } else {
        const formattedIds = data.map((d) => `'${d?.Id}'`).join(", ");
        await fetchAndMergeData(data, formattedIds, dateStart, dateEnd);
        fetchReportDetails();
      }
    } else {
      setLoading(false);
      setEnd(true);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
    fetchInProgress.current = false; 
  }
};

const fetchAndMergeData = async (clientData, ids, dateStart, dateEnd) => {
  if (!ids || !dateStart || !dateEnd) return;

  const startOfMonth = `${selectedYear}-${String(selectedMonth).padStart(2, "0")}-01`;
  const endOfMonthDate = new Date(selectedYear, selectedMonth, 0);
  const endOfMonth = `${endOfMonthDate.getFullYear()}-${String(
    endOfMonthDate.getMonth() + 1
  ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;

  try {
    const [controlPointResponse, reportingResponse, voucherResponse,timeResponse] = await Promise.all([
      fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Control%20Point`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Reporting%20Activities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${taskApiUrl}/GetTaskTotalTime?DateRange1=${startOfMonth}&DateRange2=${endOfMonth}&clientId=${ids}&ActivtyType=Vouchers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(
        `${taskApiUrl}/GetTime?ClientId=${ids}&DateRangeFrom=${dateStart}&DateRangeTo=${dateEnd}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    const [controlPointTasks, reportingTasks, voucherTasks,timeData] = await Promise.all([
      controlPointResponse.ok ? controlPointResponse.json() : [],
      reportingResponse.ok ? reportingResponse.json() : [],
      voucherResponse.ok ? voucherResponse.json() : [],
      timeResponse.ok ? timeResponse.json() : [],
    ]);

    const mergedData = clientData.map((client) => {
      const taskDataForClient = allActivities.map((activity) => {
        const controlPointTask = controlPointTasks.find(
          (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
        );
        const reportingTask = reportingTasks.find(
          (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
        );
        const voucherTask = voucherTasks.find(
          (task) => task.ActivityId === activity.AL_ID && task.ClientId === client.Id
        );

        let taskStatus = "Pending";
        let taskCompletionStatus = "";

        if (controlPointTask) {
          taskCompletionStatus = controlPointTask.TaskStatus === "R" ? "No" : "Yes";
          taskStatus = controlPointTask.TaskStatus;
        } else if (reportingTask) {
          taskCompletionStatus =
            reportingTask.TaskStatus === "C"
              ? "Completed"
              : reportingTask.TaskStatus === "A"
              ? "Applicable"
              : reportingTask.TaskStatus === "R"
              ? "Not Completed"
              : "Pending";
          taskStatus = reportingTask.TaskStatus;
        }else if (voucherTask) {
          const totalTime = parseFloat(voucherTask.TotalTime || "0");
          taskCompletionStatus = totalTime > 0 ? totalTime.toFixed(2) : "0.00";
          taskStatus = totalTime > 0 ? "" : "N/A"; // Show N/A if no total time
        }
        return {
          ActivityName: activity.AL_Name,
          ActivityId: activity.AL_ID,
          TaskStatus: taskStatus,
          TaskCompletionStatus: taskCompletionStatus,
        };
      });
      const clientTimeData = timeData.find((time) => time.ClientID === String(client.Id));
      const registeredTime = clientTimeData?.GetAllTime?.[0]?.RegisteredTime || "0.00";
      const billableTime = clientTimeData?.GetAllTime?.[0]?.BillableTime || "0.00";

      return {
        ...client,
        tasks: taskDataForClient,
        RegisteredTime: registeredTime,
        BillableTime: billableTime,
      };
    });
    setMergedData((prev) => [...prev, ...mergedData]);
    setIsTableLoaded(true);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


useEffect(() => {
const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
fetchCustomer(dateStart, dateEnd, false);
}, [page,allActivities]);
useEffect(() => {
if (isInitialRender.current) {
isInitialRender.current = false;
return;
}
setEnd(false);
setPage(1);
setMergedData([]);
setMasterData([]);
const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
fetchCustomer(dateStart, dateEnd, true);
}, [selectedDay, selectedMonth, selectedYear, selectedEmployees]);

const debounce = (func, delay) => {
let timeoutId;
return (...args) => {
if (timeoutId) {
  clearTimeout(timeoutId);
}
timeoutId = setTimeout(() => {
  func(...args);
}, delay);
};
};
const handleScroll = debounce(() => {
// if (!isTableLoaded || loading || end) return;

if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
setPage((p) => p + 1);
}
}, 1000);
const isInitialRender = useRef(true);

useEffect(() => {
const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
fetchCustomer(dateStart, dateEnd, false);
}, [page, allActivities]);
useEffect(() => {
if (isInitialRender.current) {
isInitialRender.current = false;
return;
}

setEnd(false);
setPage(1);
setMergedData([]);
setMasterData([]);
// new Promise(resolve => setTimeout(resolve, 2000));

const dateStart = formatDate(selectedDay, selectedMonth, selectedYear);
const dateEnd = formatDate(selectedDay, selectedMonth, selectedYear);
fetchCustomer(dateStart, dateEnd, true);
}, [selectedDay, selectedMonth, selectedYear, selectedEmployees]);

useEffect(() => {
window.addEventListener("scroll", handleScroll);
return () => window.removeEventListener("scroll", handleScroll);
}, []);
const handleCheckboxChange = (item) => {
  setSelectedOptions((prevState) => ({
    ...prevState,
    [item]: !prevState[item], // Toggle the selected option
  }));
};

  return (
    <div>
      <NavSub />
      <div className='ReportDetails_layout'>
      <ToastComponent ref={toastRef} timeout={10000} />
        <div className='LookUp_head_container' style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
            <CaretLeft onClick={handleBackReports} size={24} style={{cursor:"pointer"}}/> Report Details
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
            {!isEditing && <PencilSimple onClick={handleEditClick} size={24}  style={{cursor:"pointer"}}/>}
          </div>
        </div>

        {loading ? (
          <div className="loading-indicator"><CircularProgress sx={{ color: '#50145A' }} /></div>        ) : reportDetail ? (
          <div>
         <table className="styled-table">
  <thead>
    <tr className="header-row">
      <th className="header-cell">Report Name</th>
      <th className="header-cell">Report Description</th>
      <th className="header-cell">Created By</th>
      <th className="header-cell">Report Type</th>
      <th className="header-cell">Created On</th>
    </tr>
  </thead>
    <tbody>
      {reportDetail && (
        <tr className="data-row">
          <td className="data-cell">
            {isEditing ? (
              <input
                type="text"
                name="ReportName"
                autoFocus
                value={formData.ReportName}
                onChange={handleInputChange}
                className="editable-field"
              />
            ) : (
              reportDetail.ReportName
            )}
          </td>
          <td className="data-cell">
            {isEditing ? (
              <input
                type="text"
                name="ReportDescription"
                value={formData.ReportDescription}
                onChange={handleInputChange}
                className="editable-field"
              />
            ) : (
              reportDetail.ReportDescription
            )}
          </td>
          <td className="data-cell">{reportDetail.CreatedBy}</td>
          <td className="data-cell">
            {isEditing ? (
              <input
                type="text"
                name="ReportType"
                value={formData.ReportType}
                onChange={handleInputChange}
                className="editable-field"
              />
            ) : (
              reportDetail.ReportType
            )}
          </td>
          <td className="data-cell">{new Date(reportDetail.CreatedDate).toLocaleDateString('en-GB')}</td>
        </tr>
      )}
    </tbody>
</table>
<div className="table_head_custom_add" style={{marginTop:"20px",marginBottom:"20px"}}>
<div className='individual_btn_cust'>
          <FormControl className="select-dropdown-user">
  <InputLabel
    id="team-selector-label"
    className="select-dropdown-label"
    shrink={false}
    onClick={toggleTeamSelector}
    style={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }}
  >
    SELECT TEAMS
  </InputLabel>

  <Select
    labelId="team-selector-label"
    id="team-selector"
    multiple
    value={selectedTeams}
    onChange={handleTeamChange}
    renderValue={(selected) =>
      selected
        .map((teamId) => {
          const team = teams.find((team) => team.id === teamId);
          return team?.Name;
        })
        .join(" ")
    }
    style={{ width: "150px" }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 250,
        },
      },
    }}
  >
    {selectedTeams?.length > 0 && (
      <MenuItem onClick={(e) => handleClearAllTeam(e)}>
        <ListItemText style={{ color: "red" }} primary={"Clear All"} />
      </MenuItem>
    )}
    {teams.map((team) => (
      <MenuItem key={team.id} value={team.id}>
        <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
        <ListItemText primary={team.name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>


              <div className="Title-STR-main2" onClick={handleAccountingResponsibleClick}>
          {selectedPrepareName?.EM_FirstName + selectedPrepareName?.EM_LastName || "Preparer Name"}
          <FontAwesomeIcon
            className="str-caret-icon"
            icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}

          />
          {isAccountResponsibleOpen && (
            <div ref={dropdownsearchRef} className="search-drop-DailyVoucher">
              <div className="search-drop-page">
                <div className="main-suggestion">
                  <div className="input-suggestion">
                    <div className="icon-search-phospher">
                      <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />
                    </div>
                    <input
                      type="text"
                      value={accSearch}
                      className="input-suggestion-search"
                      onClick={(e) => e.stopPropagation()} 
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        setAccSearch(value);
                        setAccountResponsible2(
                          accountResponsible?.filter((acc) =>
                            (acc.EM_FirstName + ' ' + acc?.EM_LastName).toLowerCase().includes(value)
                          )
                        );
                      }}
                      placeholder="Start typing..."
                    />
                  </div>
                  <div tabIndex={1}>
                  <div style={{color:'#B00000', padding:'2px 22px'}} onClick={()=>{setSelectedPrepareName(); setSelectedAccountResponsible([]); setSelectedEmployees([])}}>
                      Clear All
                    </div>
                    {accountResponsible2?.map((acc) => (
                      <div key={acc.EM_ID} className="suggestion-list-li">
                        <input
                          type="radio"
                          className="radio-searchbox"
                          style={{
                            accentColor: 'rgb(80, 20, 90)',
                            width: '18px',
                            height: '18px',
                          }}
                          checked={selectedAccountResponsible?.some(
                            (item) => item.EM_ID === acc?.EM_ID  
                          )}
                          onChange={() =>
                            handleCheckboxAccountResponsibleChange(acc)
                          }
                        />
                        <label className="li-content">
                          {acc.EM_FirstName + ' ' + acc.EM_LastName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
        <div style={{display:"flex",justifyContent:"flex-end",gap:"24px"}}>
 <div className="calendar-picker" ref={calendarWrapperRef}>
      <button onClick={toggleCalendar} className="calendar-button">
        {`${months[selectedMonth - 1]} ${selectedYear}`}
      </button>
      {isCalendarOpen && (
        <div className="calendar-dropdown">
          <div className="year-selector">
            <button onClick={handlePreviousYear} className="year-nav">&lt;</button>
            <span className="year-display">{selectedYear}</span>
            <button onClick={handleNextYear} className="year-nav">&gt;</button>
          </div>

          <div className="month-grid">
            {months.map((month, index) => (
              <div
                key={index}
                className={`month-item ${index + 1 === selectedMonth ? "selected" : ""}`}
                onClick={() => handleMonthChange(index + 1)}
              >
                {month}
              </div>
            ))}
          </div>
          
        </div>
      )}
      
    </div>
 </div>
              
            </div>
<div className='individual_btn_cust'>
<div className='cust_common_btn' onClick={handleCustomizeClick}>Customize Report</div>
<Menu
  anchorEl={anchorEl}
  open={open}
  onClose={() => setOpen(false)}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
>
  <div className="dropdown-container">
    <div className="dropdown-section columns-section">
      <div className="dropdwon_coumns_head">
        <Columns size={24} />
        <div className="title_reprot_filter">Columns</div>
      </div>
      {reportItemsColumns.map((item, index) => (
        <MenuItem key={index} onClick={() => handleCheckboxChange(item)}>
          <Checkbox checked={!!selectedOptions[item]} />
          <ListItemText primary={item} />
        </MenuItem>
      ))}
      {allActivities.map((activity, index) => (
        <MenuItem key={`activity-${index}`} onClick={() => handleCheckboxChange(activity.AL_Name)}>
          <Checkbox checked={!!selectedOptions[activity.AL_Name]} />
          <ListItemText primary={activity.AL_Name} />
        </MenuItem>
      ))}
    </div>
  </div>
  <div className="dropdown-footer">
    <button className="common_btn_cust_repo_claer_all" onClick={handleClearAll}>Clear All</button>
    <button onClick={handleSave} className="common_btn_cust_repo">Apply</button>
  </div>
</Menu>
{isEditing && (
             <div className="ReportDeatils_button_field" style={{marginTop:"0px"}}>
                <button onClick={handleSave}  className="common_btn">Save</button>
              </div>
            )}
</div>
</div>
{reportDetail.Coulmns && (
  <div className="table-container-cust">
    <table className="custom-table">
      <thead>
        <tr className="table-header-cust">
          {/* Dynamically render column headers */}
          {["Customer Name", "Cost Center", "ERPSystem", "Project Code", "Department", "Account Responsible", "Outsourcing Sub-Processor", "Status", "RegisteredTime", "BillableTime"].map((column, index) => {
            if (selectedOptions[column]) {
              return (
                <th className="table-header-cell-cust" key={index}>
                  {column}
                </th>
              );
            }
            return null; // Skip if not selected
          })}

          {/* Render expanded activities headers if enabled */}
          {expandedActivities &&
            allActivities?.map((activity) => {
              if (selectedOptions[activity.AL_Name]) {
                return (
                  <th className="Expand-styles" style={{ minWidth: '250px', padding: "15px" }} key={activity.AL_ID}>
                    {activity.AL_Name} {/* Activity Name */}
                  </th>
                );
              }
              return null; // Skip if not selected
            })
          }
        </tr>
      </thead>

      <tbody>
        {mergedData.map((data, index) => (
          <tr key={index} className={index % 2 === 0 ? "table-row-white" : "table-row-alternate"}>
            {/* Dynamically render data for selected columns */}
            {["Customer Name", "Cost Center", "ERPSystem", "Project Code", "Department", "Account Responsible", "Outsourcing Sub-Processor", "Status", "RegisteredTime", "BillableTime"].map((column, colIndex) => {
              if (selectedOptions[column]) {
                // Retrieve the correct key from the map
                const dataKey = columnToDataKeyMap[column];
                return (
                  <td className="table-cell-cust" key={colIndex}>
                    {data[dataKey] || ''} {/* Render data for the column */}
                  </td>
                );
              }
              return null; // Skip if not selected
            })}

            {/* Render expanded activities data */}
            {expandedActivities &&
              allActivities
                ?.filter((activity) => selectedOptions[activity.AL_Name])
                .map((activity) => {
                  const task = data.tasks.find((t) => t.ActivityId === activity.AL_ID);
                  return (
                    <td key={activity.AL_ID} className="table-cell-cust" style={{ textAlign: "center" }}>
                      {task ? `${task.TaskCompletionStatus} (${task.TaskStatus})` : "0.00"}
                    </td>
                  );
                })
            }
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}
{loading && (<div className="loading-indicator"><CircularProgress sx={{ color: '#50145A' }} /></div>)}
{end && (<div className="no-more-data"><p>No data to load</p></div>)}
          </div>
        ) : (
          <p>No report found with the provided ID.</p>
        )}
      </div>
    </div>
  );
}

export default ReportDetails;
