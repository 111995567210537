import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import "../Styles/Dashboard.css";
import CancelIcon from "@mui/icons-material/Cancel";
import PauseIcon from "@mui/icons-material/Pause";
import isGuestUser from "../utils/guestUser";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import Button from "@mui/material/Button";
import ToastComponent from "./ToastComponent";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
function DashboardTopCopm() {
  const { token, userData } = useContext(AuthContext);
  const { handleAuthExpiry, logout } = useContext(AuthContext);
  const [notification, setNotification] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [filteredNotificationsMain, setFilteredNotificationsMani] = useState(
    []
  );

  const [open, setOpen] = React.useState(false);
  const [newNotificationTitle, setNewNotificationTitle] = useState("");
  const toastRef = useRef();
  const [greeting, setGreeting] = useState(getGreeting());
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userRole = userData?.[0]?.DR_Name;

  function getGreeting() {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 12) {
      return "GOOD MORNING !";
    } else if (hour < 16) {
      return "GOOD AFTERNOON !";
    } else {
      return "GOOD EVENING !";
    }
  }

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString("en-GB");
    return { date, time };
  };

  const [dateTime, setDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(getCurrentDateTime());
      setGreeting(getGreeting());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const dashboardNotification = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetDashboardNotificaiton`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNotification(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    dashboardNotification();
  }, []);

  useEffect(() => {
    setFilteredNotifications(notification);
    const filtered = notification.filter((item) => item.Status === true);
    setFilteredNotificationsMani(filtered);
  }, [notification]);

  const handleAddNotification = async (status) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const payload = {
        Title: newNotificationTitle,
        Status: status,
        CreateBy: userData?.[0]?.EM_FirstName || "Unknown",
      };

      const response = await fetch(`${clientApiUrl}/AddDashboardNotification`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to add notification");
      }

      const data = await response.json();
      setNotification((prevNotifications) => [...prevNotifications, data]);
      setNewNotificationTitle("");
      dashboardNotification();
      toastRef.current.showToast("Notification Added", "success");
    } catch (error) {
      console.error("Error adding notification:", error);
      toastRef.current.showToast("Failed to add notification", "error");
    }
  };

  const handleDeleteNotification = async (id) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/DeleteDashboardNotification?id=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to delete notification");
      }

      setNotification((prevNotifications) =>
        prevNotifications.filter((notification) => notification.Id !== id)
      );
      toastRef.current.showToast("Notification deeted", "success");
    } catch (error) {
      console.error("Error deleting notification:", error);
      toastRef.current.showToast("Error deleting notification", "error");
    }
  };

  const handleUpdateNotification = async (id) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const notificationToUpdate = notification.find(
        (notif) => notif.Id === id
      );
      if (!notificationToUpdate) return;

      const newStatus = !notificationToUpdate.Status;
      const payload = {
        Title: notificationToUpdate.Title,
        Status: newStatus,
        CreateBy: userData?.[0]?.EM_FirstName || "Unknown",
        Id: id,
      };

      //console.log("Updating notification with payload:", payload);

      const response = await fetch(
        `${clientApiUrl}/UpdateDashboardNotification`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        dashboardNotification();
        toastRef.current.showToast("Notification updated", "success");
      } else {
        throw new Error("Failed to update notification");
      }
    } catch (error) {
      console.error("Error updating notification:", error);
      toastRef.current.showToast("Error updating notification", "error");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 1100,
    width: "70%",
    height: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="darshboard-graph-subhead">
        <div className="d-t-c-w-dv">
          <div className="d-g-h1">
            Hi, {userData?.[0]?.EM_FirstName}{" "}
            <span className="tt-bb-ss">👋🏻</span>{" "}
          </div>
          <div className="d-t-c-w-dv2">{greeting}</div>
        </div>
        <div className="darshboard-graph-subhead-line1">
          <div className="darshboard-graph-subhead-line122">
            <div className="d-g-s-h-1">Live Updates </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4px"
              height="4px"
              viewBox="0 0 4 4"
              fill="none"
              className="dash-board-heading-dot"
            >
              <circle cx="2" cy="2" r="2" fill="#50145A" />
            </svg>
            <div className="d-g-s-h-2">{dateTime.date}</div>
            <div className="d-g-s-h-3">{dateTime.time}</div>
          </div>
          {userRole === "Admin" && (
            <div>
              <div className="broadcast-noti" onClick={handleOpen}>
                <AddIcon />
                <div> Add broadcast notification</div>
              </div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <div className="m-a-t-c-h">
                      <div className="m-a-t-c-h-nameing">ADD NOTIFICATIONS</div>
                      <div>
                        <CancelIcon
                          onClick={handleClose}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="m-a-t-c-s-h-1">
                      <div className="m-a-t-c-s-h-noti-1">
                        <div className="custom-input-wrapper">
                          <input
                            id="custom-input"
                            type="text"
                            className="custom-input-for-modal-admin-dash"
                            placeholder="The Notification is ...."
                            value={newNotificationTitle}
                            onChange={(e) =>
                              setNewNotificationTitle(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="modal-icons-div">
                        <Button
                          style={{
                            cursor: "pointer",

                            backgroundColor: "purple",
                            color: "white",
                            border:
                              notification.Status === true
                                ? "none"
                                : "2px solid purple",
                          }}
                          onClick={() => handleAddNotification(true)}
                        >
                          RUN
                        </Button>
                        <PauseIcon
                          onClick={() => handleAddNotification(false)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                    <div style={{ height: "300px", overflowY: "auto" }}>
                      {filteredNotifications.map((notification) => (
                        <div className="admin-noti-div">
                          <div key={notification.Id}>
                            <input
                              id={`custom-input-${notification.Id}`}
                              type="text"
                              className="custom-input-for-modal-admin-dash-2"
                              value={notification.Title}
                              readOnly
                            />
                            <Button
                              style={{
                                cursor:
                                  notification.Status === true
                                    ? "not-allowed"
                                    : "pointer",
                                marginLeft: "10px",
                                backgroundColor:
                                  notification.Status === true
                                    ? "purple"
                                    : "white",
                                color:
                                  notification.Status === true
                                    ? "white"
                                    : "purple",
                                border:
                                  notification.Status === true
                                    ? "none"
                                    : "2px solid purple",
                              }}
                              onClick={() =>
                                notification.Status === false &&
                                handleUpdateNotification(notification.Id)
                              }
                              disabled={notification.Status === true} // Disable if status is true
                            >
                              RUN
                            </Button>

                            <PauseIcon
                              onClick={() =>
                                notification.Status === true &&
                                handleUpdateNotification(notification.Id)
                              }
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                color:
                                  notification.Status === true
                                    ? "purple"
                                    : "rgba(128, 0, 128, 0.657)",
                              }}
                            />

                            <DeleteOutlineIcon
                              onClick={() =>
                                handleDeleteNotification(notification.Id)
                              }
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </div>
          )}
        </div>
        <div className="h-l-m-line" />
        <div className="d-g-s-h-4">
          <div className="ticker-container">
            <div className="ticker-wrap">
              <div className="ticker-move">
                {filteredNotificationsMain.map((data, index) => (
                  <div key={index} className="ticker-item bg">
                    <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                    {data.Title} <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTopCopm;
