import React, { useState, useEffect, useRef, useContext } from "react";
import NavSub from "../components/NavSub";
import "../Styles/SynergyBillMonth.css";
import { FileArrowUp, FunnelSimple, CaretDown, CaretUp } from "phosphor-react";
import { AuthContext } from "../context/AuthContext";
import { reportApiUrl } from "../utils/GetUrl";
import * as XLSX from "xlsx";
import { CircularProgress } from "@mui/material";

function SynergyBillMonth() {
  const currentYear = new Date().getFullYear(); // Get the current year
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const { token, logout } = useContext(AuthContext);
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Add event listener for outside clicks
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle option click and update the selected option

  //integration
  const [monthlyBill, setMonthlyBill] = useState({});

  useEffect(() => {
    const fetchMonthlyBillData = async (selectedYear) => {
      if (!selectedYear) return; // Check if selectedMonth is available

      const year = selectedYear;
      setLoading(true);

      try {
        const response = await fetch(
          `${reportApiUrl}/GetBillingByDepartment?year=${year}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMonthlyBill(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
      }
    };

    // Make sure `selectedMonth` is available here.
    if (selectedYear) {
      fetchMonthlyBillData(selectedYear);
    }
  }, [token, selectedYear]);
  useEffect(() => {
    console.log("Monthly Bill:", monthlyBill);
  }, [monthlyBill]);

  const result = Object.keys(monthlyBill?.Departments?.Departments || {}).map(
    (departmentName) => {
      const department = monthlyBill.Departments.Departments[departmentName];
      const departmentHead = department.DepartmentHead;

      // Initialize an object to accumulate the total values for each month
      const totalMonthlyData = {
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0,
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
      };

      // Iterate over all cost centers in the department and sum the monthly data
      Object.values(department.CostCenters || {}).forEach((costCenter) => {
        totalMonthlyData.January += costCenter.January || 0;
        totalMonthlyData.February += costCenter.February || 0;
        totalMonthlyData.March += costCenter.March || 0;
        totalMonthlyData.April += costCenter.April || 0;
        totalMonthlyData.May += costCenter.May || 0;
        totalMonthlyData.June += costCenter.June || 0;
        totalMonthlyData.July += costCenter.July || 0;
        totalMonthlyData.August += costCenter.August || 0;
        totalMonthlyData.September += costCenter.September || 0;
        totalMonthlyData.October += costCenter.October || 0;
        totalMonthlyData.November += costCenter.November || 0;
        totalMonthlyData.December += costCenter.December || 0;
      });

      return {
        departmentName,
        departmentHead,
        costCenters: [
          {
            costCenterName: "All Cost Centers",
            monthlyData: totalMonthlyData,
          },
        ],
      };
    }
  );
  const [isOpen, setIsOpen] = useState(false); // State to track dropdown open/close
  // Default selected year

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle the dropdown open/close state
  };

  const handleYearChange = (year) => {
    setSelectedYear(year); // Set selected year when user clicks on it
    setIsOpen(false); // Close the dropdown after selecting a year
  };

  const handleDownload = () => {
    const dataForExcel = result.map((department) => {
      const monthlyTotals = department.costCenters.reduce(
        (totals, costCenter) => {
          Object.keys(costCenter.monthlyData).forEach((month) => {
            totals[month] += costCenter.monthlyData[month];
          });
          return totals;
        },
        {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        }
      );

      return {
        "Department Name": department.departmentName,
        "Department Head": department.departmentHead,
        January: monthlyTotals.January.toFixed(2),
        February: monthlyTotals.February.toFixed(2),
        March: monthlyTotals.March.toFixed(2),
        April: monthlyTotals.April.toFixed(2),
        May: monthlyTotals.May.toFixed(2),
        June: monthlyTotals.June.toFixed(2),
        July: monthlyTotals.July.toFixed(2),
        August: monthlyTotals.August.toFixed(2),
        September: monthlyTotals.September.toFixed(2),
        October: monthlyTotals.October.toFixed(2),
        November: monthlyTotals.November.toFixed(2),
        December: monthlyTotals.December.toFixed(2),
      };
    });

    const grandTotals = dataForExcel.reduce(
      (totals, row) => {
        Object.keys(totals).forEach((key) => {
          if (key !== "Department Name" && key !== "Department Head") {
            totals[key] += parseFloat(row[key]);
          }
        });
        return totals;
      },
      {
        "Department Name": "Total",
        "Department Head": "",
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0,
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
      }
    );

    Object.keys(grandTotals).forEach((key) => {
      if (key !== "Department Name" && key !== "Department Head") {
        grandTotals[key] = grandTotals[key].toFixed(2);
      }
    });

    dataForExcel.push(grandTotals);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Monthly Data");

    XLSX.writeFile(workbook, `MonthlyBill_${selectedYear}.xlsx`);
  };

  return (
    <div>
      <NavSub />

      <div className="nra-top-copfor-col">
        <div className="nra-top-Bill">
          <div className="Nav-btn-user" onClick={handleDownload}>
            <FileArrowUp size={25} color="#3d025f" weight="fill" />
            <button className="Nav-Sub-btn">DOWNLOAD FILES</button>
          </div>

          <div className="synergy-monthly-bill-dd-main">
            <div
              className="synergy-monthly-bill-dd"
              onClick={handleToggleDropdown}
            >
              {`YEAR ${selectedYear}`}
              {isOpen ? <CaretUp weight="fill" /> : <CaretDown weight="fill" />}
            </div>
            {isOpen && (
              <div className="year-dropdown-list-syngergy-monthly-bill">
                {[...Array(11)].map((_, index) => {
                  const year = 2020 + index; // Generate years from 2024 to 2030
                  return (
                    <div
                      key={year}
                      className="year-dropdown-item"
                      onClick={() => handleYearChange(year)}
                    >
                      {year}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "98%",
          margin: "1rem auto",
          padding: "20px 8px",
          overflow: "auto",
          height: "80vh",
          zIndex: 1,
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <thead
            style={{
              position: "sticky" /* Makes the header sticky */,
              top: 0 /* Keeps it at the top of the table container */,
              backgroundColor: "#D9D9D9",
              zIndex: 1 /* Ensures the header stays on top of the body when scrolling */,
            }}
          >
            <tr>
              <th
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  padding: "20px",
                  minWidth: "250px",
                  textAlign: "justify",
                  display: "flex",
                  gap: "18px",
                  cursor: "pointer",
                }}
              >
                Department
              </th>

              <th
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  position: "relative",
                  minWidth: "300px",
                  textAlign: "justify",
                }}
              >
                View Group Department Head
              </th>
              <th className="Month-display">JAN</th>
              <th className="Month-display">FEB</th>
              <th className="Month-display">MAR</th>
              <th className="Month-display">APR</th>
              <th className="Month-display">MAY</th>
              <th className="Month-display">JUN</th>
              <th className="Month-display">JUL</th>
              <th className="Month-display">AUG</th>
              <th className="Month-display">SEP</th>
              <th className="Month-display">OCT</th>
              <th className="Month-display">NOV</th>
              <th className="Month-display">DEC</th>
            </tr>
          </thead>
          <tbody>
            {result.map((department, index) => (
              
              <tr
                key={department.departmentName}
                style={{
                  backgroundColor: index % 2 === 0 ? "#F1F1F1" : "#FFFFFF",
                }}
              >
                <td
                  style={{
                    padding: "15px",
                    fontFamily: "Poppins",
                    color: "#898989",
                  }}
                >
                  {department.departmentName}
                </td>
                <td
                  style={{
                    padding: "15px",
                    fontFamily: "Poppins",
                    color: "#898989",
                  }}
                >
                  {department.departmentHead}
                </td>
                {department.costCenters.map((costCenter, index) => (
                  <React.Fragment key={index}>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.January.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.February.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.March.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.April.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.May.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.June.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.July.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.August.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.September.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.October.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.November.toFixed(2)}
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        border: "1px solid #fff",
                        fontFamily: "Poppins",
                        color: "#898989",
                      }}
                    >
                      {costCenter.monthlyData.December.toFixed(2)}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
            {/* Totals Row */}
            <tr style={{ backgroundColor: "#50145A", fontWeight: "bold" }}>
              <td
                colSpan="2"
                style={{
                  padding: "15px",
                  border: "1px solid #fff",
                  fontFamily: "Poppins",
                  color: "#FFFFFF",
                }}
              >
                Total Hours
              </td>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month) => {
                const total = result.reduce((sum, department) => {
                  department.costCenters.forEach((costCenter) => {
                    sum += costCenter.monthlyData[month] || 0; // Add data for the current month, defaulting to 0 if not available
                  });
                  return sum;
                }, 0);
                return (
                  <td
                    style={{
                      padding: "15px",
                      border: "1px solid #fff",
                      fontFamily: "Poppins",
                      color: "#FFFFFF",
                    }}
                    key={month}
                  >
                    {total.toFixed(2)}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>

      {loading && (
               <div
                 style={{
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   padding: "20px",
                 }}
               >
                 <CircularProgress style={{ color: "purple" }} width={40} />
               </div>
             )}
             {end && <div>No more Data...</div>}
      </div>
    </div>
  );
}

export default SynergyBillMonth;
