import { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import isGuestUser from "../utils/guestUser";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [userData, setUserData] = useState();
  const [connectionIdBudgetForecast, setConnectionIdBudgetForecast] =
    useState(null);
  const user = userData?.[0];
  const [connectionIdMasterData, setConnectionIdMasterData] = useState(null);
  const [connectionIdViewForceData, setConnectionIdViewForceData] =
    useState(null);
  const toastRef = useRef();
  const [connectionId, setConnectionId] = useState(null);
  const [generalNotifications, setGeneralNotification] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [notificationsForBudgetForecast, setNotificationsForBudgetForecast] =
    useState([]);
  const [notificationsForMasterData, setNotificationsForMasterData] = useState(
    []
  );
  const [notificationsForViewForceData, setNotificationsForViewForceData] =
    useState([]);
  const navigate = useNavigate();
  const lastNotificationId = useRef(null);

  const getTeamsForCheck = async (token) => {
    if (!token) {
      return null;
    }
    try {
      const response = await fetch(`${userApiUrl}/GetTeams?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status == 401) {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const login = async (values) => {
    try {
      const response = await fetch(`${userApiUrl}/LoginUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password, // Corrected field name
        }),
      });
      const data = await response.json();
      localStorage.setItem("token", data?.Token);
      localStorage.setItem("userId", data?.Id);
      setToken(data?.Token);
      getUserInfo(data?.Id, data?.Token);
      return data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  function getMonthRange() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
      startDate: `${firstDay.getFullYear()}-${String(
        firstDay.getMonth() + 1
      ).padStart(2, "0")}-${String(firstDay.getDate()).padStart(2, "0")}`,
      endDate: `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`,
    };
  }

  const fetchQcPoints = async (uData, token) => {
    if (!token) {
      console.error("No token");
      return;
    }
    const response = await fetch(
      `${taskApiUrl}/GetControlPointByStatus?status=all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.status === 401) {
      navigate("/");
    }

    const data = await response.json();

    // Filter control points by responsible person and status
    const filteredQcPoints = data?.filter(
      (item) =>
        item?.controlResponsible === uData?.[0]?.DR_Name && item?.status === "C"
    );

    return filteredQcPoints;
  };

  // Checking for existing control points based on frequency
  const getControlPoints = async (id, role, token, frequency) => {
    console.log(role);
    if (!token) return;

    const dateRange = getMonthRange(); // Default to monthly, can be adjusted based on frequency

    const response = await fetch(
      `${taskApiUrl}/GetControlPointMapping?DateRange1=${dateRange.startDate}&DateRange2=${dateRange.endDate}&Emids='${id}'`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response?.status === 401) {
      navigate("/");
    }

    const data = await response.json();
    console.log(data);
    // Check if control points exist for the specific frequency
    if (data?.length > 0) {
      return data?.filter((i) => i.ControlResponsible == role);
    }

    return [];
  };

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
    // const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const createControlPoints = async (data, token) => {
    if (!token) return;
    const stripTime = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());

    // const today11 = stripTime(new Date());
    // const tomorrow = stripTime(new Date(today11));
    // tomorrow.setDate(today11.getDate() + 66);
    // const today = tomorrow;
    // console.log(today)
    const today = new Date();
    const currentWeek = getWeekNumber(today);
    const currentMonth = today.getMonth() + 1;
    const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;

    // Helper function to strip time from a date (normalize to midnight)

    // Fetch all control points and existing control points
    const getAllControlPoints = await fetchQcPoints(data, token);
    const controlResponsible = data?.[0]?.DR_Name;
    const existingControlPoints = await getControlPoints(
      data?.[0]?.EM_ID,
      controlResponsible,
      token,
      "Monthly"
    );

    // Filter valid control points
    const validControlPoints = getAllControlPoints?.filter(
      (point) =>
        point.status === "C" && point.controlResponsible === controlResponsible
    );

    // Create today's required control points based on frequency
    const todaysRequiredControlPoints = validControlPoints?.filter((point) => {
      const existingPoint = existingControlPoints?.find(
        (existing) => String(existing.ControlPointId) === String(point.id)
      );
      const createdDate = existingPoint
        ? new Date(existingPoint.CreatedDate)
        : null;
      const createdWeek = createdDate ? getWeekNumber(createdDate) : null;
      const createdMonth = createdDate ? createdDate.getMonth() + 1 : null;

      if (point.frequency === "Daily") {
        // Check if today's control point already exists
        const todayWithoutTime = stripTime(today);
        return !existingControlPoints?.some((existing) => {
          const existingDate = stripTime(new Date(existing.CreatedDate));
          return (
            String(existing.ControlPointId) === String(point.id) &&
            existingDate.getTime() === todayWithoutTime.getTime()
          );
        });
      }

      if (point.frequency === "Weekly") {
        // Check if control point exists for the current week
        return !existingControlPoints?.some((existing) => {
          const existingWeek = getWeekNumber(new Date(existing.CreatedDate));
          return (
            String(existing.ControlPointId) === String(point.id) &&
            existingWeek === currentWeek
          );
        });
      }

      if (point.frequency === "Bi-Monthly") {
        const currentDay = today.getDate();
        const isFirstHalfOfMonth = currentDay <= 15;
        const currentYear = today.getFullYear();

        return !existingControlPoints?.some((existing) => {
          const existingDate = new Date(existing.CreatedDate);
          const existingDay = existingDate.getDate();
          const existingYear = existingDate.getFullYear();
          const isExistingFirstHalf = existingDay <= 15;

          // Check if control point exists for the same half of the month and same year
          return (
            String(existing.ControlPointId) === String(point.id) &&
            isExistingFirstHalf === isFirstHalfOfMonth &&
            existingYear === currentYear
          );
        });
      }

      if (point.frequency === "Quarterly") {
        // Check if control point exists for the current quarter
        return !existingControlPoints?.some((existing) => {
          const existingDate = new Date(existing.CreatedDate);
          const existingQuarter = Math.floor(existingDate.getMonth() / 3) + 1;
          const existingYear = existingDate.getFullYear();
          return (
            String(existing.ControlPointId) === String(point.id) &&
            existingQuarter === currentQuarter &&
            existingYear === today.getFullYear()
          );
        });
      }

      if (point.frequency === "Monthly") {
        // Check if control point exists for the current month and year
        return !existingControlPoints?.some((existing) => {
          const existingDate = new Date(existing.CreatedDate);
          const existingMonth = existingDate.getMonth() + 1; // Months are 0-indexed in JS
          const existingYear = existingDate.getFullYear();
          return (
            String(existing.ControlPointId) === String(point.id) &&
            existingMonth === currentMonth &&
            existingYear === today.getFullYear()
          );
        });
      }

      return false;
    });

    console.log(
      "Today's required control points:",
      todaysRequiredControlPoints
    );
    todaysRequiredControlPoints?.forEach(async (point) => {
      await addControlPoint(data, point, token);
    });
  };

  // Utility function to get the week number of the year
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Utility function to add a control point
  const addControlPoint = async (data, point, token) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const response = await fetch(`${taskApiUrl}/AddcontrolPointMapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          EmpployeeId: data?.[0]?.EM_ID,
          ControlPointId: point?.id,
          Status: "P",
        }),
      });
      const res = await response.json();
      // console.log(res);
    } catch (e) {
      console.error(e);
    }
  };

  // Helper to get Fridays of a month
  function getFridaysOfMonth(year, month) {
    const fridays = [];
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, month, day);
      if (date.getDay() === 5) {
        // Friday
        fridays.push(date);
      }
    }
    return fridays; // 2nd and 4th Fridays are fridays[1] and fridays[3]
  }

  const getUserInfo = async (id, token) => {
    const check = await getTeamsForCheck(token);
    if (!check) {
      // localStorage.setItem("token", null);
      // localStorage.setItem("userId", null);
      return;
    }

    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp?EMID=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (handleAuthExpiry(response)) {
        logout();
        return false;
      }
      setUserData(data);
      // const controlPointsExist = await getControlPoints(
      //   data?.[0]?.EM_ID,
      //   token
      // );
      //console.log(controlPointsExist);
      const userId = data?.[0]?.EM_ID;

      createControlPoints(data, token);

      signalRConnectBudgetForecast();
      signalRConnectMasterData();
      signalRConnectViewForceData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const latestNotificationIdRef = useRef(0);

  const fetchGeneralNotifications = async () => {
    try {
      const userEmpID = user?.EM_ID;
      const response = await fetch(
        `${notificationApiUrl}/GetNotificationMessage?FirstCount=1&LastCount=10&Userid=${userEmpID}&sendto=${userEmpID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        logout();
        return;
      }

      if (response.ok) {
        const newNotifications = await response.json();

        if (!Array.isArray(newNotifications) || newNotifications.length === 0) {
          console.log("No new notifications or response is not an array.");
          return;
        }

        const latestNotificationId = newNotifications[0].id;
        const isNewNotification =
          latestNotificationId > latestNotificationIdRef.current;

        console.log("Fetched Notifications Data:", newNotifications);
        console.log("Latest Notification ID:", latestNotificationId);
        console.log(
          "Previously Stored Latest ID:",
          latestNotificationIdRef.current
        );

        if (isNewNotification) {
          setGeneralNotification((prevNotifications) => {
            const updatedNotifications = [
              ...newNotifications,
              ...prevNotifications,
            ];
            return updatedNotifications;
          });
          setUnreadNotifications(true);
          latestNotificationIdRef.current = latestNotificationId;
          console.log("New Notifications Detected");
        } else {
          console.log("No New Notifications");
        }
      } else {
        console.error("Failed to fetch notifications:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (user && token) {
      fetchGeneralNotifications();
      const intervalId = setInterval(fetchGeneralNotifications, 30 * 60 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [user, token]);

  const markNotificationsAsRead = () => {
    setUnreadNotifications(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    if (token) {
      setToken(token);
      getUserInfo(userId, token);
    }
  }, []);

  const handleAuthExpiry = (response) => {
    if (response?.status === 401) {
      return true;
    } else {
      return false;
    }
  };

  const logout = () => {
    setToken(null);
    navigate("/");
    localStorage.setItem("token", null);
    localStorage.setItem("userId", null);
  };

  function signalRConnectBudgetForecast() {
    setConnectionIdBudgetForecast(null);
    const hubUrl = `${notificationApiUrl}?hubName=budgetforcastHub`;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    setConnectionIdBudgetForecast(newConnection);
    //console.log(newConnection)
    //console.log(newConnection);
    newConnection
      .start()
      .then(() => {
        //console.log("SignalR Connected BudgetForecast!");
        newConnection.on("MessageReceived", (message) => {
          //console.log(message)
          setNotificationsForBudgetForecast((prevNotifications) => [
            ...prevNotifications,
            message,
          ]);
        });
      })
      .catch((error) => console.error("SignalR Connection Error:", error));

    newConnection.onclose(() => {
      //console.log("SignalR Connection Closed!");
      signalRConnectBudgetForecast();
    });

    return () => {
      newConnection.stop();
    };
  }

  function signalRConnectMasterData() {
    const hubUrl = `${notificationApiUrl}?hubName=masterdataHub`;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    setConnectionIdMasterData(newConnection);
    //console.log(newConnection)

    newConnection
      .start()
      .then(() => {
        //console.log("SignalR Connected Master! ");
        newConnection.on("MessageReceived", (message) => {
          //console.log(message);
          setNotificationsForMasterData((prevNotifications) => [
            ...prevNotifications,
            message,
          ]);
        });
      })
      .catch((error) => console.error("SignalR Connection Error:", error));

    newConnection.onclose(() => {
      //console.log("SignalR Connection Closed!");
      signalRConnectMasterData();
    });

    return () => {
      newConnection.stop();
    };
  }

  function signalRConnectViewForceData() {
    const hubUrl = `${notificationApiUrl}?hubName=viewForceDataHub`;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    setConnectionIdViewForceData(newConnection);
    //console.log(newConnection)

    newConnection
      .start()
      .then(() => {
        //console.log("SignalR Connected ViewForce!");
        newConnection.on("MessageReceived", (message) => {
          setNotificationsForViewForceData((prevNotifications) => [
            ...prevNotifications,
            message,
          ]);
        });
      })
      .catch((error) => console.error("SignalR Connection Error:", error));

    newConnection.onclose(() => {
      //console.log("SignalR Connection Closed!");
      signalRConnectViewForceData();
    });

    return () => {
      newConnection.stop();
    };
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        fetchGeneralNotifications,
        setUnreadNotifications,
        markNotificationsAsRead,
        unreadNotifications,
        generalNotifications,
        setGeneralNotification,
        unreadNotifications,
        token,
        userData,
        setUserData,
        getUserInfo,
        handleAuthExpiry,
        logout,
        connectionId,
        connectionIdBudgetForecast,
        connectionIdViewForceData,
        connectionIdMasterData,
        setConnectionIdViewForceData,
        setConnectionIdMasterData,
        setConnectionId,
        setConnectionIdBudgetForecast,
        notifications,
        setNotifications,
        notificationsForMasterData,
        setNotificationsForMasterData,
        notificationsForViewForceData,
        setNotificationsForViewForceData,
        notificationsForBudgetForecast,
        setNotificationsForBudgetForecast,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
