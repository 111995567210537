import React, { useContext, useRef, useState } from "react";
import "../Styles/HampiReset.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, IconButton, Switch, TextField } from "@mui/material";
import logo1 from "../Images/MainLogo.png";
import { Info } from "@phosphor-icons/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../components/ToastComponent";
import isGuestUser from "../utils/guestUser";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
// import MuiTooltip from '../Component/Tool_tip1';

const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);
const validationSchema = yup.object({
  NewPassword: yup
    .string("Enter your password")
    .matches(passwordRegexp, "Password is not valid")
    .required("Password is required"),
  ConfirmNewPassword: yup
    .string("Enter your password")
    .oneOf([yup.ref("NewPassword"), null], "Passwords must match")
    .required("Password is required"),
});

export default function HampiReset() {
  const [loading, setLoading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
  const { userData, token } = useContext(AuthContext);
  const { handleAuthExpiry, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = userData?.[0];
  const toastRef = useRef();
  //console.log(token);

  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      ConfirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      // setLoading(true);
      //console.log(formik.values);
      handleResetPassword(formik.values);
    },
  });

  const handleResetPassword = async (values) => {
    setLoading(true);

    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const response = await fetch(`${userApiUrl}/UpdateEmp`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          UserID: user?.EM_ID,
          employeeId: user?.EM_EmpID,
          firstName: user?.EM_FirstName,
          lastName: user?.EM_LastName,
          joiningDate: user?.EM_JoiningDate,
          // "terminationDate": user?.EM_TerminationDate,
          email: user?.EM_EmailId,
          passWord: values.NewPassword,
          designationId: parseFloat(user?.EM_Designation),
          departmentId: parseFloat(user?.EM_Department),
          ModifiedById: user?.EM_ID,
          roleId: user?.DRM_ID ? user.DRM_ID.split(",").map(Number) : [],
          teamId: parseFloat(user?.STM_Team),
          subTeamId: parseFloat(user?.STM_Subteam),
          qualityConsultantId: user?.STM_QC,
          processLeadId: user?.STM_PL,
          departmentLeadId: user?.STM_DM_Head,
          managerId: user?.STM_Manager,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      setLoading(false);
      //console.log(data);
      toastRef.current.showToast("Password Reset  Successfully", "success");
      await new Promise((resolve) => setTimeout(resolve, 2000));
      navigate("/dashboard");
    } catch (error) {
      console.error("Error resetting password:", error.message); // Log the error message
      toastRef.current.showToast(
        "Error resetting Password. Please try Again",
        "error"
      );
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [passwordEntered1, setPasswordEntered1] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPasswordEntered(value !== "");
  };
  const handlePasswordChange1 = (event) => {
    const value = event.target.value;
    setPasswordEntered1(value !== "");
  };
  const toggleInfoMenu = () => {
    setIsInfoMenuOpen(!isInfoMenuOpen);
  };

  const closeInfoMenu = () => {
    setIsInfoMenuOpen(false);
  };

  return (
    <div className="main-l">
      <ToastComponent ref={toastRef} timeout={4000} />

      <div className="Container-r">
        <div>
          <div className="img11">
            <img className="img-logo1" loading="lazy" src={logo1} alt="" />
          </div>
          <div className="title1">Reset Password</div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="main-user-info1">
            <div className="user-input-box1">
              New Password
              <button onClick={toggleInfoMenu}>
                <Info size={20} weight="duotone" />
              </button>
            </div>
            {isInfoMenuOpen && (
              <div className="overlay" onClick={closeInfoMenu}>
                <div className="info-menu">
                  <ul>
                    <li>Use Atleast 8 Characters.</li>
                    <li>Use at least one uppercase.</li>
                    <li>
                      Use a mix of letters, numbers and atleast one special
                      character.
                    </li>
                  </ul>
                </div>
              </div>
            )}

            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              name="NewPassword"
              className="form-control1"
              placeholder="Enter New Password"
              value={formik.values.NewPassword}
              onChange={(event) => {
                formik.handleChange(event);
                handlePasswordChange(event);
              }}
              error={
                formik.touched.NewPassword && Boolean(formik.errors.NewPassword)
              }
              helperText={
                formik.touched.NewPassword && formik.errors.NewPassword
              }
              InputProps={{
                endAdornment: passwordEntered && (
                  <IconButton onClick={handleTogglePassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />

            <div className="user-input-box2">Confirm New Password</div>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              type={showPassword1 ? "text" : "password"}
              name="ConfirmNewPassword"
              className="form-control1"
              placeholder="Re-Enter Password"
              value={formik.values.ConfirmNewPassword}
              onChange={(event) => {
                formik.handleChange(event);
                handlePasswordChange1(event);
              }}
              error={
                formik.touched.ConfirmNewPassword &&
                Boolean(formik.errors.ConfirmNewPassword)
              }
              helperText={
                formik.touched.ConfirmNewPassword &&
                formik.errors.ConfirmNewPassword
              }
              InputProps={{
                endAdornment: passwordEntered1 && (
                  <IconButton onClick={handleTogglePassword1}>
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </div>
          {loading ? (
            <div className="input-field">
              <CircularProgress />
            </div>
          ) : (
            <div className="input-field">
              <div className="btn">
                <button type="submit">Reset</button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

// export default Hampi_Reset
