import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/Budget.css";
import NavSub from "../components/NavSub";
import {
  ArrowLeft,
  CaretDown,
  ClockCounterClockwise,
  Student,
  Download,
  PencilSimpleLine,
  XCircle,
} from "@phosphor-icons/react";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { CaretLeft } from "phosphor-react";
import { useNotifications } from "../context/NotificationContext";
import { userApiUrl } from "../utils/GetUrl";
import { UserContext } from "../context/UserContext";
import  isGuestUser  from '../utils/guestUser';
function BudgetForeCast(props) {
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [latestSelectedYear, setLatestSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  const { postNotification, fetchNotifications } = useNotifications();
  const [groupedData, setGroupedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyDatamodel, setHistoryDatamodel] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedHistoryData, setSelectedHistoryData] = useState(null);
  const [isArrowVisible, setIsArrowVisible] = useState(false);
  const [showLatestBudget, setShowLatestBudget] = useState(false);
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [months, setMonths] = useState([]);
  const [dataMessage, setDataMessage] = useState("");
  const { notificationsForBudgetForecast, setNotificationsForBudgetForecast } =
    useContext(AuthContext);
  const { token, connectionIdBudgetForecast, connectionId, logout } =
    useContext(AuthContext);
  const toastRef = useRef();
  const modalRef = useRef(null);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const showArrow = () => setIsArrowVisible(true);
  const { userData } = useContext(AuthContext);
  const {  usersList,teamNames } = useContext(UserContext);
  const user = userData?.[0];
  const [latestData, setLatestData] = useState([]);
  const updateData = async (updatedRow) => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      const response = await fetch(`${userApiUrl}/UpdateBudgetManual`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
      
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      
      if (!response.ok) {
        throw new Error("Failed to update budget data");
      }
      
      const result = await response.json();
      toastRef.current.showToast("Data updated successfully", "success");
      fetchHistoryData(latestSelectedYear);
    } catch (error) {
      console.error("Error updating data:", error);
      toastRef.current.showToast("Error updating data", "error");
    }
  };
  
  const handleChange = (subTeam, rowIndex, key, value) => {
    const updatedData = [...latestData];
      const filteredItems = updatedData.filter(item => {
      const user = usersList?.find(d => d?.EM_EmpID === item.empid);
      const teamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";
      return teamName === subTeam;
    });
      if (filteredItems[rowIndex]) {
      const currentRow = filteredItems[rowIndex];
      currentRow[key] = value; 
      currentRow.changed = true; 
      currentRow.total = monthsArray.reduce(
        (acc, monthKey) => acc + (currentRow[monthKey] || 0),
        0
      );
      const updatedLatestData = updatedData.map(item =>
        item.empid === currentRow.empid ? currentRow : item
      );
      console.log("SubTeam:", subTeam, "RowIndex:", rowIndex, "Key:", key, "Value:", value);

      setLatestData(updatedLatestData);
    } else {
      console.error(`No item found at rowIndex ${rowIndex} for subTeam ${subTeam}`);
    }
  };
  
  
  const handleSave = async () => {
    const promises = [];
  
    for (const row of latestData) {
      if (row.changed) {
        if (!row.createdBy) {
          row.createdBy = user?.EM_ID || "Unknown";
        }
        row.createdDate = formatDate(new Date());
        promises.push(updateData(row));
      }
    }
    
    await Promise.all(promises);
    await fetchHistoryData(latestSelectedYear);
  };
  
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = [];
    for (let year = currentYear; year >= 2000; year--) {
      yearList.push(year.toString());
    }
    for (let year = currentYear + 1; year <= currentYear + 3; year++) {
      yearList.unshift(year.toString());
    }
    setYears(yearList);
  }, []);
  useEffect(() => {
    const getMonthName = (monthNumber) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[monthNumber - 1];
    };
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;
      return `${getMonthName(month)} ${latestSelectedYear}`;
    });
    setMonths(monthNames);
  }, [latestSelectedYear]);
  const handleViewClick = (item) => {
    setSelectedHistoryData(item);
    setShowHistory(true);
    setShowLatestBudget(false);
    closeModal();
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };
  const handleYearChange = async (event) => {
    const newYear = event.target.value;
    setLatestSelectedYear(newYear);
    fetchHistoryData(newYear);
  };
  const fetchHistoryData = async (latestSelectedYear) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${userApiUrl}/GetLatestBudget?Year=${latestSelectedYear}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch budget history data");
      }

      const data = await response.json();
      const sortedData = data.sort((a, b) => {
        // Sort by team first
        if (a.team.toLowerCase() < b.team.toLowerCase()) return -1;
        if (a.team.toLowerCase() > b.team.toLowerCase()) return 1;
        
        // If the teams are the same, sort by subTeams
        if (a.subTeams.toLowerCase() < b.subTeams.toLowerCase()) return -1;
        if (a.subTeams.toLowerCase() > b.subTeams.toLowerCase()) return 1;
      
        return 0;
      });
      const dateSortedData = sortedData.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );
     
      setShowHistory(false);
      setShowLatestBudget(true);
      setIsArrowVisible(true);
      showArrow();
      setLatestData(data);
      setHistoryDatamodel(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching budget history data:", error);
      setLoading(false);
    }
  };

  const monthsArray = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "decs",
  ];

  const currentMonth = new Date()
    .toLocaleString("default", { month: "short" })
    .toLowerCase();
  const currentMonthIndex = monthsArray.indexOf(currentMonth);

  // const groupData = (data) => {
  //   const grouped = {};
  //   data.forEach((item) => {
  //     const subTeamID = usersList?.find((user) => user.EM_EmpID === item.empid)?.STM_Subteam;
  //     if (subTeamID) {
  //       if (!grouped[subTeamID]) {
  //         grouped[subTeamID] = [];
  //       }
  //       grouped[subTeamID].push(item);
  //     }
  //   });
  //   setGroupedData(grouped);  // Update state with the grouped data
  // };
  
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("scroll", handleClickOutside);
    } else {
      document.removeEventListener("scroll", handleClickOutside);
    }
    return () => {
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (token) {
      fetchHistoryData(latestSelectedYear);
    }
  }, [token, latestSelectedYear]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpenError, setIsModalOpenError] = useState(false);
  const handleCloseErrorModal = () => {
    setIsModalOpenError(false); // This will close the modal
  };
  
const [errorMessage, setErrorMessage] = useState("");

const handleDownloadSample = () => {
  // Path to your Excel file in the public folder
  const fileUrl = `${process.env.PUBLIC_URL}/BudgetAndForecastSampleFile.xlsx`;

  // Create a temporary anchor element
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', 'budget.xlsx'); // Set the file name

  // Append to the body
  document.body.appendChild(link);
  link.click(); // Trigger the download
  link.remove(); // Remove the link after download
};

  const handleFileUpload = async (event) => {



    setButtonLoading(true);
    const file = event.target.files[0];

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }

    if (!file) {
      toastRef.current.showToast("No file selected", "error");
      setButtonLoading(false);
      return;
    }

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toastRef.current.showToast(
        "Please select an Excel file (XLSX format)",
        "error"
      );
      setButtonLoading(false);
      return;
    }

    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length === 0 || jsonData[0].length === 0) {
        toastRef.current.showToast("No data found in the file", "error");
        setButtonLoading(false);
        return;
      }

      const normalizeColumnName = (name) => {
        if (typeof name !== "string") {
          return "";
        }
        return name.trim().toUpperCase();
      };
      const requiredBudgetColumns = [
        "EMPID",
        "NAME",
        "TEAM",
        "SUB-TEAMS",
        "TOTAL HOURS",
        // `YTD${new Date().getFullYear()}`.toUpperCase(),
      ];

      const actualColumns = jsonData[0].map(normalizeColumnName);
      const missingColumns = requiredBudgetColumns.filter(
        (col) => !actualColumns.includes(col)
      );

      if (missingColumns.length > 0) {
        toastRef.current.showToast(
          `Missing required columns: ${missingColumns.join(", ")}`,
          "error"
        );
        setButtonLoading(false);
        return;
      }

      const formData = new FormData();
      const uniqueFileName = `u_${Date.now()}.xlsx`;
      formData.append("Folder", "BudgetForecast");
      formData.append("FileNameWithExtension", uniqueFileName);
      formData.append("Attachment", file);
      formData.append("RequestDate", formatDate(new Date()));
      formData.append("RequestedBy", user?.EM_ID || "Unknown");
      formData.append("Remarks", "H");
      formData.append("HubName", "budgetforcastHub");
      formData.append(
        "SignalRConnectionId",
        connectionIdBudgetForecast?.connection?.connectionId || ""
      );

      const response = await fetch(`${userApiUrl}/Uploadmedia`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (!response.ok) {
        const errorMessage =
          result.data ===
          "File Upload unsuccessful because same name file already present"
            ? "File Upload unsuccessful: Same name file already present"
            : `File Upload unsuccessful: ${result.data}`;
        toastRef.current.showToast(errorMessage, "error");
        return;
      }
      // toastRef.current.showToast("File uploaded successfully", "success");
      // await postNotification(
      //   "File upload completed successfully Budget and ForeCast.",
      //   usersList
      // );
      setButtonLoading(false);
      fetchHistoryData(latestSelectedYear);
      // await fetchNotifications();
    } catch (error) {
      console.error("Upload Error:", error);
      toastRef.current.showToast("Error uploading file", "error");
    } finally {
      setButtonLoading(false);
      event.target.value = "";
    }
  };
  useEffect(() => {
    if (notificationsForBudgetForecast.length === 0) {
      return;
    }
  
    setIsLoading(false);
    const lastNotification =
      notificationsForBudgetForecast[notificationsForBudgetForecast.length - 1];
    if (!lastNotification) return;
  
    if (
      lastNotification === "File data uploaded" ||
      lastNotification === "File upload successful" ||
      lastNotification === "File prcoessed success"
    ) {
      toastRef.current.showToast("File processed successfully", "success");
      fetchHistoryData(latestSelectedYear);
    } else {
      setErrorMessage(lastNotification); 
      setIsModalOpenError(true); 
    }
  
    setNotificationsForBudgetForecast([]);
  
  }, [notificationsForBudgetForecast]);
  
  
  
  
  
 
  const calculateTotalHrs = (item, monthsArray) => {
    return monthsArray.reduce(
      (acc, monthKey) => acc + (item[monthKey] || 0),
      0
    );
  };
  const calculateYtd = (item, monthsArray) => {
    const currentMonth = new Date()
      .toLocaleString("default", { month: "short" })
      .toLowerCase();
    const currentMonthIndex = monthsArray.indexOf(currentMonth);

    return monthsArray
      .slice(0, currentMonthIndex + 1)
      .reduce((acc, monthKey) => acc + (item[monthKey] || 0), 0);
  };

  // const totalHrs = calculateTotalHrs(item, monthsArray);
  // const ytd = calculateYtd(item, monthsArray);
  const monthsArrayHead = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",  // Updated from "decs" to "dece"
  ];
  
  return (
    <div>
      <ToastComponent ref={toastRef} timeout={10000}/>
      <NavSub />
      <div className="budget_layout">
        <div className="Buget_head">
          <div className="Budget-history-design">
            <div className="Budget-history">
              <div></div>
              <div
                className="budget-title-bar"
                onClick={() => {
                  openModal();
                }}
              >
                HISTORY
              </div>
              <div
                style={{ display: "flex", margin: "0px auto" }}
                onClick={() => {
                  openModal();
                }}
              >
                <CaretDown size={18} color="#3d025f" weight="fill" />
              </div>
            </div>
            {isModalOpen && (
              <div className="background-pages-new">
                <div ref={modalRef}>
                  <div className="popup-model-history ">
                    <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                      HISTORY
                    </div>
                    <XCircle
                      onClick={closeModal}
                      size={28}
                      weight="fill"
                      className="text-rgba-243-237-255"
                    />
                  </div>
                  <div className="model-border">
                    <div>
                      {historyDatamodel.map((item, index) => (
                        <div
                          key={index}
                          className="content-history"
                          onClick={() => {
                            handleViewClick(item);
                            showArrow();
                          }}
                        >
                          <div className="design-all">
                            {item.username || "N/A"}
                          </div>
                          Updated on
                          <div className="design-all"> {new Date(item.createdDate).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })}</div>
                          budget 
                          <div className="design-all">Team -{item.team || "N/A"},</div>
                          <div className="design-all">subteam -{item.subTeams}</div>
                          <span className="budget-download">View</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="budget_btn_fields">
            <div className="dropdown_year">
              <label htmlFor="yearDropdown">Year:</label>
              <select value={latestSelectedYear} onChange={handleYearChange}>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="Import_field_btn" style={{width:'150px', cursor:'pointer'}}>
                <label className="Nav-Sub-btn" onClick={handleDownloadSample} style={{cursor:'pointer'}}>
                
                  Download Sample
                 
                </label>
              </div>
            {dataMessage && <div>{dataMessage}</div>}
            {!buttonLoading && (
              <div className="Import_field_btn">
                <Download size={35} color="#3d025f" weight="fill" />
                <label className="Nav-Sub-btn">
                  {" "}
                  IMPORT FILES
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            )}
            {buttonLoading && (
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ color: "purple" }} />
              </div>
            )}
          </div>
        </div>

        {isLoading  ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              margin: "30px auto",
            }}
          >
            <CircularProgress style={{ color: "#50145A" }} />
          </div>
        ) : (
          showLatestBudget && (
            <div>
              
              {Object.keys(
  latestData.reduce((groups, item) => {
    const user = usersList?.find(d => d?.EM_EmpID === item.empid); // Find the user in usersList
    const subTeamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";

    if (!groups[subTeamName]) {
      groups[subTeamName] = [];
    }
    groups[subTeamName].push(item);
    return groups;
  }, {})
).sort() 
  .map((subTeam, groupIndex) => (
  <div key={groupIndex} className="budget_team_container">
    <div className="budget_team_title">
      <div className="budget_team_Title_field">
        {subTeam || "Subteam Not Found"}
      </div>
    </div>

    <div className="budget_table_container">
      <div className="budget_table_head">
        <div style={{ width: "130px" }}>MEMBERS</div>
        <div style={{ width: "180px" }}>TEAM</div>
        {monthsArrayHead.map((month, monthIndex) => (
          <div key={monthIndex}>{month}</div>
        ))}
        <div>TOTAL HRS</div>
        <div>YTD - {latestSelectedYear}</div>
        <div></div>
      </div>

      {latestData
        .filter(item => {
          const user = usersList?.find(d => d?.EM_EmpID === item.empid);
          const teamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";
          return teamName === subTeam;
        })
        .map((item, rowIndex) => {
          const user = usersList?.find(d => d?.EM_EmpID === item.empid); 
          const totalHrs = calculateTotalHrs(item, monthsArray);
          const ytd = calculateYtd(item, monthsArray, currentMonthIndex);
          const fullName = user ? `${user.EM_FirstName} ${user.EM_LastName}` : "User Not Found";
          const teamName = user ? teamNames?.find(t => t.id === parseFloat(user.STM_Team))?.name : "Unknown Team";

          return (
            <div
              key={rowIndex}
              className={
                rowIndex % 2 === 0
                  ? "budget_table_row gray"
                  : "budget_table_row white"
              }
            >
              <div className="budget_table_column">{fullName}</div>
              <div className="budget_table_column">{teamName}</div>

              {monthsArray.map((monthKey, monthIndex) => (
                <input
                  key={monthIndex}
                  type="number"
                  className={`budget_table_column ${rowIndex % 2 === 0 ? "gray" : "white"}`}
                  value={item[monthKey]}
                  onChange={(e) =>
                    handleChange(
                      subTeam,
                      rowIndex,
                      monthKey,
                      parseFloat(e.target.value)
                    )
                  }
                />
              ))}
              <div className="budget_table_column">
                {totalHrs}
              </div>
              <div className="budget_table_column">{ytd}</div>
            </div>
          );
        })}

      <div className="bb-tt">
        <div className="budget_table_column3">Total</div>
        <div className="budget_table_column3"></div>
        {monthsArray.map((monthKey) => (
          <div className="budget_table_column3" key={monthKey}>
            {latestData
              .filter(item => {
                const user = usersList?.find(d => d?.EM_EmpID === item.empid);
                const teamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";
                return teamName === subTeam;
              })
              .reduce((acc, cur) => acc + (cur[monthKey] || 0), 0)}
          </div>
        ))}
        <div className="budget_table_column3">
          {latestData
            .filter(item => {
              const user = usersList?.find(d => d?.EM_EmpID === item.empid);
              const teamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";
              return teamName === subTeam;
            })
            .reduce((acc, cur) => acc + monthsArray.reduce((subAcc, key) => subAcc + (cur[key] || 0), 0), 0)}
        </div>
        <div className="budget_table_column3">
          {latestData
            .filter(item => {
              const user = usersList?.find(d => d?.EM_EmpID === item.empid);
              const teamName = user ? teamNames?.find(team => team?.id === parseFloat(user.STM_Subteam))?.name : "Unknown Team";
              return teamName === subTeam;
            })
            .reduce((acc, cur) => acc + calculateYtd(cur, monthsArray, currentMonthIndex), 0)}
        </div>
      </div>
    </div>
  </div>
))}



            </div>
          )
        )}

        {showHistory && selectedHistoryData && (
          <>
            <div
              onClick={() => {
                setLoading(true);
                fetchHistoryData(latestSelectedYear);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "16px",
                cursor: "pointer",
              }}
              className="Buget_head"
            >
              <CaretLeft
                onClick={() => {
                  setLoading(true);
                  fetchHistoryData(latestSelectedYear);
                }}
              />{" "}
              Back
            </div>

            <div className="budget_team_container">
              <div className="budget_team_title">
                <div className="budget_team_Title_field">
                  {selectedHistoryData.subTeams}
                </div>
              </div>

              <div className="budget_table_container">
                <div className="budget_table_head">
                  <div style={{ width: "130px" }}>MEMBERS</div>
                  <div style={{ width: "180px" }}>TEAM</div>
                  {months.map((month, index) => (
                    <div key={index}>{month}</div>
                  ))}
                  <div>TOTAL HRS</div>
                  <div>YTD - {selectedHistoryData.year}</div>
                  <div></div>
                </div>

                {[selectedHistoryData].map((data, index) => {
                  // Define monthlyHours here
                  const monthlyHours = [
                    data.jan,
                    data.feb,
                    data.mar,
                    data.apr,
                    data.may,
                    data.jun,
                    data.jul,
                    data.aug,
                    data.sep,
                    data.oct,
                    data.nov,
                    data.decs,
                  ];

                  // Calculate total hours
                  const totalHrs = monthlyHours.reduce(
                    (acc, cur) => acc + cur,
                    0
                  );

                  // Calculate YTD hours
                  const currentMonthIndex = new Date().getMonth(); // Get current month index (0-based)
                  const ytd = monthlyHours
                    .slice(0, currentMonthIndex + 1)
                    .reduce((acc, cur) => acc + cur, 0);

                  return (
                    <div
                      className={
                        index % 2 === 0
                          ? "budget_table_row gray"
                          : "budget_table_row white"
                      }
                      key={index}
                    >
                      <div className="budget_table_column">{data.username}</div>
                      <div className="budget_table_column">{data.team}</div>
                      {monthlyHours.map((hour, hourIndex) => (
                        <div className="budget_table_column" key={hourIndex}>
                          {hour}
                        </div>
                      ))}
                      <div className="budget_table_column">{totalHrs}</div>
                      <div className="budget_table_column">{ytd}</div>
                    </div>
                  );
                })}

                <div className="bb-tt">
                  <div className="budget_table_column3">Total</div>
                  <div className="budget_table_column3"></div>
                  {months.map((month, monthIndex) => (
                    <div className="budget_table_column3" key={monthIndex}>
                      {[
                        selectedHistoryData.jan,
                        selectedHistoryData.feb,
                        selectedHistoryData.mar,
                        selectedHistoryData.apr,
                        selectedHistoryData.may,
                        selectedHistoryData.jun,
                        selectedHistoryData.jul,
                        selectedHistoryData.aug,
                        selectedHistoryData.sep,
                        selectedHistoryData.oct,
                        selectedHistoryData.nov,
                        selectedHistoryData.decs,
                      ][monthIndex] || 0}
                    </div>
                  ))}
                  <div className="budget_table_column3">
                    {[
                      selectedHistoryData.jan,
                      selectedHistoryData.feb,
                      selectedHistoryData.mar,
                      selectedHistoryData.apr,
                      selectedHistoryData.may,
                      selectedHistoryData.jun,
                      selectedHistoryData.jul,
                      selectedHistoryData.aug,
                      selectedHistoryData.sep,
                      selectedHistoryData.oct,
                      selectedHistoryData.nov,
                      selectedHistoryData.decs,
                    ].reduce((acc, cur) => acc + cur, 0)}
                  </div>
                  <div className="budget_table_column3">
                    {[
                      selectedHistoryData.jan,
                      selectedHistoryData.feb,
                      selectedHistoryData.mar,
                      selectedHistoryData.apr,
                      selectedHistoryData.may,
                      selectedHistoryData.jun,
                      selectedHistoryData.jul,
                      selectedHistoryData.aug,
                      selectedHistoryData.sep,
                      selectedHistoryData.oct,
                      selectedHistoryData.nov,
                      selectedHistoryData.decs,
                    ]
                      .slice(0, new Date().getMonth() + 1)
                      .reduce((acc, cur) => acc + cur, 0)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isModalOpenError && (
  <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
    <div className="modalLayout">
      <div className="modalLayout_contact">
        <div className="modalLayoutMain_contact">
          <div className="modalStyling">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                Error
              </div>
              <XCircle
                onClick={handleCloseErrorModal}
                size={32}
                weight="fill"
                className="text-rgba-243-237-255 cursor-pointer"
              />
            </div>
            <div style={{ margin: "40px auto" }}>
              <p className="font-semibold" style={{ marginBottom: "10px" }}>
              File processing failed. Please review the following errors:              </p>
              <ul className="list-decimal list-inside">
                {errorMessage.split('\n').map((error, index) => (
                  <li key={index}>
                   {error}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}



      <div className="footer-container-w">
        <div className="footer-design">
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default BudgetForeCast;
