import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/LookUp.css";
import isGuestUser from "../utils/guestUser";
import {
  PencilSimpleLine,
  PlusCircle,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import ToastComponent from "../components/ToastComponent";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress } from "@mui/material";
function LookUpActivity() {
  const toastRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenActivity, setIsModalOpenActivity] = useState(false);
  const [isModalStrEdit, setIsModalStrEdit] = useState(false);
  const [isModalOpenStr, setIsModalOpenStr] = useState(false);
  const [isModalOpenVoucher, setIsModalOpenVoucher] = useState(false);
  const [showDeleteModalActivity, setShowDeleteModalActivity] = useState(false);
  const [showDeleteModalVoucher, setShowDeleteModalVoucher] = useState(false);
  const [showDeleteModalStr, setShowDeleteModalStr] = useState(false);
  const [strActivityToDelete, setStrActivityToDelete] = useState(null);
  const [filteredVoucherActivities, setFilteredVoucherActivities] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [serviceError, setServiceError] = useState(null);
  const [subTypeError, setSubTypeError] = useState(null);
  const [priceError, setPriceError] = useState(null);
  const { token } = useContext(AuthContext);
  const { handleAuthExpiry, logout, userData } = useContext(AuthContext);
  const [isModalOpenActivityAdd, setIsModalOpenActivityAdd] = useState(false);
  const [isModalOpenStrAdd, setIsModalStrAdd] = useState(false);
  const [isModalOpenAdhocAdd, setIsModalOpenAdhocAdd] = useState(false);
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [currentTimeCode, setCurrentTimeCode] = useState("");
  const [code, setCode] = useState("");
  const [subtype, setSubType] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [voucherActivities, setVoucherActivities] = useState([]);
  const [reportingActivities, setReportingActivities] = useState([]);
  const [controlPoint, setControlPoint] = useState([]);
  const [adhoc, setAdhoc] = useState([]);
  const [editingActivityId, setEditingActivityId] = useState(null);
  const [currentActivityName, setCurrentActivityName] = useState("");
  const openModalVoucherEdit = (activity) => {
    setIsModalOpenVoucher(true);
    setName(activity.AL_Name);
    setCurrentActivityName(activity.AL_Name);
    setCode(activity.AL_SalseforceCode);
    setService(activity.Service);
    setSubType(activity.AL_SubType);
    setType(activity.AL_Type);
    setPrice(activity.Price);
    setCurrentTimeCode(activity.AL_SalseforceCode);
    setEditingActivityId(activity.AL_ID);
  };
  const openModalActivityEdit = (activity) => {
    setIsModalOpenActivity(true);
    setName(activity.AL_Name);
    setCurrentActivityName(activity.AL_Name); // Set current name
    setCode(activity.AL_SalseforceCode);
    setSubType(activity.AL_SubType);
    setPrice(activity.Price);
    setService(activity.Service);
    setCurrentTimeCode(activity.AL_SalseforceCode); // Set current code
    setEditingActivityId(activity.AL_ID);
    setType(activity.AL_Type);
  };
  const openModalStrEdit = (activity) => {
    setIsModalStrEdit(true);
    setName(activity.AL_Name);
    setCurrentActivityName(activity.AL_Name); // Set current name
    setCode(activity.AL_SalseforceCode);
    setSubType(activity.AL_SubType);
    setPrice(activity.Price);
    setService(activity.Service);
    setCurrentTimeCode(activity.AL_SalseforceCode); // Set current code
    setEditingActivityId(activity.AL_ID);
    setType(activity.AL_Type);
  };

  const EditVoucher = async () => {
    setNameError(null);
    setCodeError(null);
    setPriceError(null);
    setServiceError(null);
    let isValid = true;

    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    if (!name.trim()) {
      setNameError("Activity type is required");
      isValid = false;
    }
    if (!code.trim()) {
      setCodeError("Time code is required");
      isValid = false;
    }
    if (!service.trim()) {
      setServiceError("Service is required");
      isValid = false;
    }
    const priceStr = price ? String(price) : ""; // Ensure price is a string or empty
    if (!priceStr.trim()) {
      setPriceError("Price is required");
      isValid = false;
    } else if (!/^\d+$/.test(priceStr.trim())) {
      setPriceError("Price must be an integer.");
      isValid = false;
    }

    // If the form is not valid, return early
    if (!isValid) return;
    try {
      const response = await fetch(`${clientApiUrl}/UpdateActivity`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ActivityId: editingActivityId,
          ActivityName: name,
          UpdateActivityName: name !== currentActivityName ? "TRUE" : "FALSE",
          TimeCode: code,
          ActivityStatus: true,
          Type: type,
          SubType: subtype,
          Price: price,
          Service: service,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }

      const data = await response.json();

      if (data === true) {
        toastRef.current.showToast("Updated successfully", "success");
        fetchData();
        closeModalVoucher();
        closeModalActivity();
      } else {
        const errorMessage = await response.text();
        toastRef.current.showToast("Failed to update, try again", "error");
        fetchData();
        closeModalVoucher();
        closeModalActivity();
        throw new Error(`Failed to update activity: ${errorMessage}`);
      }
    } catch (error) {
      toastRef.current.showToast("Failed to update, try again", "error");
      fetchData();
      closeModalVoucher();
      closeModalActivity();
    }
  };
  const saveActivity = async (name, code, type, service) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    try {
      const response = await fetch(`${clientApiUrl}/CreateActivity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ActivityName: name,
          TimeCode: code,
          Type: type,
          SubType: subtype,
          Price: price,
          Service: service,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();

      if (data === true) {
        console.log("Activity created successfully");
        toastRef.current.showToast("Created successfully", "success");
        closeModalActivityAdd();
        closeModalActivityAddStr();
        closeModalAdhocAdd();
        closeModal();
        fetchData();
        setName("");
        setService("");
        setCode("");
        setSubType("");
        setPrice("");
        setPriceError("");
      } else {
        console.error(
          "Error creating activity. Server responded with status:",
          response.status
        );
        toastRef.current.showToast(
          "Failed to create, please try again",
          "error"
        );
      }
    } catch (error) {
      console.error("Error creating activity:", error);
      toastRef.current.showToast("Failed to create, please try again", "error");
    } finally {
      closeModalActivityAdd();
      closeModal();
      fetchData();
      setName("");
      setCode("");
      setSubType("");
      setPrice("");
      setService("");
      setPriceError("");
    }
  };
  const handleSave = (type) => {
    setNameError("");
    setCodeError("");
    setServiceError("");
    setPriceError(""); // Reset price error on save attempt

    const trimmedName = name.trim();
    const trimmedCode = code.trim();
    const trimmedService = service.trim();
    const trimmedSubtype = subtype.trim();
    const trimmedPrice = (price || "").trim();

    // Validate required fields
    if (!trimmedName && !trimmedCode && !trimmedService) {
      setNameError("Name is required");
      setCodeError("Code is required");
      setPriceError("Price is required");
      setServiceError("Service is required");
    } else if (!trimmedName) {
      setNameError("Name is required");
    } else if (!trimmedCode) {
      setCodeError("Code is required");
    } else if (!trimmedService) {
      setServiceError("Service is required");
    } else if (!trimmedPrice) {
      // Validate if price is entered
      setPriceError("Price is required");
    } else if (!/^\d+$/.test(trimmedPrice)) {
      // Validate price only if entered
      setPriceError("Price must be an integer.");
    } else {
      // If all fields are valid, proceed with the API call
      saveActivity(
        trimmedName,
        trimmedCode,
        type,
        trimmedService,
        trimmedSubtype,
        trimmedPrice
      );
    }
  };

  // const handleSave = (type) => {
  //   setNameError("");
  //   setCodeError("");
  //   setServiceError("");

  //   const trimmedName = name.trim();
  //   const trimmedCode = code.trim();
  //   const trimmedService = service.trim();
  //   const trimmedSubtype = subtype.trim();
  //   const trimmedPrice = price.trim();

  //   if (!trimmedName && !trimmedCode && !trimmedService) {
  //     setNameError("Name is required");
  //     setCodeError("Code is required");
  //     setServiceError("Service is required");
  //   } else if (!trimmedName) {
  //     setNameError("Name is required");
  //   } else if (!trimmedCode) {
  //     setCodeError("Code is required");
  //   } else if (!trimmedService) {
  //     setServiceError("Service is required");
  //   } else {
  //     saveActivity(trimmedName, trimmedCode, type, trimmedService, trimmedSubtype, trimmedPrice);
  //   }
  // };

  // const handleSave = (type) => {
  //   setNameError("");
  //   setCodeError("");

  //   const trimmedName = name.trim();
  //   const trimmedCode = code.trim();
  //   const trimmedService = service.trim();

  //   if (!trimmedName && !trimmedCode && !trimmedService) {
  //     setNameError("Name is required");
  //     setCodeError("Code is required");
  //     setServiceError("Service is required");
  //   } else if (!trimmedName) {
  //     setNameError("Name is required");
  //   } else if (!trimmedCode) {
  //     setCodeError("Code is required");
  //   }
  //   else if (!trimmedService) {
  //     setServiceError("Service is required");
  //   }  else {
  //     saveActivity(trimmedName, trimmedCode,trimmedService, type);
  //   }
  // };
  const fetchData = async () => {
    if (!token) {
      return;
    }
    try {
      setLoading(true);

      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = await handleAuthExpiry(response);
      if (handleAuthError) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        const activeData = data.filter(
          (activity) => activity.AL_Status === "1"
        );
        const vouchers = data.filter(
          (activity) => activity.AL_Type === "Vouchers"
        );
        const reportingActivities = data.filter(
          (activity) => activity.AL_Type === "Reporting Activites"
        );
        const strActivities = data.filter(
          (activity) => activity.AL_Type === "Control Point"
        );
        const adhoc = data.filter((activity) => activity.AL_Type === "Adhoc");
        setVoucherActivities(vouchers);
        setReportingActivities(reportingActivities);
        setControlPoint(strActivities);
        setAdhoc(adhoc);
        filterActivities(data);
        setLoading(false);
      } else {
        console.error("Failed to fetch data");
        // Show error toast message or handle the error as needed
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Show error toast message or handle the error as needed
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModalActivityAdd = () => {
    setIsModalOpenActivityAdd(true);
    // setIsModalStrAdd(true);
  };
  const openModalActivityAddStr = () => {
    setIsModalStrAdd(true);
  };
  const closeModalActivityAddStr = () => {
    setIsModalStrAdd(false);
  };

  const closeModalActivityAdd = () => {
    setIsModalOpenActivityAdd(false);
    // setIsModalStrAdd(false);
    // setIsModalStrEdit(false);
    // setIsModalOpenAdhocAdd(false);
  };
  const openModalAdhocAdd = () => {
    setIsModalOpenAdhocAdd(true);
  };

  const closeModalAdhocAdd = () => {
    setIsModalOpenAdhocAdd(false);
  };
  const closeModalActivity = () => {
    setIsModalOpenActivity(false);
    setIsModalOpenStr(false);
    setIsModalStrEdit(false);
  };
  const closeModalVoucher = () => {
    setIsModalOpenVoucher(false);
    setName("");
    setCode("");
    setSubType("");
    setPrice("");
    setService("");
    setPriceError("");
    setEditingActivityId(null);
    setIsModalStrEdit(false);
  };

  const filterActivities = (activities) => {
    const filtered = activities.filter(
      (activity) => activity.AL_SalseforceCode === "0201 Vouchers"
    );
    setFilteredVoucherActivities(filtered);
  };
  useEffect(() => {
    fetchData();
  }, [token]);
  const openModalActivity = (activity) => {
    setActivityToDelete(activity);
    setShowDeleteModalActivity(true);
  };
  const openModalVoucher = (voucher) => {
    setVoucherToDelete(voucher);
    setShowDeleteModalVoucher(true);
  };
  const openModalStr = (activity) => {
    setStrActivityToDelete(activity);
    setShowDeleteModalStr(true);
  };
  // const openModalVoucherEdit = (activity) => {
  //   setIsModalOpenActivity(true);
  //   setIsModalOpenStr(true);
  //   setName(activity.AL_Name);
  //   setCurrentActivityName(activity.AL_Name); // Set current name
  //   setCode(activity.AL_SalseforceCode);
  //   setSubType(activity.AL_SubType);
  //   setType(activity.AL_Type);
  //   setCurrentTimeCode(activity.AL_SalseforceCode); // Set current code
  //   setEditingActivityId(activity.AL_ID);
  // };

  const [activityToDelete, setActivityToDelete] = useState(null);
  const [voucherToDelete, setVoucherToDelete] = useState(null);

  const deleteActivity = async (AL_ID, AL_Name, AL_SalseforceCode) => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }

    try {
      const response = await fetch(`${clientApiUrl}/UpdateActivity`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ActivityId: AL_ID,
          ActivityName: AL_Name,
          TimeCode: AL_SalseforceCode,
          ActivityStatus: false,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        console.log("Deleted successfully", data);
        toastRef.current.showToast("Item Deleted successfully", "success");
        setReportingActivities((prevActivities) =>
          prevActivities.filter((item) => item.ActivityId !== AL_ID)
        );
        fetchData(); // Assuming fetchData is a function to refetch data after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete item:", errorData);
        toastRef.current.showToast("Failed to delete item", "error");
      }
    } catch (error) {
      console.error("Error deleting the item:", error);
      toastRef.current.showToast("Error deleting the item", "error");
    }
  };

  const handleConfirmDeleteActivity = async () => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    if (!activityToDelete) return;

    const {
      AL_ID,
      AL_Name,
      AL_SalseforceCode,
      AL_Type,
      AL_SubType,
      Price,
      Service,
    } = activityToDelete;

    try {
      const response = await fetch(`${clientApiUrl}/UpdateActivity`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ActivityId: AL_ID,
          ActivityName: AL_Name,
          TimeCode: AL_SalseforceCode,
          ActivityStatus: false,
          Type: AL_Type,
          SubType: AL_SubType,
          Price: Price,
          Service: Service,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        console.log("Deleted successfully", data);
        toastRef.current.showToast("Item Deleted successfully", "success");
        setReportingActivities((prevActivities) =>
          prevActivities.filter((item) => item.ActivityId !== AL_ID)
        );
        setShowDeleteModalActivity(false);
        fetchData(); // Assuming fetchData is a function to refetch data after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete item:", errorData);
        toastRef.current.showToast("Failed to delete item", "error");
      }
    } catch (error) {
      console.error("Error deleting the item:", error);
      toastRef.current.showToast("Error deleting the item", "error");
    }
  };
  const handleConfirmDeleteVoucher = async () => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    if (!voucherToDelete) return;

    const {
      AL_ID,
      AL_Name,
      AL_SalseforceCode,
      AL_Type,
      AL_SubType,
      Price,
      Service,
    } = voucherToDelete;

    try {
      const response = await fetch(`${clientApiUrl}/UpdateActivity`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ActivityId: AL_ID,
          ActivityName: AL_Name,
          TimeCode: AL_SalseforceCode,
          ActivityStatus: false,
          Type: AL_Type,
          SubType: AL_SubType,
          Price: Price,
          Service: Service,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        console.log("Deleted successfully", data);
        toastRef.current.showToast("Item Deleted successfully", "success");
        setFilteredVoucherActivities((prevActivities) =>
          prevActivities.filter((item) => item.ActivityId !== AL_ID)
        );
        setShowDeleteModalVoucher(false);
        fetchData(); // Assuming fetchData is a function to refetch data after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete item:", errorData);
        toastRef.current.showToast("Failed to delete item", "error");
      }
    } catch (error) {
      console.error("Error deleting the item:", error);
      toastRef.current.showToast("Error deleting the item", "error");
    }
  };
  const handleConfirmDeleteStr = async () => {
    if (isGuestUser(userData?.[0]?.DRM_ID)) {
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`);

      return;
    }
    if (!strActivityToDelete) return;

    const {
      AL_ID,
      AL_Name,
      AL_SalseforceCode,
      AL_Type,
      AL_SubType,
      Price,
      Service,
    } = strActivityToDelete;

    try {
      const response = await fetch(`${clientApiUrl}/UpdateActivity`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ActivityId: AL_ID,
          ActivityName: AL_Name,
          TimeCode: AL_SalseforceCode,
          ActivityStatus: false,
          Type: AL_Type,
          SubType: AL_SubType,
          Price: Price,
          Service: Service,
        }),
      });
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        console.log("Deleted successfully", data);
        toastRef.current.showToast("Item Deleted successfully", "success");
        setControlPoint((prevActivities) =>
          prevActivities.filter((item) => item.ActivityId !== AL_ID)
        );
        setShowDeleteModalStr(false);
        fetchData(); // Assuming fetchData is a function to refetch data after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete item:", errorData);
        toastRef.current.showToast("Failed to delete item", "error");
      }
    } catch (error) {
      console.error("Error deleting the item:", error);
      toastRef.current.showToast("Error deleting the item", "error");
    }
  };

  // Function to handle cancel action for STR Activities
  const handleCancelDeleteStr = () => {
    setShowDeleteModalStr(false);
    setStrActivityToDelete(null);
  };
  const handleCancelDeleteActivity = () => {
    setShowDeleteModalActivity(false);
  };
  const handleCancelDeleteVoucher = () => {
    setShowDeleteModalVoucher(false);
  };
  const handleDeleteIconClick = () => {
    setShowDeleteModalVoucher(true);
  };

  return (
    <div className="LookUp_container">
      <ToastComponent ref={toastRef} timeout={10000} />
      <div>
        <div className="LookUp_header">
          <div className="Lookup_head_title">VOUCHERS</div>
          <div>
            <button
              className="flex items-center justify-center mx-auto text-white rounded-lg font-poppins w-171 h-9 bg-purple-950"
              style={{ width: "171px" }}
            >
              <PlusCircle
                size={20}
                color="#FFFFFF"
                weight="fill"
                className="mr-2"
              />
              <span
                className="inline-block align-middle aaa"
                onClick={openModal}
              >
                ADD NEW VOUCHER
              </span>
            </button>
          </div>
        </div>
        <div className="lookup_activity_contents">
          <div className="table_head_lookup">
            <div className="lookup_activity_name">List of activities</div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "40px" }}
            >
              View force code
            </div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Price
            </div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Service
            </div>
            <div></div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <CircularProgress style={{ color: "#50145A" }} />
            </div>
          ) : (
            <div>
              {voucherActivities
                .filter((activity) => activity.AL_Status === "1")
                .map((activity, index) => (
                  <div
                    key={index}
                    className={
                      index % 2 === 0
                        ? "lookUp_table_td"
                        : "lookUp_table_td_alt"
                    }
                  >
                    <div className="lookup_activity_name">
                      {activity.AL_Name}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SalseforceCode}
                    </div>
                    <div className="lookup_activity_name">{activity.Price}</div>
                    <div className="lookup_activity_name">
                      {activity.Service}
                    </div>

                    <div className="lookup_activity_actionField">
                      <div>
                        <PencilSimpleLine
                          weight="fill"
                          onClick={() => openModalVoucherEdit(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <Trash
                          weight="fill"
                          onClick={() => openModalVoucher(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="LookUp_header">
          <div className="Lookup_head_title">REPORTING ACTIVITIES</div>
          <div>
            <button
              className="flex items-center justify-center mx-auto text-white rounded-lg font-poppins w-171 h-9 bg-purple-950"
              style={{ width: "171px" }}
            >
              <PlusCircle
                size={20}
                color="#FFFFFF"
                weight="fill"
                className="mr-2"
                style={{ cursor: "pointer" }}
              />
              <span
                className="inline-block align-middle aaa"
                onClick={openModalActivityAdd}
              >
                ADD NEW ACTIVITY
              </span>
            </button>
          </div>
        </div>
        <div className="lookup_activity_contents">
          <div className="table_head_lookup">
            <div className="lookup_activity_name">List of activities</div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "40px" }}
            >
              View force code
            </div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Price
            </div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Service
            </div>
            <div></div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <CircularProgress style={{ color: "#50145A" }} />
            </div>
          ) : (
            <div>
              {reportingActivities
                .filter((activity) => activity.AL_Status === "1")
                .map((activity, index) => (
                  <div
                    key={index}
                    className={
                      index % 2 === 0
                        ? "lookUp_table_td"
                        : "lookUp_table_td_alt"
                    }
                  >
                    <div className="lookup_activity_name">
                      {activity.AL_Name}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SalseforceCode}
                    </div>
                    <div className="lookup_activity_name">{activity.Price}</div>
                    <div className="lookup_activity_name">
                      {activity.Service}
                    </div>
                    <div className="lookup_activity_actionField">
                      <div>
                        <PencilSimpleLine
                          weight="fill"
                          onClick={() => openModalActivityEdit(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <Trash
                          weight="fill"
                          onClick={() => openModalActivity(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="LookUp_header">
          <div className="Lookup_head_title">STR ACTIVITIES</div>
          <div>
            <button
              className="flex items-center justify-center mx-auto text-white rounded-lg font-poppins w-171 h-9 bg-purple-950"
              style={{ width: "171px" }}
            >
              <PlusCircle
                size={20}
                color="#FFFFFF"
                weight="fill"
                className="mr-2"
                style={{ cursor: "pointer" }}
              />
              <span
                className="inline-block align-middle aaa"
                onClick={openModalActivityAddStr}
              >
                ADD STR ACTIVITY
              </span>
            </button>
          </div>
        </div>
        <div className="lookup_activity_contents">
          <div className="table_head_lookup">
            <div className="lookup_activity_name">List of STR Activites</div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "40px" }}
            >
              View force code
            </div>
            <div className="lookup_activity_name">Sub Type</div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Price
            </div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "60px" }}
            >
              Service
            </div>
            <div></div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <CircularProgress style={{ color: "#50145A" }} />
            </div>
          ) : (
            <div>
              {controlPoint
                .filter((activity) => activity.AL_Status === "1")
                .map((activity, index) => (
                  <div
                    key={index}
                    className={
                      index % 2 === 0
                        ? "lookUp_table_td"
                        : "lookUp_table_td_alt"
                    }
                  >
                    <div className="lookup_activity_name">
                      {activity.AL_Name}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SalseforceCode}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SubType}
                    </div>
                    <div className="lookup_activity_name">{activity.Price}</div>
                    <div className="lookup_activity_name">
                      {activity.Service}
                    </div>
                    <div className="lookup_activity_actionField">
                      <div>
                        <PencilSimpleLine
                          weight="fill"
                          onClick={() => openModalStrEdit(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <Trash
                          weight="fill"
                          onClick={() => openModalStr(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="LookUp_header">
          <div className="Lookup_head_title">ADHOC ACTIVITIES</div>
          <div>
            <button
              className="flex items-center justify-center mx-auto text-white rounded-lg font-poppins w-171 h-9 bg-purple-950"
              style={{ width: "171px" }}
            >
              <PlusCircle
                size={20}
                color="#FFFFFF"
                weight="fill"
                className="mr-2"
                style={{ cursor: "pointer" }}
              />
              <span
                className="inline-block align-middle aaa"
                onClick={openModalAdhocAdd}
              >
                ADD ADHOC ACTIVITY
              </span>
            </button>
          </div>
        </div>
        <div className="lookup_activity_contents">
          <div className="table_head_lookup">
            <div className="lookup_activity_name">List of ADHOC Activites</div>
            <div
              className="lookup_activity_name"
              style={{ marginRight: "40px" }}
            >
              View force code
            </div>
            <div className="lookup_activity_name">Sub Type</div>
            <div className="lookup_activity_name">Price</div>
            <div className="lookup_activity_name">Service</div>
            <div></div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <CircularProgress style={{ color: "#50145A" }} />
            </div>
          ) : (
            <div>
              {adhoc
                .filter((activity) => activity.AL_Status === "1")
                .map((activity, index) => (
                  <div
                    key={index}
                    className={
                      index % 2 === 0
                        ? "lookUp_table_td"
                        : "lookUp_table_td_alt"
                    }
                  >
                    <div className="lookup_activity_name">
                      {activity.AL_Name}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SalseforceCode}
                    </div>
                    <div className="lookup_activity_name">
                      {activity.AL_SubType}
                    </div>
                    <div className="lookup_activity_name">{activity.Price}</div>
                    <div className="lookup_activity_name">
                      {activity.Service}
                    </div>
                    <div className="lookup_activity_actionField">
                      <div>
                        <PencilSimpleLine
                          weight="fill"
                          onClick={() => openModalStrEdit(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <Trash
                          weight="fill"
                          onClick={() => openModalStr(activity)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        {showDeleteModalVoucher && (
          <div className="modal-overlay">
            <div className="delete-modal">
              <p style={{ fontSize: "16px", color: "#000", fontWeight: 500 }}>
                Are you sure you want to delete this Voucher?
              </p>
              <p
                style={{ fontSize: "14px", color: "#676767", fontWeight: 400 }}
              >
                Once deleted, cannot be retrieved back
              </p>
              <div className="sure_btns">
                <button
                  className="sure_ok"
                  onClick={handleConfirmDeleteVoucher}
                >
                  Ok
                </button>
                <button
                  className="sure_cancel"
                  onClick={handleCancelDeleteVoucher}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {showDeleteModalActivity && (
          <div className="modal-overlay">
            <div className="delete-modal">
              <p style={{ fontSize: "16px", color: "#000", fontWeight: 500 }}>
                Are you sure you want to delete this Activity?
              </p>
              <p
                style={{ fontSize: "14px", color: "#676767", fontWeight: 400 }}
              >
                Once deleted, cannot be retrieved back
              </p>
              <div className="sure_btns">
                <button
                  className="sure_ok"
                  onClick={handleConfirmDeleteActivity}
                >
                  Ok
                </button>
                <button
                  className="sure_cancel"
                  onClick={handleCancelDeleteActivity}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {showDeleteModalStr && (
          <div className="modal-overlay">
            <div className="delete-modal">
              <p style={{ fontSize: "16px", color: "#000", fontWeight: 500 }}>
                Are you sure you want to delete this Activity?
              </p>
              <p
                style={{ fontSize: "14px", color: "#676767", fontWeight: 400 }}
              >
                Once deleted, cannot be retrieved back
              </p>
              <div className="sure_btns">
                <button className="sure_ok" onClick={handleConfirmDeleteStr}>
                  Ok
                </button>
                <button className="sure_cancel" onClick={handleCancelDeleteStr}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {isModalOpen && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Add Voucher type{" "}
                      </div>
                      <XCircle
                        onClick={closeModal}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          {" "}
                          Voucher type name*{" "}
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Voucher type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              const value = e.target.value;
                              setName(value);
                              if (nameError && value.trim() !== "") {
                                setNameError("");
                              } else if (!value.trim()) {
                                setNameError("Activity type is required");
                              }
                            }}
                            required
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              const value = e.target.value;
                              setCode(value);
                              if (codeError && value.trim() !== "") {
                                setCodeError("");
                              } else if (!value.trim()) {
                                setCodeError("Code is required");
                              }
                            }}
                            required
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>

                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Sub Type</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter sub type"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSubType(value);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                            }}
                          />
                        </div>
                      </div> */}
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError("");
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                            required
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>

                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label"> Service </div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              const value = e.target.value;
                              setService(value);
                              if (serviceError && value.trim() !== "") {
                                setServiceError("");
                              } else if (!value.trim()) {
                                setServiceError("service is required");
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => handleSave("Vouchers")}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isModalOpenActivityAdd && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Add Activity type
                      </div>
                      <XCircle
                        onClick={closeModalActivityAdd}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              const value = e.target.value;
                              setName(value);

                              // If there was an error and user starts typing, clear the error
                              if (nameError && value.trim() !== "") {
                                setNameError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setNameError("Activity type is required");
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              const value = e.target.value;
                              setCode(value);

                              // If there was an error and user starts typing, clear the error
                              if (codeError && value.trim() !== "") {
                                setCodeError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setCodeError("Code is required");
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Sub Type</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter sub type"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSubType(value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError("");
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                            required
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label"> Service </div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              const value = e.target.value;
                              setService(value);
                              if (serviceError && value.trim() !== "") {
                                setServiceError("");
                              } else if (!value.trim()) {
                                setServiceError("service is required");
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      {/* <div className='modal_button_field'><button className='common_btn'>SAVE</button></div> */}
                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => handleSave("Reporting Activites")}
                          // onClick={() => {
                          //   saveActivity(name, code, "activity");
                          //   closeModal();
                          // }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpenStrAdd && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Add STR type
                      </div>
                      <XCircle
                        onClick={closeModalActivityAddStr}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              const value = e.target.value;
                              setName(value);

                              // If there was an error and user starts typing, clear the error
                              if (nameError && value.trim() !== "") {
                                setNameError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setNameError("Activity type is required");
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              const value = e.target.value;
                              setCode(value);

                              // If there was an error and user starts typing, clear the error
                              if (codeError && value.trim() !== "") {
                                setCodeError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setCodeError("Code is required");
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Sub Type</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter sub type"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSubType(value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError("");
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                            required
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Service*</div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              const value = e.target.value;
                              setService(value);
                              if (serviceError && value.trim() !== "") {
                                setServiceError("");
                              } else if (!value.trim()) {
                                setServiceError("Service is required");
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                            {/* Add more options as needed */}
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      {/* <div className='modal_button_field'><button className='common_btn'>SAVE</button></div> */}
                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => handleSave("Control Point")}
                          // onClick={() => {
                          //   saveActivity(name, code, "activity");
                          //   closeModal();
                          // }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpenAdhocAdd && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Add Adhoc type
                      </div>
                      <XCircle
                        onClick={closeModalAdhocAdd}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              const value = e.target.value;
                              setName(value);

                              // If there was an error and user starts typing, clear the error
                              if (nameError && value.trim() !== "") {
                                setNameError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setNameError("Activity type is required");
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              const value = e.target.value;
                              setCode(value);

                              // If there was an error and user starts typing, clear the error
                              if (codeError && value.trim() !== "") {
                                setCodeError("");
                              } else if (!value.trim()) {
                                // If value becomes empty after typing, set the error message
                                setCodeError("Code is required");
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Sub Type</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter sub type"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSubType(value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError("");
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                            required
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Service*</div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              const value = e.target.value;
                              setService(value);
                              if (serviceError && value.trim() !== "") {
                                setServiceError("");
                              } else if (!value.trim()) {
                                setServiceError("Service is required");
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      {/* <div className='modal_button_field'><button className='common_btn'>SAVE</button></div> */}
                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => handleSave("Adhoc")}
                          // onClick={() => {
                          //   saveActivity(name, code, "activity");
                          //   closeModal();
                          // }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isModalOpenVoucher && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Edit Voucher type
                      </div>
                      <XCircle
                        onClick={closeModalVoucher}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setNameError(null);
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setCodeError(null);
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">SubType</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              setSubType(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setSubTypeError(null);
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError(null);
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Service*</div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              setService(e.target.value);
                              if (e.target.value !== "") {
                                setServiceError(null);
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => {
                            EditVoucher();
                          }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpenActivity && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Edit Activity type
                      </div>
                      <XCircle
                        onClick={closeModalActivity}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setNameError(null);
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setCodeError(null);
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>

                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError(null);
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Service*</div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              setService(e.target.value);
                              if (e.target.value !== "") {
                                setServiceError(null);
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select Service
                            </option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      {/* <div className='modal_button_field'><button className='common_btn'>SAVE</button></div> */}
                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => {
                            EditVoucher();
                          }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalStrEdit && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Edit STR type
                      </div>
                      <XCircle
                        onClick={closeModalActivity}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <div className="textfield_container_lookup">
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">
                          Activity Type name*
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter activity type"
                            className="textfield_Modal"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setNameError(null);
                              }
                            }}
                          />
                          {nameError && (
                            <div className="error-message">{nameError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Time code*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="0201 Vouchers"
                            className="textfield_Modal"
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setCodeError(null);
                              }
                            }}
                          />
                          {codeError && (
                            <div className="error-message">{codeError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Sub Type</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter sub type"
                            className="textfield_Modal"
                            value={subtype}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSubType(value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Price*</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="textfield_Modal"
                            value={price}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPrice(value);
                              if (priceError && value.trim() !== "") {
                                setPriceError(null);
                              } else if (value.trim() && !/^\d+$/.test(value)) {
                                setPriceError("Price must be an integer.");
                              }
                            }}
                          />
                          {priceError && (
                            <div className="error-message">{priceError}</div>
                          )}
                        </div>
                      </div>
                      <div className="textfile_field_lookup">
                        <div className="Roles_row__label">Service*</div>
                        <div>
                          <select
                            className="textfield_Modal"
                            value={service}
                            onChange={(e) => {
                              setService(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setServiceError(null);
                              }
                            }}
                          >
                            <option value="">Enter activity type</option>
                            <option value="Statutory & Tax Reporting">
                              Statutory & Tax Reporting
                            </option>
                            <option value="Accounting & Services">
                              Accounting & Services
                            </option>
                          </select>
                          {serviceError && (
                            <div className="error-message">{serviceError}</div>
                          )}
                        </div>
                      </div>

                      {/* <div className='modal_button_field'><button className='common_btn'>SAVE</button></div> */}
                      <div className="modal_button_field">
                        <button
                          className="common_btn"
                          onClick={() => {
                            EditVoucher();
                          }}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LookUpActivity;

// const openModalActivity = (activity) => {
//   setIsModalOpenActivity(true);
//   // const activityName = activity.AL_Name.replace(/____(voucher|activity)$/, "");
//   // setName(activityName);
//   setName(activity.AL_Name);
//   console.log(activity);
//   setCode(activity.AL_SalseforceCode);
//   setEditingActivityId(activity.AL_ID);
// };

// const openModalVoucher = (activity) => {
//   // const activityName = activity.AL_Name.replace(/____(voucher|activity)$/, "");
//   // setName(activityName);
//   // setCode(activity.AL_SalseforceCode);
//   // setEditingActivityId(activity.AL_ID);
//   // setIsModalOpenVoucher(true);
//   setName(activity.AL_Name);
//   console.log(activity);
//   setCode(activity.AL_SalseforceCode);
//   setEditingActivityId(activity.AL_ID);
//   setIsModalOpenVoucher(true);
// };
// const handleDeleteActivity = (index) => {
//   const updatedActivities = [...reportingActivities];
//   updatedActivities.splice(index, 1);
//   setReportingActivities(updatedActivities);
//   setShowDeleteModalActivity(false);
// };
// const handleOpenDeleteModal = (index) => {
//   setShowDeleteModalVoucher(true);
//   setDeleteActivityIndex(index);
//   setIsModalOpenVoucher(index);
// };
// const handleOpenDeleteModalActivity = (index) => {
//   setShowDeleteModalActivity(true);
//   setDeleteActivityIndex(index);
//   setIsModalOpenActivity(index);
// };
// const handleConfirmDeleteActivity = () => {
//   const updatedActivities = [...reportingActivities];
//   updatedActivities.splice(deleteActivityIndex, 1);
//   setReportingActivities(updatedActivities);
//   setShowDeleteModalVoucher(false);
//   setShowDeleteModalActivity(false);
// };

// const handleCancelDeleteActivity = () => {
//   const updatedActivities = [...reportingActivities];
//   updatedActivities.splice(deleteActivityIndex, 1);
//   setReportingActivities(updatedActivities);
//   setShowDeleteModalVoucher(false);
//   setShowDeleteModalActivity(false);
// };
// const handleConfirmDeleteActivity = async (activity) => {
//   await deleteActivity(activity?.AL_ID, activity?.AL_Name, activity?.AL_SalseforceCode);
//   const updatedActivities = [...reportingActivities];
//   updatedActivities.splice(deleteActivityIndex, 1);
//   setReportingActivities(updatedActivities);
//   setShowDeleteModalVoucher(false);
// };

// const handleCancelDeleteActivity = () => {
//   const updatedActivities = [...reportingActivities];
//   updatedActivities.splice(deleteActivityIndex, 1);
//   setReportingActivities(updatedActivities);
//   setShowDeleteModalVoucher(false);
//   setShowDeleteModalActivity(false);
// };
// const deleteActivity = async (activity, name, code) => {
//   try {
//     const response = await fetch("https://fn-hampi-clientsvc-dev.azurewebsites.net/UpdateActivity", {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify({
//         ActivityId: activity,
//         ActivityName: name,
//         TimeCode: code,
//         ActivityStatus: false
//       })
//     });

//     if (response.ok) {
//       const data = await response.json();
//       console.log("Deleted successfully", data);
//       toastRef.current.showToast("Item Deleted successfully", "success");
//       setReportingActivities(prevActivities =>
//         prevActivities.filter(item => item.ActivityId !== activity.ActivityId)
//       );
//       fetchData();
//     } else {
//       const errorData = await response.json();
//       console.error("Failed to delete item:", errorData);
//       toastRef.current.showToast("Failed to delete item", "error");
//     }
//   } catch (error) {
//     console.error("Error deleting the item:", error);
//     toastRef.current.showToast("Error deleting the item", "error");
//   }

// };
