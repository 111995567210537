import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/LookUp.css";
import  isGuestUser  from '../utils/guestUser';
import {
  PencilSimpleLine,
  PlusCircle,
  SortDescending,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { CircularProgress } from "@mui/material";
import ToastComponent from "../components/ToastComponent";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../context/AuthContext";
import { SortAscending } from "phosphor-react";

function LookUpDepartment() {
  const toastRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData, token } = useContext(AuthContext);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const { handleAuthExpiry, logout } = useContext(AuthContext);
  const [sortField, setSortField] = useState(null); // Tracks the current sort field
  const [sortOrder, setSortOrder] = useState(null);
  const[loadingEdit,setLoadingEdit]=useState(null);
  const initialValues = {
    costCenter: "",
    DepartmentName: "",
    DepartmentLead: "",
  };
  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=8000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      setActivities(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
      // Show error toast message or handle the error as needed
    }
  };

  useState(() => {
    fetchDepartments(token);
  }, [token]);
  const sortActivities = (field) => {
    let newSortOrder = "asc"; // Default sort order when a field is clicked
  
    if (sortField === field) {
      // Toggle between ascending, descending, and unsorted
      if (sortOrder === "asc") {
        newSortOrder = "desc";
      } else if (sortOrder === "desc") {
        newSortOrder = null; // Reset sorting
      }
    }
  
    // Update state with the new sort field and order
    setSortField(field);
    setSortOrder(newSortOrder);
  
    if (newSortOrder === null) {
      // If reset to unsorted state, no need to sort, just reset to original data
      setActivities(activities); // Ensure `activities` holds the unsorted original list if necessary
      return;
    }
  
    const sorted = [...activities]; // Copy the current activities
  
    sorted.sort((a, b) => {
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";
  
      if (aValue < bValue) return newSortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return newSortOrder === "asc" ? 1 : -1;
      return 0;
    });
  
    setActivities(sorted); // Update the state with the sorted activities
  };
  const handleSortClick = (field) => {
    sortActivities(field);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModalEdit = () => {
    setIsModalOpenEdit(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [originalDepartment, setOriginalDepartment] = useState({});
  const [editedDepartment, setEditedDepartment] = useState({
    id: "",
    DepartmentName: "",
    costCenter: "",
    DepartmentLead: "",
  });
  const openModalEdit = (activity) => {
    console.log("Editing activity:", activity); // Debug log
    const originalData = {
      id: activity.id,
      DepartmentName: activity.name,
      costCenter: activity.costCenter,
      DepartmentLead: activity.owner_EM_ID,
    };
    setOriginalDepartment(originalData); // Store original data
    setEditedDepartment(originalData);
    setIsModalOpenEdit(true);
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditedDepartment((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitEdit = async () => {
    setLoadingEdit(true);
    console.log("Submitting edited department:", editedDepartment); // Debug log

    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
  
    try {
      const formattedData = {
        DepartmentId: editedDepartment.id,
        CostCenter: editedDepartment.costCenter,
        DepartmentName: editedDepartment.DepartmentName,
        DepartmentLead: editedDepartment.DepartmentLead,
        UpdateCostCenter: editedDepartment.costCenter !== originalDepartment.costCenter,
        DepartmentStatus: true,
      };
  
      const response = await fetch(`${clientApiUrl}/UpdateDepartment`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formattedData),
      });
  
      if (response.status === 401) {
        toastRef.current.showToast("Unauthorized access. Please login again.", "error");
        await logout();
        return;
      }
  
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
  
      const data = await response.json();
      console.log("Response data:", data); // Debug log
  
      if (data === true) {
        fetchDepartments(); // Refresh department list
        closeModalEdit();
        setLoadingEdit(false);
        toastRef.current.showToast("Department Updated successfully", "success");
          if (editedDepartment.costCenter !== originalDepartment.costCenter) {
          const viewForceDataPayload = {
            HeaderName: "CostCenter",
            OldName: originalDepartment.costCenter,
            NewName: editedDepartment.costCenter,
          };
  
          try {
            const viewForceResponse = await fetch(`${taskApiUrl}/UpdateViewForceData`, {
              method: "PUT",
              headers: {
                // "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(viewForceDataPayload),
            });
            if (viewForceResponse.status === 401) {
              toastRef.current.showToast("Unauthorized access. Please login again.", "error");
              await logout();
              return;
            }
        
            const handleAuthError = handleAuthExpiry(viewForceResponse);
            if (handleAuthError === true) {
              toastRef.current.showToast("Your Session has expired", "error");
              logout();
              return false;
            }
        
            const viewForceData = await viewForceResponse.text();
  
            if (viewForceData === true) {
              // toastRef.current.showToast("Cost center updated successfully in ViewForceData", "success");
            } else {
              // toastRef.current.showToast("Error updating cost center in ViewForceData", "error");
            }
          } catch (viewForceError) {
            console.error("Error updating cost center in ViewForceData:", viewForceError);
            // toastRef.current.showToast("Error updating cost center in ViewForceData", "error");
          }
        }
      } else {
        // toastRef.current.showToast("Failed to update department", "error");
        closeModalEdit();
        setLoadingEdit(false);
      }
    } catch (error) {
      // toastRef.current.showToast("Error updating department", "error");
      closeModalEdit();
      setLoadingEdit(false);
    }
  };
  

  //  const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'name') {
  //     setName(value);
  //   } else if (name === 'department') {
  //     setDepartment(value);
  //   } else if (name === 'lead') {
  //     setLead(value);
  //   }
  // };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setSelectedDepartment(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setSelectedDepartment((prevState) => ({
      ...prevState,
      [name]: trimmedValue,
    }));
  };

  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentId: "",
    CostCenter: "",
    DepartmentName: "",
    DepartmentLead: "",
    DepartmentStatus: true,
  });
  const [errors, setErrors] = useState({
    costCenter: "",
    departmentName: "",
    // departmentLead: '',
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      const response = await fetch(`${clientApiUrl}/CreateDepartment`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DepartmentName: values.DepartmentName,
          DepartmentLead: values.DepartmentLead,
          CostCenter: values.costCenter,
          DepartmentStatus: "true",
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        fetchDepartments();
        closeModal();
        toastRef.current.showToast("Department added successfully", "success");
        resetForm();
      } else {
        console.error("Failed to create department");
        toastRef.current.showToast("Failed to create department", "error");
        closeModal();
        resetForm();
      }
    } catch (error) {
      console.error("Failed to create department", error);
      toastRef.current.showToast("Failed to create department", "error");
      closeModal();
      resetForm();
    } finally {
      setSubmitting(false); // Set submitting to false regardless of success or failure
    }
  };
  // const openDeleteModal = (id) => {
  //   setShowDeleteModal(true);
  //   setSelectedDepartmentId(id);
  //   handleDeleteLink(id);
  // };
  // const openDeleteModal = (id) => {
  //   setShowDeleteModal(true);
  //   setSelectedDepartmentId(id);
  // };
  const openDeleteModal = (department) => {
    setShowDeleteModal(true);
    setSelectedDepartmentId(department.id);
    setSelectedDepartment(department);
  };

  const handleDeleteLink = async (id) => {
    if(isGuestUser(userData?.[0]?.DRM_ID)){
      toastRef.current.showToast(`Guest User Does not have Add or Edit Access`)
  
      return;
    }
    try {
      const formattedData = {
        DepartmentId: id,
        DepartmentName: selectedDepartment.name,
        CostCenter: selectedDepartment.costCenter,
        UpdateCostCenter: false,
        DepartmentStatus: false,
        DepartmentLead: selectedDepartment.owner_EM_ID,
      };

      const response = await fetch(`${clientApiUrl}/UpdateDepartment`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formattedData),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }

      const data = await response.json();
      if (data === true) {
        toastRef.current.showToast(
          "Department deleted successfully",
          "success"
        );
        setShowDeleteModal(false);
        fetchDepartments(); // Assuming this function fetches the list of departments again
      } else {
        toastRef.current.showToast("Failed to delete department", "error");
      }
    } catch (error) {
      toastRef.current.showToast("Error deleting department", "error");
    }
  };

  const handleDeleteConfirmation = async () => {
    if (selectedDepartmentId) {
      await handleDeleteLink(selectedDepartmentId);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="LookUp_container">
      <ToastComponent ref={toastRef} timeout={10000} />
      <div className="LookUp_header">
        <div className="Lookup_head_title">Department</div>
        <div>
          <button
            className="flex items-center justify-center mx-auto text-white rounded-lg font-poppins w-171 h-9 bg-purple-950"
            style={{ width: "171px" }}
          >
            <PlusCircle
              size={20}
              color="#FFFFFF"
              weight="fill"
              className="mr-2"
            />
            <span className="inline-block align-middle aaa" onClick={openModal}>
              ADD NEW Department
            </span>
          </button>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <CircularProgress style={{ color: "#50145A" }} />
        </div>
      ) : (
        <div className="lookup_activity_contents">
        <div className="table_head_lookup">
          <div className="lookup_activity_name" onClick={() => handleSortClick('costCenter')}>
            Cost center 
            <SortAscending 
              size={22} 
              style={{ opacity: sortField === 'costCenter' && sortOrder === 'asc' ? 1 : 0.5 }} 
            />
            <SortDescending 
              size={22} 
              style={{ opacity: sortField === 'costCenter' && sortOrder === 'desc' ? 1 : 0.5 }} 
            />
          </div>
          
          <div className="lookup_activity_name" onClick={() => handleSortClick('name')}>
            Department 
            <SortAscending 
              size={22} 
              style={{ opacity: sortField === 'name' && sortOrder === 'asc' ? 1 : 0.5 }} 
            />
            <SortDescending 
              size={22} 
              style={{ opacity: sortField === 'name' && sortOrder === 'desc' ? 1 : 0.5 }} 
            />
          </div>
          
          <div className="lookup_activity_name" onClick={() => handleSortClick('owner_EM_ID')}>
            Department lead 
            <SortAscending 
              size={22} 
              style={{ opacity: sortField === 'owner_EM_ID' && sortOrder === 'asc' ? 1 : 0.5 }} 
            />
            <SortDescending 
              size={22} 
              style={{ opacity: sortField === 'owner_EM_ID' && sortOrder === 'desc' ? 1 : 0.5 }} 
            />
          </div>
      
          <div style={{ width: "40px" }}></div>
        </div>
      
        {activities?.map((activity, index) => (
          <div
            key={activity.id}
            className={index % 2 === 0 ? "lookUp_table_td" : "lookUp_table_td_alt"}
          >
            <div className="lookup_activity_name">{activity.costCenter}</div>
            <div className="lookup_activity_name">{activity.name}</div>
            <div className="lookup_activity_name">{activity.owner_EM_ID}</div>
            <div className="lookup_activity_actionField">
              {activity.name && activity.costCenter && (
                <>
                  <div>
                    <PencilSimpleLine
                      weight="fill"
                      onClick={() => openModalEdit(activity)}
                    />
                  </div>
                  <div>
                    <Trash
                      weight="fill"
                      onClick={() => openDeleteModal(activity)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      
      )}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="delete-modal">
            <p style={{ fontSize: "16px", color: "#000", fontWeight: 500 }}>
              Are you sure you want to delete this Department?
            </p>
            <p style={{ fontSize: "14px", color: "#676767", fontWeight: 400 }}>
              Once deleted, cannot be retrieved back
            </p>
            <div className="sure_btns">
              <button className="sure_ok" onClick={handleDeleteConfirmation}>
                Ok
              </button>
              <button
                className="sure_cancel"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpenEdit && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
          <div className="modalLayout">
            <div className="modalLayout_contact">
              <div className="modalLayoutMain_contact">
                <div className="modalStyling">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                      Edit Department
                    </div>
                    <XCircle
                      onClick={closeModalEdit}
                      size={32}
                      weight="fill"
                      className="text-rgba-243-237-255"
                    />
                  </div>
                  <div className="textfield_container_lookup">
                    <div className="textfile_field_lookup">
                      <div className="Roles_row__label">Cost center*</div>
                      <div>
                        <input
                          type="text"
                          name="costCenter"
                          placeholder="Enter cost center"
                          className="textfield_Modal"
                          value={editedDepartment.costCenter}
                          onChange={handleChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="textfile_field_lookup">
                      <div className="Roles_row__label">Department*</div>
                      <div>
                        <input
                          type="text"
                          name="DepartmentName"
                          placeholder="Enter Department"
                          className="textfield_Modal"
                          value={editedDepartment.DepartmentName}
                          onChange={handleChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="textfile_field_lookup">
                      <div className="Roles_row__label">Department Lead*</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter department lead"
                          className="textfield_Modal"
                          name="DepartmentLead"
                          value={editedDepartment.DepartmentLead}
                          onChange={handleChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="modal_button_field">
                    {!loadingEdit && (
             <button className="common_btn" onClick={handleSubmitEdit}>
             SAVE
           </button>
            )}
            {loadingEdit && (
              <div
              
              >
                <CircularProgress style={{ color: "purple" }} />
              </div>
            )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div className="modalLayout">
              <div className="modalLayout_contact">
                <div className="modalLayoutMain_contact">
                  <div className="modalStyling">
                    <div className="flex justify-between">
                      <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                        {" "}
                        Add Department
                      </div>
                      <XCircle
                        onClick={closeModal}
                        size={32}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      />
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validate={(values) => {
                        const errors = {};
                        if (!values.costCenter.trim()) {
                          errors.costCenter = "CostCenter is required";
                        }
                        if (!values.DepartmentName.trim()) {
                          errors.DepartmentName = "Department name is required";
                        }
                        // if (!values.DepartmentLead.trim()) {
                        //   errors.DepartmentLead = 'Department lead is required';
                        // }
                        return errors;
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form className="textfield_container_lookup">
                          <div className="textfile_field_lookup">
                            <div className="Roles_row__label">Cost center*</div>
                            <div>
                              <Field
                                type="text"
                                name="costCenter"
                                placeholder="Enter cost center"
                                className="textfield_Modal"
                                // required
                              />
                              <ErrorMessage
                                name="costCenter"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="textfile_field_lookup">
                            <div className="Roles_row__label">Department*</div>
                            <div>
                              <Field
                                type="text"
                                name="DepartmentName"
                                placeholder="Enter department name"
                                className="textfield_Modal"
                                // required
                              />
                              <ErrorMessage
                                name="DepartmentName"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="textfile_field_lookup">
                            <div className="Roles_row__label">
                              Department Lead*
                            </div>
                            <div>
                              <Field
                                type="text"
                                name="DepartmentLead"
                                placeholder="Enter department lead"
                                className="textfield_Modal"
                                // required
                              />
                              {/* <ErrorMessage name="DepartmentLead" component="div" className="error-message" /> */}
                            </div>
                          </div>
                          <div className="modal_button_field">
                            <button
                              className="common_btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Submitting..." : "SAVE"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LookUpDepartment;
