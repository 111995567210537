import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SignUp from "./pages/SignUp";
import SignUpPage from "./pages/SignUpPage";
import UserDetailEdit from "./pages/UserDetailEdit";
import HampiLogin from "./pages/HampiLogin";
import HampiReset from "./pages/HampiReset";
import CustomerTeam1 from "./pages/CustomerTeam1";
import CustomerMasterData from "./pages/CustomerMasterData";
import CustomerData2 from "./pages/CustomerData2";
// import CustomerMasterData1 from "./pages/CustomerMasterData1";
import CustomerMasterNav from "./pages/CustomerMasterNav";
import LookUpPage from "./pages/LookUpPage";
import LookUpActivity from "./pages/LookUpActivity";
import LookUpDepartment from "./pages/LookUpDepartment";
import LookUpCalender from "./pages/LookUpCalender";
import TimeCode from "./pages/TimeCode";
import MasterDataTab1Content2 from "./pages/MasterDataTab1Content2";
import CustomerMasterTabs from "./pages/CustomerMasterTabs";
import CustomerMasterNav2 from "./pages/CustomerMasterNav2";
import CustomerDetail from "./pages/CustomerDetail";
import CustomerDetail1 from "./pages/CustomerDetail1";
import MasterDataTab3Content from "./pages/MasterDataTab3Content";
import MasterDataTab1Content from "./pages/MasterDataTab1Content";
import MasterDataTab2Content from "./pages/MasterDataTab2Content";
import MasterDataTab4Content from "./pages/MasterDataTab4Content";
import Run from "./pages/Run";
import RegisterUserDetails from "./pages/RegisterUserDetails";
import BudgetForeCast from "./pages/BudgetForeCast";
import UserSetUpDetial from "./pages/UserSetUpDetial";
import User from "./pages/UserDeatilScreen";
import UserDeatilScreen from "./pages/UserDeatilScreen";
import UserSetupAndRoles from "./pages/UserSetupAndRoles";
import { AuthContextProvider } from "./context/AuthContext";
import GraphPage from "./pages/GraphPage";
import { UserContextProvider } from "./context/UserContext";
import ExcelUpload from "./components/ExcelSheet";
 
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AddClient from "./pages/AddClient";
import Mergenew from "./pages/Mergenew";
import Dashboard from "./pages/Dashboard";
import ActivitywisetimereportNav from "./components/ActivitywisetimereportNav";
import UserwiseTimeReport from "./pages/UserwiseTimeReport";
import StandardvsActualTimeReport from "./pages/StandardvsActualTimeReport";
import VoucherEntry from "./pages/VoucherEntry";
import VoucherEntryReview from "./pages/VoucherEntryReview";
import STRActivity from "./pages/STRActivity";
 
import CommingSoon from "./pages/CommingSoon";
import Notification from "./pages/Notification";
import VoucherReview from "./pages/VoucherReview";
import HistoryDetailViewForce from "./pages/HistoryDetailViewForce";
 
import Reporting from "./pages/Reporting";
import ActivityWiseTimeReport from "./pages/ActivityWiseTimeReport";
import WeekelyTimesheet from "./pages/WeeklyTimesheet";
import WeekelyReview from "./pages/WeeklyReview";
import Capacity from "./pages/Capacity";
import QualityControl from "./pages/QualityControl";
import ViewForceSupUser from "./pages/ViewForceSupUser";
import ViewForceMain from "./pages/ViewForceMain";
import { NotificationProvider } from "./context/NotificationContext";
import Capacity2 from "./pages/Capacity2";
import DailyVoucherStock from "./pages/DailyVoucherStock";
import ReportingActivityReports from "./pages/ReportingActivityReports";
import VoucherReports from "./pages/VoucherReports";
import STRReports from "./pages/STRReports";
import SynergyBillMonth from "./pages/SynergyBillMonth";
import ReportingActivityStatus from "./pages/ReportingActivityStatus";
import STRStatusReport from "./pages/STRStatusReport";
import ReportActivityStatus from "./pages/ReportActivityStatus";
import SynergyBillingReports from "./pages/SynergyBillingReports"
import BillingByDepartment from "./pages/BillingByDepartment";
import STRStatusReports from "./pages/STRStatusReports";
import Report from "./pages/Report";
import ReportingActivitySheet from "./pages/ReportingActivitySheet";
import CustomisedReports from "./pages/CustomisedReports";
import AddReports from "./components/AddReports";
import ReportDetails from "./pages/ReportDetails";
import PublicReportDetails from "./pages/PublicReportDetails";
import ReportDetails2 from "./pages/ReportDetails2";
import PrivateReportDetails from "./components/PrivateReportDetails";
 
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "purple",
              borderWidth: "2px",
            },
            "&:hover fieldset": {
              borderColor: "purple",
              borderWidth: "1px",
            },
          },
        },
      },
    },
  },
});
function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <UserContextProvider>
          <NotificationProvider >
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/xl" element={<ExcelUpload />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/CustomerData2/:id" element={<CustomerData2 />} />
 
              <Route path="/graph" element={<GraphPage />} />
              <Route path="/lookUpPage" element={<LookUpPage />} />
              <Route path="/SignUpPage" element={<SignUpPage />} />
              <Route path="/UserDetailEdit" element={<UserDetailEdit />} />
              <Route path="/" element={<HampiLogin />} />
              <Route path="/HampiReset" element={<HampiReset />} />
              <Route path="/CustomerTeam1" element={<CustomerTeam1 />} />
 
              <Route
                path="/CustomerMasterData"
                element={<CustomerMasterData />}
              />
              <Route
                path="/CustomerMasterNav"
                element={<CustomerMasterNav />}
              />
              <Route
                path="/CustomerMasterNav2"
                element={<CustomerMasterNav2 />}
              />
              <Route
                path="/CustomerMasterTabs"
                element={<CustomerMasterTabs />}
              />
              <Route
                path="/MasterDataTab1Content"
                element={<MasterDataTab1Content />}
              />
              <Route
                path="/MasterDataTab1Content2"
                element={<MasterDataTab1Content2 />}
              />
              <Route
                path="/MasterDataTab2Content"
                element={<MasterDataTab2Content />}
              />
              <Route
                path="/MasterDataTab3Content"
                element={<MasterDataTab3Content />}
              />
              <Route
                path="/MasterDataTab4Content"
                element={<MasterDataTab4Content />}
              />
              <Route path="/Run" element={<Run />} />
              <Route path="/notify" element={<Notification />} />
              <Route path="/CustomerDetail" element={<CustomerDetail />} />
              <Route path="/CustomerDetail1" element={<CustomerDetail1 />} />
              <Route path="/Capacity2" element={<Capacity2 />} />
              <Route
                path="/UserSetupAndRoles"
                element={<UserSetupAndRoles />}
              />
              <Route path="/LookUpDepartment" element={<LookUpDepartment />} />
              <Route path="/LookUpActivity" element={<LookUpActivity />} />
              <Route path="/LookUpCalender" element={<LookUpCalender />} />
              <Route path="/TimeCode" element={<TimeCode />} />
              <Route path="/BudgetForeCast" element={<BudgetForeCast />} />
              <Route
                path="/UserSetUpDetial/:id"
                element={<UserSetUpDetial />}
              />
              <Route path="/UserDeatilScreen" element={<UserDeatilScreen />} />
              <Route path="/AddClient" element={<AddClient />} />
              <Route path="/Mergenew" element={<Mergenew />} />
              <Route
                path="/Activitywisetimereport"
                element={<ActivityWiseTimeReport />}
              />
              <Route
                path="/UserwiseTimeReport"
                element={<UserwiseTimeReport />}
              />
              <Route
                path="/StandardvsActualTimeReport"
                element={<StandardvsActualTimeReport />}
              />
              <Route
                path="/weeklyTimeSheet"
                element={<WeekelyTimesheet />}
              />
              <Route
                path="/weeklyReview"
                element={<WeekelyReview />}
              />
              <Route path="/VoucherEntry" element={<VoucherEntry />} />
              <Route path="/VoucherReview" element={<VoucherReview />} />
              <Route path="/STRActivity" element={<STRActivity />} />
              <Route
                path="/HistoryDetailViewForce"
                element={<HistoryDetailViewForce />}
              />
 
              <Route path="/CommingSoon" element={<CommingSoon />} />
              <Route path="/ReportingActivities" element={<Reporting />} />
              <Route path="/Capacity" element={<Capacity />} />
              <Route path="/QualityControl" element={<QualityControl />} />
              <Route path="/ViewForceSupUser" element={<ViewForceSupUser />} />
              <Route path="/ViewForceMain" element={<ViewForceMain />} />
              <Route path="/DailyVoucherStock" element={<DailyVoucherStock />} />
              <Route path="/ReportingActivityReports" element={<ReportingActivityReports />} />
              <Route path="/VoucherReports" element={<VoucherReports />} />
              <Route path="/StrReports" element={<STRReports />} />
              <Route path="/SynergyBillMonth" element={<SynergyBillMonth />} />
              <Route path="/STRStatusReport" element={<STRStatusReport />} />
              <Route path="/ReportActivityStatus" element={<ReportActivityStatus />} />
              <Route path="/SynergyBillingReports" element={<SynergyBillingReports />} />
              <Route path="/BillingByDepartment" element={<BillingByDepartment />} />
              <Route path="/STRStatusReports" element={<STRStatusReports />} />
              <Route path="/ReportingActivitySheet" element={<ReportingActivitySheet />} />
              <Route path="/CustomisedReports" element={<CustomisedReports />} />
              <Route path="/AddReports" element={<AddReports />} />
              <Route path="/report-details/:reportId" element={<ReportDetails />} />
              <Route path="/PrivateReportDetails/:reportId" element={<PrivateReportDetails />} />
              <Route path="/PublicReportDetails/:reportId" element={<PublicReportDetails />} />

            </Routes>
          </ThemeProvider>
          </NotificationProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}
 
export default App;